<ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
	<a class="navbar-brand" routerLink="{{ routes.getRoute('dashboard') }}">
		<img [src]="open ? 'assets/themes/logo.svg?v1.1': 'assets/themes/icon.svg?v1.1'" alt="{{env.brandText}}"/>
	</a>
	<!-- <hr class="sidebar-divider my-0"> -->
	<div *ngIf="!isPublic" (click)="openSideMenu()">
		<li class="nav-item mt-3 mb-3">
      <app-search-menu (searchUpdateEvent)="searchUpdate($event)"></app-search-menu>
		</li>
		<li class="nav-item" *ngFor="let grupo of menuModel" [style.display]="(search === '') ? 'block' : 'none'">
			<div *ngIf="grupo.permissoes.length > 0">
				<a [ngClass]="(grupo.isGrupoPermissaoByRoutemenu(routemenu)) ? 'nav-link' : 'nav-link collapsed'"
					href="#" data-toggle="collapse" [attr.data-target]="'#menu' + grupo.id" aria-expanded="true"
					[attr.aria-controls]="'#menu' + grupo.id">
					<span>
						<i [ngClass]="grupo.icon"></i>
						{{ grupo.nome }}
					</span>
				</a>
				<div [id]="'menu' + grupo.id"
					[ngClass]="(grupo.isGrupoPermissaoByRoutemenu(routemenu)) || isSingleGroup() ? 'collapse show' : 'collapse'"
					aria-labelledby="headingPages" data-parent="#accordionSidebar">
					<div class="bg-white py-2 collapse-inner rounded">
						<a *ngFor="let permissao of grupo.permissoes"
							[ngClass]="(permissao.routemenu == routemenu) ? 'collapse-item active' : 'collapse-item'"
							routerLink="{{ routes.getRoute(permissao.routemenu) }}" title="{{ permissao.labelmenu }}">
							{{ permissao.labelmenu }}
						</a>
					</div>
				</div>
			</div>
		</li>
		<li class="nav-item" *ngFor="let grupo of menuModel" [style.display]="(search !== '') ? 'block' : 'none'">
			<div *ngIf="grupo.permissoes.length > 0 && grupo.isGrupoPermissaoByLabelmenu(search)">
				<a class="nav-link" href="#" data-toggle="collapse" [attr.data-target]="'#menu' + grupo.id"
					aria-expanded="true" [attr.aria-controls]="'#menu' + grupo.id">
					<span>
						<i [ngClass]="grupo.icon"></i>
						{{ grupo.nome }}
					</span>
				</a>
				<div [id]="'menu' + grupo.id" class="collapse show" aria-labelledby="headingPages"
					data-parent="#accordionSidebar">
					<div class="bg-white py-2 collapse-inner rounded">
						<a *ngFor="let permissao of grupo.permissoes"
							[style.display]="(filterSearch(permissao.labelmenu)) ? 'block' : 'none'"
							[ngClass]="(permissao.routemenu == routemenu) ? 'collapse-item active' : 'collapse-item'"
							routerLink="{{ routes.getRoute(permissao.routemenu) }}" title="{{ permissao.labelmenu }}">
							{{ permissao.labelmenu }}
						</a>
					</div>
				</div>
			</div>
		</li>
	</div>
</ul>
