import { ConfirmOptions } from './confirm-options.interface';
import { ConfirmService } from '../../../services/confirm.service';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-confirm',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.css'],
    standalone: true,
    imports: [NgIf]
})
export class ConfirmComponent implements AfterViewInit {

  public options: ConfirmOptions|null;

  @ViewChild('openButton', { static: true }) openButton: ElementRef<HTMLButtonElement>;

  constructor(private confirmService: ConfirmService, private changeDetectorRef: ChangeDetectorRef) { }

  ngAfterViewInit(): void {
    const openButton = this.openButton.nativeElement;
    
    this.confirmService.get().subscribe((options: ConfirmOptions|null) => {
      this.options = options;
      if (this.options) openButton.click();
    });

    this.changeDetectorRef.detectChanges();
  }

  confirm(res: boolean) {
    this.options.callback(res);
    this.confirmService.set(null);
  }
}
