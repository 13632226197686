import { Component, OnInit } from '@angular/core';
import { LoadingIconComponent } from '../loading-icon/loading-icon.component';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.css'],
    standalone: true,
    imports: [LoadingIconComponent]
})
export class LoaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
