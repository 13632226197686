import { Tools } from '../helpers/tools';
import { PermissaoSistema } from './permissaosistema.model';

export class ModelGrupoMenu {

    private id: number;
    private nome: string;
    private icon: string;
    private permissoes: PermissaoSistema[];

    constructor(id: number, nome: string, icon: string) {
        this.id = id;
        this.nome = nome;
        this.icon = icon;
        this.permissoes = [];
    }

    public addPermissao(permissao: PermissaoSistema): void {
        this.permissoes.push(permissao);
    }

    public setPermissoes(permissoes: PermissaoSistema[]): void {
        this.permissoes = permissoes;
    }

    public getPermissoes(): PermissaoSistema[] {
        return this.permissoes;
    }

    public getPermissao(codpermissaosistema: number): PermissaoSistema {
        let item: PermissaoSistema;

        for(let i = 0; i < this.permissoes.length; i++) {
            if(this.permissoes[i].codpermissaosistema === codpermissaosistema) {
                item = this.permissoes[i];
            }
        }

        return item;
    }

    public isGrupoPermissaoByRoutemenu(routemenu: string) {
        for(let i = 0; i < this.permissoes.length; i++) {
            if(this.permissoes[i].routemenu === routemenu) {
                return true;
            }
        }

        return false;
    }

    public isGrupoPermissaoByLabelmenu(labelmenu: string) {
        let tools = new Tools();

        if(labelmenu !== "") {
            for(let i = 0; i < this.permissoes.length; i++) {
                let index = tools.lowerCaseAndRemoveSpecialChars(this.permissoes[i].labelmenu).indexOf(tools.lowerCaseAndRemoveSpecialChars(labelmenu));
    
                if(index !== -1) {
                    return true;
                }
            }
        } else {
            return true;
        }

        return false;
    }

}
