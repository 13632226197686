import { EnvService } from "../services/env.service";

export class AuthHelper {

    static isAuthenticated(env: EnvService): boolean {
        if (localStorage.getItem("auth") !== null) {
            return true;
        }

        if (sessionStorage.getItem("auth") !== null) {
            let currentDate = new Date();

            let expirationDate = new Date(sessionStorage.getItem('sessionLastAccess'));
            expirationDate.setMinutes(expirationDate.getMinutes() + env.sessionExpirationTime);

            if (currentDate.getTime() <= expirationDate.getTime()) {
                sessionStorage.setItem('sessionLastAccess', currentDate.toISOString());
                return true;
            } else {
                sessionStorage.removeItem('auth');
                sessionStorage.removeItem('sessionLastAccess');
                sessionStorage.removeItem('permissions');

                localStorage.setItem("isExpirated", "S");

                return false;
            }
        }

        return false;
    }

    // static isAuthenticated(): boolean {
    //     return (sessionStorage.getItem("auth") !== null || localStorage.getItem("auth") !== null);
    // }

    static login(data, remember): void {
        // Limpa o storage de labels
        localStorage.removeItem("moduleLabels" + AuthHelper.getAuthDadoEmpresa("codlojamain"));
        localStorage.setItem("executeAfterLoginActions", "S");

        data.darkmode = data.darkmode ? "t" : "f";

        if (remember) {
            localStorage.setItem('auth', JSON.stringify(data));
        } else {
            let date = new Date();
            sessionStorage.setItem('sessionLastAccess', date.toISOString());
            sessionStorage.setItem('auth', JSON.stringify(data));
        }

        localStorage.setItem("codloja" + AuthHelper.getAuthDadoEmpresa("codlojamain"), data.codlojausuario);
    }

    static logout(): void {
        localStorage.removeItem('auth');
        localStorage.removeItem('permissions');
        localStorage.removeItem('codloja' + AuthHelper.getAuthDadoEmpresa("codlojamain"));
        localStorage.removeItem("moduleLabels" + AuthHelper.getAuthDadoEmpresa("codlojamain"));

        sessionStorage.removeItem('auth');
        sessionStorage.removeItem('sessionLastAccess');
        sessionStorage.removeItem('permissions');
    }

    static getAuthDadoEmpresa(attr: string) {
        let object = JSON.parse(sessionStorage.getItem("auth"));

        if (object === null) {
            object = JSON.parse(localStorage.getItem("auth"));
        }

        return (object === null) ? undefined : object[attr];
    }

    static setAuthDadoEmpresa(attr: string, data: string) {
        let localSet = "session";
        let object = JSON.parse(sessionStorage.getItem("auth"));

        if (object === null) {
            localSet = "local";
            object = JSON.parse(localStorage.getItem("auth"));
        }

        if (object === null || object === undefined) {
            return;
        }

        object[attr] = data;

        if (localSet == "session") {
            sessionStorage.setItem("auth", JSON.stringify(object));
        } else {
            localStorage.setItem("auth", JSON.stringify(object));
        }

    }

    static setUserPermissions(data, remember): void {
        if (remember) {
            localStorage.setItem('permissions', JSON.stringify(data));
        } else {
            sessionStorage.setItem('permissions', JSON.stringify(data));
        }
    }

    static getUserPermissons() {
        let object = JSON.parse(sessionStorage.getItem("permissions"));

        if (object === null) {
            object = JSON.parse(localStorage.getItem("permissions"));
        }

        if (object === null) {
            return undefined;
        }

        return object;
    }

    static hasUser(): boolean {
        return (AuthHelper.getAuthDadoEmpresa("codusuario") !== null);
    }

    // Para o codloja utilizar sempre o localStorage
    static hasLoja(): boolean {
        let codloja = localStorage.getItem("codloja" + AuthHelper.getAuthDadoEmpresa("codlojamain"));
        return (codloja !== null);
    }

    static getLoja(): string {
        let codloja = localStorage.getItem("codloja" + AuthHelper.getAuthDadoEmpresa("codlojamain"));
        return (codloja === null) ? undefined : codloja;
    }

    static setLoja(codloja: number) {
        localStorage.setItem("codloja" + AuthHelper.getAuthDadoEmpresa("codlojamain"), codloja.toString());
    }

    static setLojaMain(codlojamain: number) {
        localStorage.setItem("codlojamain" + AuthHelper.getAuthDadoEmpresa("codlojamain"), codlojamain.toString());
    }

    static getLojaMain() {
        let dt = localStorage.getItem("codlojamain" + AuthHelper.getAuthDadoEmpresa("codlojamain"));
        return (dt === null) ? undefined : dt;
    }

    static isDarkmode(): boolean {
        let object = JSON.parse(sessionStorage.getItem("auth"));

        if (object === null) {
            object = JSON.parse(localStorage.getItem("auth"));
        }

        return (object === null) ? false : object.darkmode == 't';
    }

    static getAuthenticatedUser() {
        let object = JSON.parse(sessionStorage.getItem("auth"));

        if (object === null) {
            object = JSON.parse(localStorage.getItem("auth"));
        }

        return (object === null) ? undefined : object;
    }

}
