<div class="modal fade" id="alteracaoSenhaModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content modal-content-bs">
      <div class="modal-header">
        <h4 class="modal-title">Alterar Senha</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="reset()">
          &times;
        </button>
      </div>
      <div class="modal-body">
        <div class="panel panel-default">
          <div class="panel-body">
            <app-loader *ngIf="showLoader"></app-loader>
            <div *ngIf="!showLoader">
              <app-alert></app-alert>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Nome</label>
                    <input [(ngModel)]="item.nome" name="nome" placeholder="Nome" class="form-control" readonly />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Usuário</label>
                    <input [(ngModel)]="item.usuario" name="usuario" placeholder="Usuário" class="form-control" readonly />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Senha</label>
                    <app-input-password #senha inputClass="form-control" fieldLabel="Senha" fieldName="senha" [inputValue]="item.senha"></app-input-password>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Confirmar Senha</label>
                    <app-input-password #confirm inputClass="form-control" fieldLabel="Confirmar Senha" fieldName="confirm"></app-input-password>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="btn-group float-right">
          <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="reset();">
            Cancelar
          </button>
          <app-button-load [isLoading]="loadSave" classNames="btn btn-success force-append" icon="fas fa-save" text="Salvar" (clickEvent)="save()"></app-button-load>
        </div>
      </div>
    </div>
  </div>
</div>