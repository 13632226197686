import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from './env.service';
import { QueryStringBuilder } from '../helpers/query-string-builder';
import { AuthHelper } from '../helpers/auth-helper';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

export abstract class Service {
    httpOptions: any;
    baseUrl: string;
    resource: string;
    isSubmoduleService: boolean;

    constructor(resource: string, protected http: HttpClient, protected env: EnvService, protected router: Router, public toastr: ToastrService) {
        this.resource = resource;
        this.baseUrl = env.apiUrl + this.resource;
        this.isSubmoduleService = false;
        this.loadHeaders();
    }

    loadHeaders() {

        if (AuthHelper.getAuthDadoEmpresa("lg")) {
            this.httpOptions = {
                headers: new HttpHeaders({
                    'sequence-version': this.env.sequenceVersion,
                    'content-type': 'application/json',
                    'access-control-allow-origin': '*',
                    'access-control-allow-headers': 'x-requested-with, content-type, accept, origin, authorization',
                    'access-control-allow-methods': 'get, post, put, patch, delete, options',
                    'lg': AuthHelper.getAuthDadoEmpresa("lg")
                })
            };
        } else {
            this.httpOptions = {
                headers: new HttpHeaders({
                    'sequence-version': this.env.sequenceVersion,
                    'content-type': 'application/json',
                    'access-control-allow-origin': '*',
                    'access-control-allow-headers': 'x-requested-with, content-type, accept, origin, authorization',
                    'access-control-allow-methods': 'get, post, put, patch, delete, options'
                })
            };
        }

    }

    initializeQueryStringBuilder(baseUrl: string, page?: number, ordenation?: string, typeorder?: string, limit?: number, canIgnorePage?: boolean): QueryStringBuilder {
        let qsBuilder = new QueryStringBuilder(baseUrl);

        if ((canIgnorePage && page > 0) || !canIgnorePage) {
            qsBuilder.checkAndAddParamNumeric("pag", page);

            if(limit !== undefined) {
                qsBuilder.checkAndAddParamNumeric("limit", limit);
            }
        }

        qsBuilder.checkAndAddParam("ordenacao", ordenation);
        qsBuilder.checkAndAddParam("typeorder", typeorder);

        return qsBuilder;
    }

    getHttpOptions() {
        if(!AuthHelper.isAuthenticated(this.env) && this.resource != "login") {
            if(localStorage.getItem("isExpirated") === "S") {
                this.toastr.info("Sua sessão expirou! <br>Por favor, faça login novamente.", "", {
                    positionClass: 'toast-top-center'
                });

                localStorage.removeItem('isExpirated');
            }

            this.router.navigate(['/login']);

            return;
        }

        this.loadHeaders();
        return this.httpOptions;
    }

}
