import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgIf } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

@Component({
    selector: 'app-search-menu',
    templateUrl: './search-menu.component.html',
    standalone: true,
    imports: [ReactiveFormsModule, FormsModule, NgIf]
})
export class SearchMenuComponent implements OnInit {

  public searchValue: string;

  @Output() searchUpdateEvent = new EventEmitter<string>();

  constructor() {
    this.clearSearch();
  }

  ngOnInit() { }

  searchUpdate() {
    this.searchUpdateEvent.emit(this.searchValue);
  }

  clearSearch() {
    this.searchValue = "";
    this.searchUpdate();
  }

}
