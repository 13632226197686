import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CopyToClipboardDirective } from '../../../directives/copy-to-clipboard.directive';
import { NgIf } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

@Component({
    selector: 'app-input-password',
    templateUrl: './input-password.component.html',
    standalone: true,
    imports: [ReactiveFormsModule, FormsModule, NgIf, CopyToClipboardDirective]
})
export class InputPasswordComponent implements OnInit {

  @Input() fieldName;
  @Input() fieldLabel;
  @Input() inputClass;
  @Input() inputValue;
  @Input() inputReadonly;
  @Input() placeHolder;
  @Input() temporaryDisabledTime;
  @Input() copyButton: boolean;


  @Output() componentValueChanged: EventEmitter<any>;

  showPassword: boolean;
  temporaryDisabled: boolean;

  constructor() {
    this.componentValueChanged = new EventEmitter();
    this.showPassword = false;
    this.temporaryDisabled = true;
    this.temporaryDisabledTime = 6000;
  }

  ngOnInit() {
    setTimeout(_ => this.temporaryDisabled = false, this.temporaryDisabledTime);
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  changePassword() {
    this.componentValueChanged.emit({ field: this.fieldName, value: this.inputValue });
  }

}
