import { Enum } from './enum';
import { EnumItem } from './enumItem';
import { EnumOption } from './enumOption';
import { Injectable } from '@angular/core';

@Injectable()
export class NotificacaoEnum extends Enum {

    constructor() {
        super();

        this.items.push(new EnumItem("channel", [
            new EnumOption("Webpush Notification", 1),
            new EnumOption("E-mail", 2),
            new EnumOption("SMS", 3),
            //new EnumOption("Audio", 4),
            new EnumOption("App", 5),
            new EnumOption("Sequence", 6)
        ]));

        this.items.push(new EnumItem("destino", [
            new EnumOption("Sites/API", 1),
            new EnumOption("Usuários Sequence", 2),
            new EnumOption("Todos", 3)
        ]));

        this.items.push(new EnumItem("prioridade", [
            new EnumOption("Alta", 1),
            new EnumOption("Normal", 3),
            new EnumOption("Baixa", 4)
        ]));
    }

}
