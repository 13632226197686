import { Injectable } from '@angular/core';

import routesList from '../routes.config';

import { ModelRoute } from '../models/modelroute.model';

@Injectable()
export class RouteNamesServiceProvider {

    public routes: ModelRoute[];

    constructor() {
        this.routes = routesList;
    }

    addRoutes(routes: ModelRoute[]) {
        this.routes = routes;
    }

    addRoute(route: ModelRoute) {
        if (this.getFilteredListRoutes(route.name) === undefined) {
            this.routes.push(route);
        }
    }

    getRoute(name: string, params?: any) {
        if (this.getFilteredListRoutes(name) !== undefined) {
            let url = '/' + this.getFilteredListRoutes(name).url;

            if (params !== undefined) {
                for(let param in params) {
                    url = url.replace(":" + param, params[param]);
                }
            }

            return url;
        } else {
            return undefined;
        }
    }

    getRouteByName(name: string) {
        if (this.getFilteredListRoutes(name) !== undefined) {
            return this.getFilteredListRoutes(name).url;
        } else {
            return undefined;
        }
    }

    private getFilteredListRoutes(name: string): ModelRoute {
        return this.routes.find(route => {
            return route.name === name;
        });
    }

}
