import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'textLength',
    standalone: true
})
export class TextLengthPipe implements PipeTransform {

  transform(value: string|null, limit: number): string|null {
    return value && value.length > limit ?
      value.substring(0, limit) + '...' : value;
  }
}
