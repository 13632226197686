import { Component, OnInit } from '@angular/core';
import { EnvService } from 'src/app/services/env.service';

// Chama funções externas do JavaScript
declare function toggleTooltips(): void;

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    standalone: true
})
export class FooterComponent implements OnInit {

  brandText: string;

  constructor(public env: EnvService) {
  }

  ngOnInit() {
    toggleTooltips();
  }

}
