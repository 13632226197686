import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Service } from './service';
import { EnvService } from './env.service';
import { FilterServiceInterface } from '../helpers/filter-service-interface';
import { Notificacao } from '../models/notificacao.model';
import { Telefone } from '../models/telefone.model';
import { FotoCadastro } from '../models/fotocadastro.model';
import { ImageServiceInterface } from '../helpers/image-service-interface';
import { QueryStringBuilder } from '../helpers/query-string-builder';
import { ModuleServiceInterface } from '../helpers/module-service-interface';
import { NotificacaoFilter } from '../models/filter/notificacao-filter.model';

@Injectable({
  providedIn: 'root'
})
export class NotificacoesService extends Service implements ModuleServiceInterface<Notificacao>, FilterServiceInterface<NotificacaoFilter>, ImageServiceInterface {

  constructor(protected http: HttpClient, protected env: EnvService, protected router: Router, public toastr: ToastrService) {
    super("notificacoes", http, env, router, toastr);
  }

  getAll(page?: number, ordenation?: string, typeorder?: string, object?: any, limit?: number): Observable<any> {
    let url: string = this.getQueryString(this.baseUrl, page, ordenation, typeorder, object, limit);
    return this.http.get<NotificacaoFilter[]>(url, this.getHttpOptions());
  }

  getOne(cod: string): Observable<any> {
    let url: string = this.baseUrl + "/" + cod;
    return this.http.get<Notificacao>(url, this.getHttpOptions());
  }

  insert(item: Notificacao): Observable<any> {
    return this.http.post<Notificacao>(this.baseUrl, item, this.getHttpOptions());
  }

  update(item: Notificacao): Observable<any> {
    let url: string = this.baseUrl + "/" + item.id;
    return this.http.put<Notificacao>(url, item, this.getHttpOptions());
  }

  remove(item: Notificacao): Observable<any> {
    let url: string = this.baseUrl + "/" + item.id;
    return this.http.delete<Notificacao>(url, this.getHttpOptions());
  }

  removeSelected(object: any): Observable<any> {
    let url: string = this.baseUrl + "/delete";
    return this.http.post<any>(url, object, this.getHttpOptions());
  }

  activateSelected(object: any): Observable<any> {
    let url: string = this.baseUrl + "/ativar";
    return this.http.patch<any>(url, object, this.getHttpOptions());
  }

  deactivateSelected(object: any): Observable<any> {
    let url: string = this.baseUrl + "/desativar";
    return this.http.patch<any>(url, object, this.getHttpOptions());
  }

  definirCapaImage(idRelacao: number, codfotocadastro: number): Observable<any> {
    let url: string = this.baseUrl + "/" + idRelacao + "/imagens/" + codfotocadastro;
    return this.http.patch<FotoCadastro>(url, {}, this.getHttpOptions());
  }

  editImage(idRelacao: number, item: FotoCadastro) {
    let url: string = this.baseUrl + "/" + idRelacao + "/imagens/" + item.codfotocadastro;
    return this.http.put<FotoCadastro>(url, item, this.getHttpOptions());
  }

  removeImage(idRelacao: number, codfotocadastro: number): Observable<any> {
    let url: string = this.baseUrl + "/" + idRelacao + "/imagens/" + codfotocadastro;
    return this.http.delete<FotoCadastro>(url, this.getHttpOptions());
  }

  removeSelectedImage(idRelacao: number, object: any): Observable<any> {
    let url: string = this.baseUrl + "/" + idRelacao + "/imagens/delete";
    return this.http.post<any>(url, object, this.getHttpOptions());
  }

  removeFile(item: Notificacao, codfile: number): Observable<any> {
    let url: string = this.baseUrl + "/" + item.codnotificacao + "/arquivos/" + codfile;
    return this.http.delete<File>(url, this.getHttpOptions());
  }

  removeSelectedFile(idRelacao: number, object: any): Observable<any> {
    let url: string = this.baseUrl + "/" + idRelacao + "/arquivos/delete";
    return this.http.post<any>(url, object, this.getHttpOptions());
  }

  getNotificationPhones(cod: string): Observable<any> {
    let url: string = this.baseUrl + "/" + cod + "/telefones";
    return this.http.get<Telefone[]>(url, this.getHttpOptions());
  }

  forceSend(id: number, object: any): Observable<any> {
    let url: string = this.baseUrl + "/" + id + "/force-send";
    return this.http.post<any>(url, object, this.getHttpOptions());
  }

  getAllUnread(page?: number, ordenation?: string, typeorder?: string, object?: any, limit?: number): Observable<any> {
    let url: string = this.getQueryString(this.baseUrl + "/nao-lidas", page, ordenation, typeorder, object, limit);
    return this.http.get<NotificacaoFilter[]>(url, this.getHttpOptions());
  }

  markAsRead(item: Notificacao): Observable<any> {
    let url: string = this.baseUrl + "/" + item.id;
    return this.http.patch<Notificacao>(url, item, this.getHttpOptions());
  }

  markAllAsRead(items: any): Observable<any> {
    let url: string = this.baseUrl + "/marcar-como-lidas";
    return this.http.patch<any>(url, items, this.getHttpOptions());
  }

  orderImages(item: Notificacao, items: FotoCadastro): Observable<any> {
    let url: string = this.baseUrl + "/" + item.id + "/imagens/ordenacao";
    return this.http.put<FotoCadastro[]>(url, items, this.getHttpOptions());
  }

  getQueryString(baseUrl: string, page?: number, ordenation?: string, typeorder?: string, object?: any, limit?: number): string {
    let qsBuilder: QueryStringBuilder = this.initializeQueryStringBuilder(baseUrl, page, ordenation, typeorder, limit);

    if (object !== undefined) {

      qsBuilder.checkAndAddInativarParam(object.inativar);

      qsBuilder.checkAndAddParamNumeric("id", object.id);
      qsBuilder.checkAndAddParamNumeric("destino", object.destino);
      qsBuilder.checkAndAddParamNumeric("channel", object.channel);

      qsBuilder.checkAndAddParam("dataagendainicial", object.dataagendainicial);
      qsBuilder.checkAndAddParam("dataagendafinal", object.dataagendafinal);

      qsBuilder.checkAndAddParam("dataenvioinicial", object.dataenvioinicial);
      qsBuilder.checkAndAddParam("dataenviofinal", object.dataenviofinal);

      qsBuilder.checkAndAddParam("dataterminoinicial", object.dataterminoinicial);
      qsBuilder.checkAndAddParam("dataterminofinal", object.dataterminofinal);

      qsBuilder.checkAndAddParam("emailnotificationto", object.emailnotificationto);
      qsBuilder.checkAndAddParam("seek", object.seek);
    }

    return qsBuilder.build();
  }

}
