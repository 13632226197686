export class LabelModule {

    codpermissao: number;
    label: string;

    constructor(codpermissao: number, label: string) {
        this.codpermissao = codpermissao;
        this.label = label;
    }

}
