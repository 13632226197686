export class EnumItem {

    name: string;
    values: any;

    constructor(name: string, values: any) {
        this.name = name;
        this.values = values;
    }

    getStaticSelectOptions(): any {
        let arr = [];

        for(let i = 0; i < this.values.length; i++) {
            arr.push({
                text: this.values[i].description,
                value: this.values[i].value
            });
        }

        return arr;
    }

    getValues(): any {
        return this.values;
    }

    getValueByName(name: any): any {
        for(let i = 0; i < this.values.length; i++) {
            if(this.values[i].value == name) {
                return this.values[i].description;
            }
        }
    }

    getValueByDescription(description: any): any {
        for(let i = 0; i < this.values.length; i++) {
            if(this.values[i].description == description) {
                return this.values[i].value;
            }
        }
    }

}
