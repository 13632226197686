export class QueryStringBuilder {

    private url: string;
    private quantParams: number;

    constructor(baseUrl?: string) {
        this.url = "";
        this.quantParams = 0;

        if(baseUrl != undefined) {
            this.url = baseUrl;
        }
    }

    addParam(param: string, value: string) {
        this.url += this.getConcatChar() + param + "=" + value;
        this.quantParams++;
    }

    build() {
        return this.url;
    }

    checkAndAddInativarParam(value: number) {
        if (value == 3) {
            return;
        } else if(value == 2) {
            this.addParam("inativar", "t");
        } else {
            this.addParam("inativar", "f");
        }
    }

    checkAndAddParam(param: string, value: string, defaultValue?: string) {
        if (value !== "" && value !== undefined) {
            this.addParam(param, value);
        } else {
            if(defaultValue !== undefined && defaultValue !== null) {
                this.addParam(param, defaultValue);
            }
        }
    }

    checkAndAddParamNumeric(param: string, value: number, defaultValue?: number) {
        if (value !== 0 && value !== undefined) {
            this.addParam(param, value.toString());
        } else {
            if(defaultValue !== undefined && defaultValue !== null) {
                this.addParam(param, defaultValue.toString());
            }
        }
    }

    private getConcatChar() {
        return (this.quantParams == 0) ? "?" : "&";
    }

}
