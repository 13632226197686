import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { enableProdMode, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { TreeviewModule } from 'ngx-treeview';
import { ToastrModule } from 'ngx-toastr';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { GoogleChartsModule } from 'angular-google-charts';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxCurrencyModule } from 'ngx-currency';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { AppRoutingModule } from './app/app-routing.module';
import { EnvServiceProvider } from './app/helpers/env-service-provider';
import { RouteNamesServiceProvider } from './app/helpers/route-names-service-provider';

let options: Partial<IConfig> = undefined;

registerLocaleData(localePt);

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      AppRoutingModule,
      ReactiveFormsModule,
      FormsModule,
      AngularEditorModule,
      NgxCurrencyModule,
      DragDropModule,
      PdfViewerModule,
      GoogleChartsModule,
      NgxMaskModule.forRoot(options),
      ToastrModule.forRoot({
        timeOut: 30000,
        extendedTimeOut: 2000,
        positionClass: 'toast-bottom-full-width',
        enableHtml: true,
        tapToDismiss: true,
        closeButton: true,
        easeTime: 300
      }),
      TreeviewModule.forRoot()
    ),
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    RouteNamesServiceProvider,
    EnvServiceProvider,
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations()
  ]
}).catch(err => console.error(err));
