import { LabelModule } from '../models/labelmodule.model';
import { Permissao } from '../models/permissao.model';
import { PermissoesSistemaService } from '../services/permissoessistema.service';
import { AuthHelper } from './auth-helper';
import { PermissaoHelper } from './permissao-helper';

export class LabelModulesHelper {

    getLabel(codpermissaosistema: number, service: PermissoesSistemaService): Promise<LabelModule> {
        // Tenta pegar do moduleLabels
        let label = this.getFromModuleLabels(codpermissaosistema);
        
        // Se localizou, retorna
        if(label != undefined) { 
            return new Promise((resolve, reject) => {
                return resolve(label);
            }); 
        } else {
            // Se não localizou no moduleLabels, tenta pegar nas permissões
            label = this.getFromPermissions(codpermissaosistema);
        }

        // Se localizou, joga no ModuleLabels e retorna
        if(label != undefined) { 
            return new Promise((resolve, reject) => {
                return resolve(label);
            }); 
        } else {
            // Se não localizou no moduleLabels, tenta pegar nas API
            return this.getFromAPI(codpermissaosistema, service);
        }
    }

    getFromModuleLabels(codpermissaosistema: number): LabelModule {
        if(localStorage.getItem("moduleLabels" + AuthHelper.getAuthDadoEmpresa("codlojamain")) != null) {
            // Se existe, busca nele
            let labels = JSON.parse(localStorage.getItem("moduleLabels" + AuthHelper.getAuthDadoEmpresa("codlojamain")));
            return labels.find(lb => lb.codpermissao == codpermissaosistema);
        }

        return undefined;
    }

    getFromPermissions(codpermissaosistema: number): LabelModule {
        let permissao: Permissao = PermissaoHelper.getPermissao(codpermissaosistema);

        if(permissao != undefined) {
            let label = new LabelModule(permissao.permissao, permissao.labelmenu);
            this.appendLabel(label);
            return label;
        } else {
            return undefined;
        }
    }

    getFromAPI(codpermissaosistema: number, service: PermissoesSistemaService): Promise<LabelModule> {
        return service.getOneByLabel(codpermissaosistema.toString()).then((data: any) => {
            let label = new LabelModule(data.id, data.labelmenu);
            this.appendLabel(label);
            return label;
        });
    }

    appendLabel(label: LabelModule) {
        if(localStorage.getItem("moduleLabels" + AuthHelper.getAuthDadoEmpresa("codlojamain")) != null) {
            let labels = JSON.parse(localStorage.getItem("moduleLabels" + AuthHelper.getAuthDadoEmpresa("codlojamain")));
            labels.push(label);
            localStorage.setItem("moduleLabels" + AuthHelper.getAuthDadoEmpresa("codlojamain"), JSON.stringify(labels));
        } else {
            localStorage.setItem("moduleLabels" + AuthHelper.getAuthDadoEmpresa("codlojamain"), JSON.stringify([label]));
        }
    }

}
