import { AbstractModel } from './abstractmodel.model';

export class Usuario extends AbstractModel {

    codusuario: number;
    codlojamain: number;
    codlojausuario: number;
    codcolaboradorpadrao: number;
    codcadastro: number;
    nome: string;
    usuario: string;
    senha: string;
    email: string;
    hora: string;
    ultimoacesso: string;
    permissoes: any;

    coddashboard: number;
    coddepartamentousuario: number;
    codgrupousuario: number;

    codfornecedor: number;
    codfuncionario: number;
    codtransportadora: number;

    colaborador: string;
    fornecedor: string;
    funcionario: string;
    transportadora: string;

    totalpermissoes: number;

    expiraacesso: string;



}
