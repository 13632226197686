import { Component } from '@angular/core';
import { ConfirmComponent } from './components/includes/confirm/confirm.component';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { MenuComponent } from './components/includes/menu/menu.component';
import { HeaderComponent } from './components/includes/header/header.component';
import { FooterComponent } from './components/includes/footer/footer.component';
import { map, filter, mergeMap } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { combineLatest } from 'rxjs';
import { MenuTitleService } from './services/menu-title.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    ConfirmComponent,
    MenuComponent,
    HeaderComponent,
    FooterComponent
  ]
})
export class AppComponent {

  protected adminRoute: boolean = false;
  protected openSideMenu: boolean = true;
  protected menuRoute: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private menuTitleService: MenuTitleService
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let route = this.route;
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      mergeMap((route: ActivatedRoute) => combineLatest([route.data, route.url]))
    ).subscribe(([data, url]) => {
      this.adminRoute = url[0].path == 'admin';
      this.menuRoute = data.menuRoute ?? '';
      
      if (typeof data.title == 'string') {
        this.menuTitleService.set(data.title);
      }
    });
  }

  protected toggleSideMenu(open?: boolean): void {
    this.openSideMenu = typeof open == 'boolean' ? open : !this.openSideMenu;
  }
}
