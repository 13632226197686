import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthHelper } from 'src/app/helpers/auth-helper';
import { Usuario } from 'src/app/models/usuario.model';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { AlertComponent } from '../alert/alert.component';
import { ButtonLoadComponent } from '../button-load/button-load.component';
import { InputPasswordComponent } from '../input-password/input-password.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LoaderComponent } from '../loader/loader.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-alteracao-senha-modal',
    templateUrl: './alteracao-senha-modal.component.html',
    standalone: true,
    imports: [NgIf, LoaderComponent, AlertComponent, ReactiveFormsModule, FormsModule, InputPasswordComponent, ButtonLoadComponent]
})
export class AlteracaoSenhaModalComponent implements OnInit {

  item: Usuario;
  showLoader: boolean;
  loadSave: boolean;

  @ViewChild(AlertComponent) alert;

  @ViewChild("senha") inputPassword;
  @ViewChild("confirm") inputConfirm;

  constructor(public service: UsuariosService) {
    this.item = new Usuario();
    this.loadSave = false;
  }

  ngOnInit() {
    this.getUser();
  }

  save() {
    this.loadSave = true;
    this.item.senha = this.inputPassword.inputValue;

    if (!this.isPasswordValid()) {
      this.alert.danger("Favor, informe a senha e a confirmação de senha corretamente.");
      this.loadSave = false;
      return;
    }

    this.service.changePassword(this.item).subscribe(
      data => {
        this.alert.success("Sua senha foi alterada com sucesso!");
        this.reset();
      },
      error => {
        this.alert.danger(error.error.msg);
        this.loadSave = false;
      }
    );
  }

  reset() {
    this.loadSave = false;
    this.inputPassword.inputValue = "";
    this.inputConfirm.inputValue = "";
    this.getUser();
  }

  getUser() {
    this.service.getOne(AuthHelper.getAuthDadoEmpresa("codusuario")).subscribe(data => {
      this.item = data;
    });
  }

  isPasswordValid() {
    // Se ainda não montou o componente, retorna falso
    if (this.inputPassword == undefined || this.inputConfirm == undefined) {
      return false;
    }

    return (this.inputPassword.inputValue != "") && (this.inputPassword.inputValue != undefined) && (this.inputPassword.inputValue == this.inputConfirm.inputValue)
  }

}
