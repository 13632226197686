import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Service } from './service';
import { EnvService } from './env.service';
import { Usuario } from '../models/usuario.model';
import { FilterServiceInterface } from '../helpers/filter-service-interface';
import { PermissaoSistema } from '../models/permissaosistema.model';
import { Permissao } from '../models/permissao.model';
import { QueryStringBuilder } from '../helpers/query-string-builder';
import { ModuleServiceInterface } from '../helpers/module-service-interface';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService extends Service implements ModuleServiceInterface<Usuario>, FilterServiceInterface<Usuario> {

  constructor(protected http: HttpClient, protected env: EnvService, protected router: Router, public toastr: ToastrService) {
    super("usuarios", http, env, router, toastr);
  }

  getAll(page?: number, ordenation?: string, typeorder?: string, object?: any, limit?: number): Observable<any> {
    let url: string = this.getQueryString(this.baseUrl, page, ordenation, typeorder, object, limit);
    return this.http.get<Usuario[]>(url, this.getHttpOptions());
  }

  getOne(cod: string): Observable<any> {
    let url: string = this.baseUrl + "/" + cod;
    return this.http.get<Usuario>(url, this.getHttpOptions());
  }

  insert(item: Usuario): Observable<any> {
    return this.http.post<Usuario>(this.baseUrl, item, this.getHttpOptions());
  }

  update(item: Usuario): Observable<any> {
    let url: string = this.baseUrl + "/" + item.id;
    return this.http.put<Usuario>(url, item, this.getHttpOptions());
  }

  changePassword(item: Usuario): Observable<any> {
    let url: string = this.baseUrl + "/" + item.id + "/alterar-senha";
    return this.http.put<Usuario>(url, item, this.getHttpOptions());
  }

  remove(item: Usuario): Observable<any> {
    let url: string = this.baseUrl + "/" + item.id;
    return this.http.delete<Usuario>(url, this.getHttpOptions());
  }

  removeSelected(object: any): Observable<any> {
    let url: string = this.baseUrl + "/delete";
    return this.http.post<any>(url, object, this.getHttpOptions());
  }

  activateSelected(object: any): Observable<any> {
    let url: string = this.baseUrl + "/ativar";
    return this.http.patch<any>(url, object, this.getHttpOptions());
  }

  deactivateSelected(object: any): Observable<any> {
    let url: string = this.baseUrl + "/desativar";
    return this.http.patch<any>(url, object, this.getHttpOptions());
  }

  getUserPermissions(cod: string): Observable<any> {
    let url: string = this.baseUrl + "/" + cod + "/permissoes";
    return this.http.get<Permissao[]>(url, this.getHttpOptions());
  }

  getPermissions(): Observable<any> {
    let url: string = this.env.apiUrl + "permissoes";
    return this.http.get<PermissaoSistema[]>(url, this.getHttpOptions());
  }

  clonar(object: Usuario) {
    let url: string = this.baseUrl + "/clonar";
    return this.http.post<Usuario>(url, object, this.getHttpOptions());
  }

  enableDarkMode(object: Usuario): Observable<any> {
    let url: string = this.baseUrl + "/" + object.codusuario + "/darkmode/ativar";
    return this.http.patch<any>(url, {}, this.getHttpOptions());
  }

  disableDarkMode(object: Usuario): Observable<any> {
    let url: string = this.baseUrl + "/" + object.codusuario + "/darkmode/desativar";
    return this.http.patch<any>(url, {}, this.getHttpOptions());
  }


  getQueryString(baseUrl: string, page?: number, ordenation?: string, typeorder?: string, object?: any, limit?: number): string {
    let qsBuilder: QueryStringBuilder = this.initializeQueryStringBuilder(baseUrl, page, ordenation, typeorder, limit);

    if (object !== undefined) {
      qsBuilder.checkAndAddInativarParam(object.inativar);
      qsBuilder.checkAndAddParam("nome", object.nome);
      qsBuilder.checkAndAddParam("usuario", object.usuario);
      qsBuilder.checkAndAddParamNumeric("coddepartamentousuario", object.coddepartamentousuario);
      qsBuilder.checkAndAddParamNumeric("codgrupousuario", object.codgrupousuario);
      qsBuilder.checkAndAddParamNumeric("id", object.id);
    }

    return qsBuilder.build();
  }

}
