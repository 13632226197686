import { EnumItem } from './enumItem';
import { EnumOption } from './enumOption';

export class Enum {

    items: EnumItem[];

    constructor() {
        this.items = [];

        this.items.push(new EnumItem("inativar", [
            new EnumOption("Ativos", 1),
            new EnumOption("Inativos", 2),
            new EnumOption("Todos", 3)
        ]));

        this.items.push(new EnumItem("orientacao", [
            new EnumOption("Retrato", 1),
            new EnumOption("Paisagem", 2)
        ]));

        this.items.push(new EnumItem("meses", [
            new EnumOption("Janeiro", 1),
            new EnumOption("Fevereiro", 2),
            new EnumOption("Março", 3),
            new EnumOption("Abril", 4),
            new EnumOption("Maio", 5),
            new EnumOption("Junho", 6),
            new EnumOption("Julho", 7),
            new EnumOption("Agosto", 8),
            new EnumOption("Setembro", 9),
            new EnumOption("Outubro", 10),
            new EnumOption("Novembro", 11),
            new EnumOption("Dezembro", 12)
        ]));

    }

    getItem(name: string): EnumItem {
        let item = undefined;

        for (let i = 0; i < this.items.length; i++) {
            if (this.items[i].name === name) {
                item = this.items[i];
            }
        }

        return item;
    }

}
