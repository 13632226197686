import { Permissao } from '../models/permissao.model';
import { AuthHelper } from './auth-helper';

export class PermissaoHelper {

    static getPermissoes(): Permissao[] {
        return AuthHelper.getUserPermissons();
    }

    static getPermissao(codpermissao: number): Permissao {
        return AuthHelper.getUserPermissons().find(permissao => permissao.permissao == codpermissao);
    }

    static hasPermissao(codpermissao: number): boolean {
        return AuthHelper.getUserPermissons().find(permissao => permissao.permissao == codpermissao) !== undefined;
    }

}
