<div class="modal fade" id="lojaSessaoModal" data-backdrop="static" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-bg">
    <div class="modal-content modal-content-bs">
      <div class="modal-header">
        <h4 class="modal-title">Selecione a loja da sessão</h4>
      </div>
      <div class="modal-body">
        <div class="panel panel-default">
          <div class="panel-body" style="max-height: 500px; overflow: auto;">
            <app-loader *ngIf="showLoader"></app-loader>
            <div *ngIf="!showLoader" class="table-responsive">
              <table width="100%" class="table table-striped table-bordered table-hover table-browser"
                id="dataTables-example">
                <thead>
                  <tr>
                    <th>Código</th>
                    <th>Razão</th>
                    <th>CNPJ</th>
                    <th>Endereço</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of items" data-dismiss="modal" class="tr-browserseek">
                    <td (click)="selectItem(item)">{{ item.codloja }}</td>
                    <td (click)="selectItem(item)">{{ item.razao }}</td>
                    <td (click)="selectItem(item)">{{ item.cnpj }}</td>
                    <td (click)="selectItem(item)">{{ item.endereco }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>