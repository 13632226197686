import { AuthHelper } from '../helpers/auth-helper';

export class AbstractModel {

    id: number;
    codloja: number;
    inativar: any;
    motivoinativacao: string;
    codusuario: number;

    //public var limite:uint;
    hash: string;

    // promove buscas avançadas
    seek: string;


    // Alguns cadastros precisam trazer a descrição da loja na lista.
    loja: string;

    // Filtro para geração de PDFs de relatórios
    orientacao: number;

    /**
     * Função para atribuição de data local para a propriedade recem criada
     */

    currentDate() {
        var today = new Date();
        return today.toISOString().split('T')[0];
    }

    currentTime() {

        var today = new Date();

        var hora: number = today.getHours();
        var formato_hora: string = "";

        var minuto: number = today.getMinutes();
        var formato_minuto: string = "";

        formato_hora = hora.toString();
        formato_minuto = minuto.toString();

        if (hora < 10) {
            formato_hora = "0" + hora;
        }

        if (minuto < 10) {
            formato_minuto = "0" + minuto;
        }

        return formato_hora + ":" + formato_minuto;

    }

    constructor() {

        if (AuthHelper.hasLoja()) {
            this.codloja = parseInt(AuthHelper.getLoja());
        }

        if (AuthHelper.hasUser()) {
            this.codusuario = parseInt(AuthHelper.getAuthDadoEmpresa('codusuario'));
        }

    }

}
