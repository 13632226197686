import { AbstractModel } from './abstractmodel.model';
import { File } from './file.model';
import { FotoCadastro } from './fotocadastro.model';

export class Notificacao extends AbstractModel {

    codnotificacao: number;
    titulo: string;
    mensagem: string;
    icon: string;
    image: string;
    url: string;
    pushclik: string;
    codpagina: number;
    destino: number;
    codpushsubscription: number;
    dataagenda: string;
    horaagenda: string;
    dataenvio: string;
    horaenvio: string;
    status: string;
    prioridade: number;
    hashcontrol: string;
    channel: number;
    emailnotificationto: string;
    datatermino: string;
    subject: string;

    telefones: any;

    imgs: FotoCadastro[];
    files: File[];
    countfiles: number;

}
