import { Enum } from './enum';
import { EnumItem } from './enumItem';
import { Injectable } from '@angular/core';
import { EnumOptionUsuario } from './enumOptionUsuario';

@Injectable()
export class UsuarioEnum extends Enum {

    constructor() {
        super();

        this.items.push(new EnumItem("modulo", [
            new EnumOptionUsuario("Vendas", 1, "far fa-id-badge"),
            new EnumOptionUsuario("Compras", 2, "fas fa-clipboard-list"),
            new EnumOptionUsuario("Projetos", 3, "fas fa-tools"),
            new EnumOptionUsuario("Financeiro", 4, "fas fa-credit-card"),
            new EnumOptionUsuario("CMS", 5, "far fa-bookmark"),
            new EnumOptionUsuario("E-Commerce", 6, "fas fa-shopping-cart"),
            new EnumOptionUsuario("Fiscal", 7, "fas fa-file-invoice"),
            new EnumOptionUsuario("Faturamento", 8, "fas fa-dollar-sign"),
            new EnumOptionUsuario("Serviços", 9, "fas fa-file-alt"),
            new EnumOptionUsuario("Relatórios", 10, "fas fa-print"),
            new EnumOptionUsuario("Admin", 11, "fas fa-fw fa-edit"),
            new EnumOptionUsuario("Gestão de EPI's", 12, "fas fa-hard-hat"),
            new EnumOptionUsuario("Gestão de Atletas", 13, "fas fa-running"),
            new EnumOptionUsuario("Suporte", 14, "fas fa-headset"),
            new EnumOptionUsuario("BI", 15, "fas fa-chart-pie"),
            new EnumOptionUsuario("Dropshipping", 16, "fas fa-box-open"),
            new EnumOptionUsuario("Clínica", 17, "fas fa-user-md"),
            new EnumOptionUsuario("GED", 18, "far fa-folder-open"),
            new EnumOptionUsuario("CRM", 19, "far fa-folder-open")
        ]));
    }

}
