<nav class="navbar navbar-expand navbar-light topbar mb-4 static-top shadow" style="background-color: #FFF;">
  <button class="btn button-toggle-menu" type="button" (click)="toggleSideMenu()">
		<i class="fas" [ngClass]="openSideMenu ? 'fa-chevron-left' : 'fa-chevron-right'"></i>
	</button>
  <div class="dropdown" *ngIf="!isPublic" id="menuMobile">
    <button class="btn btn-success d-md-none rounded-circle dropdown-toggle" type="button" id="dropdownMenuButton"
      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i class="menu-icon fa" [ngClass]="menuMobileActive ? 'fa-times' : 'fa-bars'"></i>
    </button>
    <div onclick="stopPropagation(event)" class="dropdown-menu main" style="width: 100%">
      <div class="mt-3 mb-3">
        <app-search-menu (searchUpdateEvent)="searchUpdate($event)"></app-search-menu>
      </div>

      <div *ngFor="let grupo of menuModel">
        <div *ngIf="grupo.permissoes.length > 0 && (grupo.isGrupoPermissaoByLabelmenu(searchMenu) || searchMenu === '')">
          <a onclick="this.classList.toggle('selected');" tabindex="0" class="dropdown-header menu-item"
          [ngClass]="(grupo.isGrupoPermissaoByLabelmenu(searchMenu) && searchMenu !== '') ? 'selected' : ''">
            <i [ngClass]="grupo.icon"></i>
            <span>{{ grupo.nome }}</span>
          </a>
          <div class="white-bg" >
            <a *ngFor="let permissao of grupo.permissoes" class="dropdown-item"
            [style.display]="(searchMenu === '' || filterSearch(permissao.labelmenu)) ? 'block' : 'none'"
            routerLink="{{ routes.getRoute(permissao.routemenu) }}">{{ permissao.labelmenu }}</a>
          </div>
        </div>
      </div>

    </div>
  </div>

  <ul class="navbar-nav ml-auto" *ngIf="!isPublic">

    <li class="nav-item dropdown no-arrow" *ngIf="fornecedor || cliente">
      <span class="nav-link dropdown-toggle">
        <span *ngIf="fornecedor != null" class="mr-2 text-gray-600 small">{{labelFornecedor}}<br>{{fornecedor}}</span>
        <span *ngIf="cliente != null" class="mr-2 text-gray-600 small">{{labelCadastro}}<br>{{cliente}}</span>
      </span>
    </li>

    <li class="nav-item" *ngIf="env.helpUrl != ''">
      <a class="nav-link" href="{{ env.helpUrl }}" target="_blank">
        <i class="header-icon fas fa-question"></i>
      </a>
    </li>

    <li class="nav-item dropdown no-arrow">
      <a class="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false">
        <i class="header-icon fas fa-bell fa-fw"></i>
        <span class="badge badge-danger badge-counter" *ngIf="notificacoes.length > 0">{{ notificacoes.length }}</span>
      </a>
      <!-- Dropdown - Alerts -->
      <div class="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in notification-box"
        aria-labelledby="alertsDropdown">
        <h6 class="dropdown-header">Notificações</h6>
        <a *ngIf="notificacoes.length == 0" class="dropdown-item d-flex align-items-center">
          <div>
            <span class="font-weight-bold">Você não possui novas notificações no momento</span>
          </div>
        </a>
        <a *ngFor="let notificacao of notificacoes" class="dropdown-item d-flex align-items-center" (click)="setAsRead(notificacao)">
          <div>
            <div class="small text-gray-500">{{ notificacao.dataagenda | date: "dd/MM/yyyy" }}
              {{ notificacao.horaagenda }}</div>
            <span class="font-weight-bold">{{ notificacao.titulo }}</span><br>
            {{ notificacao.mensagem }}
          </div>
        </a>
        <a class="dropdown-item text-center small text-gray-500" *ngIf="notificacoes.length > 0" (click)="setAllAsRead()">Marcar notificações como lidas</a>
      </div>
    </li>
    <li class="nav-item dropdown no-arrow">
      <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false">
        <span class="mr-2 text-gray-600 small header-info">
          <strong>{{ codlojamain }}</strong> - <strong [title]="razao">{{ razao | textLength: 20 }}</strong>
          <br/>
          Sessão {{ codlojausuario }} | Usuário: {{ usuario | textLength: 10 }}
        </span>
        <img class="header-avatar" src="assets/img/person.svg" [alt]="usuario">
        <i class="fas fa-fw fa-angle-down"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
        <a *ngIf="alteraLojaSessao === true" class="dropdown-item" data-toggle="modal" data-target="#lojaSessaoModal"
          style="cursor: pointer;">
          <i class="fas fa-store fa-sm fa-fw mr-2 text-gray-400"></i>
          Alterar loja
        </a>
        <a *ngIf="alteraSenha === true" class="dropdown-item" data-toggle="modal" data-target="#alteracaoSenhaModal" style="cursor: pointer;">
          <i class="fas fa-lock fa-sm fa-fw mr-2 text-gray-400"></i>
          Alterar senha
        </a>
        <a class="dropdown-item" (click)="toggleDarkMode()" style="cursor: pointer;">
          <i class="fas fa-sm fa-fw mr-2 text-gray-400" [ngClass]="darkMode ? 'fa-sun' : 'fa-moon'"></i>
          {{ darkMode ? 'Tema claro' : 'Tema escuro' }} 
        </a>
        <a class="dropdown-item" (click)="logout()" style="cursor: pointer;">
          <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
          Sair
        </a>
      </div>
    </li>
  </ul>
</nav>
<app-loja-sessao-modal #lojaModal *ngIf="alteraLojaSessao === true"></app-loja-sessao-modal>
<app-alteracao-senha-modal #senhaModal *ngIf="alteraSenha === true"></app-alteracao-senha-modal>
