import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { RouteNamesServiceProvider } from '../../../helpers/route-names-service-provider';
import { AuthHelper } from '../../../helpers/auth-helper';
import { MenuHelper } from 'src/app/helpers/menu-helper';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { ModelGrupoMenu } from 'src/app/models/modelgrupomenu.model';
import { Tools } from 'src/app/helpers/tools';
import { EnvService } from 'src/app/services/env.service';
import { SearchMenuComponent } from '../search-menu/search-menu.component';
import { NgIf, NgFor, NgClass } from '@angular/common';
import { MenuTitleService } from 'src/app/services/menu-title.service';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    standalone: true,
    imports: [RouterLink, NgIf, SearchMenuComponent, NgFor, NgClass]
})
export class MenuComponent implements OnInit {

  public isPublic: boolean;
  public menuModel: ModelGrupoMenu[];

  public search: string = "";

  public titleBase: string = "Sequence Web Framework";
  public title: string;

  @Input() routemenu: string;
  @Input() open: boolean;

  @Output() openMenu = new EventEmitter<void>();

  constructor(
    private router: Router, 
    public routes: RouteNamesServiceProvider, 
    public usuariosService: UsuariosService, 
    public titleService: Title, 
    public env: EnvService,
    private menuTitleService: MenuTitleService
  ) {
    this.isPublic = false;
    this.menuModel = [];

    this.menuTitleService.get()
      .subscribe((title: string) => {
        this.title = title;
        this.updatePageTitle();
      });
  }
  
  ngOnInit() {
    this.isPublic = (!AuthHelper.isAuthenticated(this.env));
    this.menuModel = MenuHelper.getMenu(this.usuariosService);
    this.updatePageTitle();
  }

  filterSearch(label: string) {
    let tools = new Tools();

    if (this.search !== "") {
      let i = tools.lowerCaseAndRemoveSpecialChars(label).indexOf(tools.lowerCaseAndRemoveSpecialChars(this.search));

      if (i === -1) {
        return false;
      }
    }

    return true;
  }

  searchUpdate(search: string) {
    this.search = search;
  }

  isSingleGroup(): boolean {
    return this.menuModel.filter(item => item.getPermissoes().length > 0).length === 1;
  }

  private updatePageTitle(): void {
    let titlePage: string = "";

    if (this.title !== "" && !this.isPublic) {
      titlePage = AuthHelper.getAuthDadoEmpresa("razao") + " | " + this.title + " | " + this.titleBase;
    } else if (this.title !== "" && this.isPublic) {
      titlePage = this.title + " | " + this.titleBase;
    } else if (this.title === "" && !this.isPublic) {
      titlePage = AuthHelper.getAuthDadoEmpresa("razao") + " | " + this.titleBase;
    } else {
      titlePage = this.titleBase;
    }

    this.titleService.setTitle(titlePage);
  }

  protected openSideMenu(): void {
    if (!this.open) {
      this.openMenu.emit();
    }
  }
}
