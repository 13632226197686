import { Tools } from './../../../helpers/tools';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AuthHelper } from 'src/app/helpers/auth-helper';
import { Router, RouterLink } from '@angular/router';
import { RouteNamesServiceProvider } from 'src/app/helpers/route-names-service-provider';
import { MenuHelper } from 'src/app/helpers/menu-helper';
import { ModelGrupoMenu } from 'src/app/models/modelgrupomenu.model';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { NotificacoesService } from 'src/app/services/notificacoes.service';
import { Notificacao } from 'src/app/models/notificacao.model';
import { PermissaoHelper } from 'src/app/helpers/permissao-helper';
import { NotificacaoEnum } from 'src/app/enums/notificacaoEnum';
import { LabelModulesHelper } from 'src/app/helpers/label-modules-helper';
import { PermissoesSistemaService } from 'src/app/services/permissoessistema.service';
import { ToastrService } from 'ngx-toastr';
import { AlertsService } from 'src/app/services/alerts.service';
import { EnvService } from 'src/app/services/env.service';
import { AlteracaoSenhaModalComponent } from '../alteracao-senha-modal/alteracao-senha-modal.component';
import { LojaSessaoModalComponent } from '../loja-sessao-modal/loja-sessao-modal.component';
import { SearchMenuComponent } from '../search-menu/search-menu.component';
import { NgIf, NgClass, NgFor, DatePipe } from '@angular/common';
import { TextLengthPipe } from 'src/app/pipes/text-length.pipe';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
    standalone: true,
    imports: [
      NgIf, 
      NgClass, 
      SearchMenuComponent, 
      NgFor, 
      RouterLink, 
      LojaSessaoModalComponent, 
      AlteracaoSenhaModalComponent, 
      DatePipe, 
      TextLengthPipe
    ]
})

export class HeaderComponent implements OnInit {

  razao: string;
  codlojamain: number;
  codlojausuario: string;
  usuario: string;
  fornecedor: string;
  cliente: string;
  menuMobile: any;
  menuMobileActive: boolean;
  darkMode: boolean;
  body: HTMLBodyElement;

  @ViewChild("lojaModal") lojaSessaoModalComponent;

  @Input() openSideMenu: boolean;

  @Output() toggleMenu = new EventEmitter<void>();

  public isPublic: boolean;
  public menuModel: ModelGrupoMenu[];
  public searchMenu: string = "";
  public notificacoes: Notificacao[];
  public alteraLojaSessao: boolean;
  public alteraSenha: boolean;

  public labelLoja: string;
  public labelFornecedor: string;
  public labelCadastro: string;

  public labelModulesHelper: LabelModulesHelper;

  constructor(private toastr: ToastrService, private router: Router, public routes: RouteNamesServiceProvider, public usuariosService: UsuariosService,
    public notificacoesService: NotificacoesService, public permissaoService: PermissoesSistemaService, public alertsService: AlertsService,
    public env: EnvService) {

      this.isPublic = false;
      this.menuModel = [];
      this.notificacoes = [];
      this.alteraLojaSessao = false;
      this.alteraSenha = false;

      this.labelModulesHelper = new LabelModulesHelper();

      this.getLabelFornecedor();
      this.getLabelCadastro();
      this.getLabelLoja()
  }

  ngOnInit() {
    this.initAlerts();

    if (AuthHelper.isAuthenticated(this.env)) {

      this.razao = AuthHelper.getAuthDadoEmpresa("razao");
      this.codlojamain = AuthHelper.getAuthDadoEmpresa("codlojamain");
      this.codlojausuario = AuthHelper.getLoja();
      this.usuario = AuthHelper.getAuthDadoEmpresa("usuario");
      this.fornecedor = AuthHelper.getAuthDadoEmpresa("fornecedor");
      this.cliente = AuthHelper.getAuthDadoEmpresa("cliente");

      if (this.razao === undefined || this.codlojamain === undefined) {
        this.router.navigate([this.routes.getRoute('login')]);
      }

      this.alteraLojaSessao = PermissaoHelper.hasPermissao(472);
      this.alteraSenha = PermissaoHelper.hasPermissao(793);

      this.menuModel = MenuHelper.getMenu(this.usuariosService);

      this.body = document.querySelector("body");

      this.getNotifications();
      this.updateDarkMode();
    } else {
      this.isPublic = true;
    }
  }

  ngAfterViewInit() {
    
    if (this.lojaSessaoModalComponent) {
      this.lojaSessaoModalComponent.changeEvent.subscribe(item => {
        this.codlojausuario = AuthHelper.getLoja();
      });
    }

    this.menuMobile = document.querySelector('#menuMobile');
    this.observeMenuMobile();
  }

  observeMenuMobile(): void {
    let tools = new Tools();
 
    tools.elementObserver(
      this.menuMobile, 
      ()=> this.menuMobile.classList.contains('show') ? this.menuMobileActive = true : this.menuMobileActive = false, 
      false, 
      true
    );
  }

  logout(): void {
    AuthHelper.logout();
    this.router.navigate([this.routes.getRoute('login')]);
  }

  getNotifications() {
    let componentEnum = new NotificacaoEnum();
    let filter = new Notificacao();

    filter.destino = componentEnum.getItem("destino").getValueByDescription("Usuários Sequence");
    filter.channel = componentEnum.getItem("channel").getValueByDescription("Sequence");

    this.notificacoesService.getAllUnread(0, "dataenvio", "desc", filter).subscribe(data => {
      this.notificacoes = data.result;
    });
  }


  getLabelCadastro() {
    this.labelModulesHelper.getLabel(1, this.permissaoService).then(data => {
      this.labelCadastro = data.label;
    });
  }

  getLabelFornecedor() {
    this.labelModulesHelper.getLabel(18, this.permissaoService).then(data => {
      this.labelFornecedor = data.label;
    });
  }

  getLabelLoja() {
    this.labelModulesHelper.getLabel(20, this.permissaoService).then(data => {
      this.labelLoja = data.label;
    });
  }

  initAlerts() {
    if(localStorage.getItem("executeAfterLoginActions") != null && localStorage.getItem("executeAfterLoginActions") == "S") {
      this.alertsService.get(AuthHelper.getAuthDadoEmpresa("lg")).subscribe(data => {
        data.result.forEach(item => {
          if(item !== "") {
            this.toastr.info(item, "");
          }
        });
        localStorage.setItem("executeAfterLoginActions", "N");
      });
    }
  }

  filterSearch(label: string) {
    let tools = new Tools();

    if (this.searchMenu !== "") {
      let i = tools.lowerCaseAndRemoveSpecialChars(label).indexOf(tools.lowerCaseAndRemoveSpecialChars(this.searchMenu));

      if (i === -1) {
        return false;
      }
    }

    return true;
  }

  searchUpdate(search: string) {
    this.searchMenu = search;
  }
  
  setAsRead(notificacao: Notificacao) {
    this.notificacoesService.markAsRead(notificacao).subscribe(
      success => {
        this.router.navigate([notificacao.url])
      },
      error => console.log(error)
    );
  }
  
  setAllAsRead() {
    let notificacoes = {
      items: this.notificacoes.map(notificacao => {
        return {id: notificacao.id};
      })
    };

    this.notificacoes = [];

    this.notificacoesService.markAllAsRead(notificacoes).subscribe(
      success => {
        this.getNotifications()
      },
      error => console.log(error)
    );
  }

  toggleDarkMode() {
    AuthHelper.setAuthDadoEmpresa("darkmode", this.darkMode ? "f" : "t");
    this.updateDarkMode();

    if(AuthHelper.isDarkmode()) {
      this.usuariosService.enableDarkMode(AuthHelper.getAuthenticatedUser()).subscribe(data => {});
    } else {
      this.usuariosService.disableDarkMode(AuthHelper.getAuthenticatedUser()).subscribe(data => {});
    }
  }

  updateDarkMode() {
    if(AuthHelper.isDarkmode()) {
      this.darkMode = true;
      this.body.classList.add("darkmode");
    } else {
      this.darkMode = false;
      this.body.classList.remove("darkmode");
    }
  }

  protected toggleSideMenu(): void {
    this.toggleMenu.emit();
  }
}
