<form class="navbar-search">
  <div class="input-group col-12">
    <input type="text" [(ngModel)]="searchValue" (ngModelChange)="searchUpdate()" name="searchMenu"
      class="form-control input-search-menu small my-input-search-menu" placeholder="Buscar no menu..."
      aria-label="Buscar no menu" autofocus="autofocus" autocomplete="off">
    <div class="input-group-append">
      <button *ngIf="(searchValue === '')" class="btn btn-outline-success btn-search-menu my-bt-search-menu"
        type="button">
        <i class="fas fa-search fa-sm"></i>
      </button>
      <button *ngIf="(searchValue !== '')" (click)="clearSearch()"
        class="btn btn-outline-success btn-search-menu my-bt-search-menu" type="button">
        <i class="fas fa-times"></i>
      </button>
    </div>
  </div>
</form>