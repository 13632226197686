import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Loja } from 'src/app/models/loja.model';
import { LojasService } from 'src/app/services/lojas.service';
import { AuthHelper } from 'src/app/helpers/auth-helper';
import { LoaderComponent } from '../loader/loader.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-loja-sessao-modal',
    templateUrl: './loja-sessao-modal.component.html',
    standalone: true,
    imports: [NgIf, LoaderComponent, NgFor]
})
export class LojaSessaoModalComponent implements OnInit {

  items: Loja[];
  selectedItem: Loja;
  showLoader: boolean;

  @Output() changeEvent: EventEmitter<string>;

  constructor(public service: LojasService) {
    this.items = [];
    this.selectedItem = undefined;
    this.showLoader = true;
    this.changeEvent = new EventEmitter<string>();
  }

  ngOnInit() {
    this.service.getAllForSession().subscribe(data => {
      this.items = data.result;
      this.showLoader = false;
    });
  }

  selectItem(item: Loja): void {
    this.selectedItem = item;
    AuthHelper.setLoja(item.codloja);
    this.changeEvent.next(item.codloja + "ChangeEvent");
  }

}
