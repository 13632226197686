export class EnumOption {

    description: any;
    value: any;

    constructor(description: any, value?: any) {
        this.description = description;

        if(value === undefined) {
            this.value = description;
        } else {
            this.value = value;
        }
    }

}
