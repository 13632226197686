import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnvService {

  /**
   * Algumas das informções dispostas nesta classe, poderão ser
   * manipuladas através do ENV.json
   *
   * Analisar necessidade antes de editar este arquivo.
   */

  public production: boolean = false;

  /**
   * Rodrigo 02/08/2020
   * Valores precisam ser zerados, deverá funcionar apenas com parâmetros do ENV
   */
  public codloja: number;
  public codlojamain: number;

  public protocol: string = "https";

  public backEndUrl: string = "http://localhost/sequenceweb-backend/";
  public apiUrl: string = "http://localhost:4400/api/";
  public downloadUrl: string = "http://localhost/sequenceweb-backend/";
  public domainCookie: string;

  public loadMainTitle: string = "Sequence Web Framework";
  public contactMail: string = "sequenceweb@sequenceweb.com.br";

  public brandText: string = "Sequence Web Framework®";

  public fixedFilter: boolean = false;
  public helpUrl: string = "";
  public currencyPrefix: string = "R$";
  public currencyCountry: string = "BRL";

  public sessionExpirationTime: number = 30;
  public sequenceVersion: string = "1";

  constructor() { }
}
