import { Component, OnInit, Input } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    standalone: true,
    imports: [NgIf]
})
export class AlertComponent implements OnInit {

  @Input() type: string;
  @Input() message: string;

  constructor() {
    if (this.type === undefined) {
      this.type = "success";
    }

    if (this.message === undefined) {
      this.message = "";
    }
  }

  ngOnInit() {
  }

  getMessage() {
    let iconClass: string = "fa-asterisk";

    switch(this.type) {
      case "success":
        iconClass = "fa-check";
        break;
      case "danger":
        iconClass = "fa-times";
        break;
      case "warning":
        iconClass = "fa-exclamation";
        break;
      case "info":
        iconClass = "fa-info";
        break;
    }
    
    return "<i class='fas " + iconClass + " fa-sm mr-2'></i>" + this.message;
  }

  warning(msg: string) {
    this.type = 'warning';
    this.message = msg;
    //this.containet.scrollTop = 0;
  }

  danger(msg: string) {
    this.type = 'danger';
    this.message = msg;
  }

  success(msg: string) {
    this.type = 'success';
    this.message = msg;
  }

  clear() {
    this.type = 'success';
    this.message = '';
  }

}
