import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuTitleService {

  private title = new BehaviorSubject<string>('');

  public get(): Observable<string> {
    return this.title.asObservable();
  }

  public set(title: string): void {
    this.title.next(title);
  }
}
