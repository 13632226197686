import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Service } from './service';
import { EnvService } from './env.service';

@Injectable({
  providedIn: 'root'
})

export class AlertsService extends Service {

  constructor(protected http: HttpClient, protected env: EnvService, protected router: Router, public toastr: ToastrService) {
    super("alerts", http, env, router, toastr);
  }

  get(lg: string): Observable<any> {
    let url: string = this.baseUrl + "/" + lg;
    return this.http.get<string[]>(url, this.getHttpOptions());
  }

}
