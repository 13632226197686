<div class="input-group">
  <input [type]="(showPassword) ? 'text' : 'password'" [(ngModel)]="inputValue" name="{{ fieldName }}"
    autocomplete="off" placeholder="{{ placeHolder }}" class="{{ inputClass }}" [readonly]="inputReadonly"
    (change)="changePassword()" [disabled]="temporaryDisabled"/>
  <div class="input-group-append">
    <button class="btn btn-outline-success" type="button" 
      [copyToClipboard]="inputValue" *ngIf="copyButton && inputValue">
      <i class="far fa-copy"></i>
    </button>
    <button class="btn btn-outline-success" type="button" (click)="togglePassword()">
      <i class="fas fa-eye" *ngIf="!showPassword"></i>
      <i class="fas fa-eye-slash" *ngIf="showPassword"></i>
    </button>
  </div>
</div>
