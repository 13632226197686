import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Service } from './service';
import { EnvService } from './env.service';
import { Loja } from '../models/loja.model';
import { FilterServiceInterface } from '../helpers/filter-service-interface';
import { FotoCadastro } from '../models/fotocadastro.model';
import { ImageServiceInterface } from '../helpers/image-service-interface';
import { QueryStringBuilder } from '../helpers/query-string-builder';
import { ModuleServiceInterface } from '../helpers/module-service-interface';

@Injectable({
  providedIn: 'root'
})
export class LojasService extends Service implements ModuleServiceInterface<Loja>, FilterServiceInterface<Loja>, ImageServiceInterface {

  constructor(protected http: HttpClient, protected env: EnvService, protected router: Router, public toastr: ToastrService) {
    super("lojas", http, env, router, toastr);
  }

  getAll(page?: number, ordenation?: string, typeorder?: string, object?: any, limit?: number): Observable<any> {
    let url: string = this.getQueryString(this.baseUrl, page, ordenation, typeorder, object, limit);
    return this.http.get<Loja[]>(url, this.getHttpOptions());
  }

  getOne(cod: string): Observable<any> {
    let url: string = this.baseUrl + "/" + cod;
    return this.http.get<Loja>(url, this.getHttpOptions());
  }

  insert(item: Loja): Observable<any> {
    return this.http.post<Loja>(this.baseUrl, item, this.getHttpOptions());
  }

  update(item: Loja): Observable<any> {
    let url: string = this.baseUrl + "/" + item.id;
    return this.http.put<Loja>(url, item, this.getHttpOptions());
  }

  remove(item: Loja): Observable<any> {
    let url: string = this.baseUrl + "/" + item.id;
    return this.http.delete<Loja>(url, this.getHttpOptions());
  }

  removeSelected(object: any): Observable<any> {
    let url: string = this.baseUrl + "/delete";
    return this.http.post<any>(url, object, this.getHttpOptions());
  }

  activateSelected(object: any): Observable<any> {
    let url: string = this.baseUrl + "/ativar";
    return this.http.patch<any>(url, object, this.getHttpOptions());
  }

  deactivateSelected(object: any): Observable<any> {
    let url: string = this.baseUrl + "/desativar";
    return this.http.patch<any>(url, object, this.getHttpOptions());
  }

  editImage(idRelacao: number, item: FotoCadastro) {
    let url: string = this.baseUrl + "/" + idRelacao + "/imagens/" + item.codfotocadastro;
    return this.http.put<FotoCadastro>(url, item, this.getHttpOptions());
  }

  definirCapaImage(idRelacao: number, codfotocadastro: number): Observable<any> {
    let url: string = this.baseUrl + "/" + idRelacao + "/imagens/" + codfotocadastro;
    return this.http.patch<FotoCadastro>(url, {}, this.getHttpOptions());
  }

  removeImage(idRelacao: number, codfotocadastro: number): Observable<any> {
    let url: string = this.baseUrl + "/" + idRelacao + "/imagens/" + codfotocadastro;
    return this.http.delete<FotoCadastro>(url, this.getHttpOptions());
  }

  removeSelectedImage(idRelacao: number, object: any): Observable<any> {
    let url: string = this.baseUrl + "/" + idRelacao + "/imagens/delete";
    return this.http.post<any>(url, object, this.getHttpOptions());
  }

  checkDisparos(object: any): Observable<any> {
    let url: string = this.baseUrl + "/check-disparos";
    return this.http.post<any>(url, object, this.getHttpOptions());
  }

  // Utilizado sem paginação para troca de loja da sessão.
  getAllForSession(): Observable<any> {
    return this.http.get<Loja[]>(this.baseUrl, this.getHttpOptions());
  }

  orderImages(item: Loja, items: FotoCadastro): Observable<any> {
    let url: string = this.baseUrl + "/" + item.id + "/imagens/ordenacao";
    return this.http.put<FotoCadastro[]>(url, items, this.getHttpOptions());
  }


  getQueryString(baseUrl: string, page?: number, ordenation?: string, typeorder?: string, object?: any, limit?: number): string {
    let qsBuilder: QueryStringBuilder = this.initializeQueryStringBuilder(baseUrl, page, ordenation, typeorder, limit, true);

    if (object !== undefined) {
      qsBuilder.checkAndAddInativarParam(object.inativar);
      qsBuilder.checkAndAddParam("razao", object.razao);
      qsBuilder.checkAndAddParam("fantasia", object.fantasia);
      qsBuilder.checkAndAddParam("cnpj", object.cnpj);
      qsBuilder.checkAndAddParamNumeric("id", object.id);
    }

    return qsBuilder.build();
  }

}
