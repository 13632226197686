import { ModelRoute } from "./models/modelroute.model";

const adminGroupLabel: string = "admin";

const routes: ModelRoute[] = [
    new ModelRoute('login', 'login'),
    new ModelRoute('403', '403'),

    // Rotas Públicas

    new ModelRoute('recuperarSenha', 'recuperar-senha'),
    new ModelRoute('emailsDescadastrar', 'emails/descadastrar/:lg/:email'),
    new ModelRoute('emailsRecadastrar', 'emails/cadastrar/:lg/:email'),
    new ModelRoute('eventosCadastrar', 'eventos/cadastrar/:lg/:evento'),

    // Rotas Admin

    new ModelRoute('dashboard', adminGroupLabel + '/dashboard'),

    new ModelRoute('planoContasLista', adminGroupLabel + '/planocontas'),
    new ModelRoute('planoContasNovo', adminGroupLabel + '/planocontas/novo'),
    new ModelRoute('planoContasForm', adminGroupLabel + '/planocontas/:id'),

    new ModelRoute('emailsLista', adminGroupLabel + '/emails'),
    new ModelRoute('emailsImportador', adminGroupLabel + '/emails/importar'),
    new ModelRoute('emailsNovo', adminGroupLabel + '/emails/novo'),
    new ModelRoute('emailsForm', adminGroupLabel + '/emails/:id'),

    new ModelRoute('paginasLista', adminGroupLabel + '/paginas'),
    new ModelRoute('paginasNovo', adminGroupLabel + '/paginas/novo'),
    new ModelRoute('paginasForm', adminGroupLabel + '/paginas/:id'),

    new ModelRoute('lojasLista', adminGroupLabel + '/lojas'),
    new ModelRoute('lojasNovo', adminGroupLabel + '/lojas/novo'),
    new ModelRoute('lojasForm', adminGroupLabel + '/lojas/:id'),
    new ModelRoute('regrasFreteNovo', adminGroupLabel + '/lojas/:idrelacao/regrasfrete'),
    new ModelRoute('regrasFreteForm', adminGroupLabel + '/lojas/:idrelacao/regrasfrete/:id'),

    new ModelRoute('usuariosLista', adminGroupLabel + '/usuarios'),
    new ModelRoute('usuariosNovo', adminGroupLabel + '/usuarios/novo'),
    new ModelRoute('usuariosForm', adminGroupLabel + '/usuarios/:id'),

    new ModelRoute('categoriasPublicacoesLista', adminGroupLabel + '/categorias-publicacoes'),
    new ModelRoute('categoriasPublicacoesNovo', adminGroupLabel + '/categorias-publicacoes/novo'),
    new ModelRoute('categoriasPublicacoesForm', adminGroupLabel + '/categorias-publicacoes/:id'),

    new ModelRoute('publicacoesLista', adminGroupLabel + '/publicacoes'),
    new ModelRoute('conversorPublicacoes', adminGroupLabel + '/publicacoes/converter'),
    new ModelRoute('publicacoesNovo', adminGroupLabel + '/publicacoes/novo'),
    new ModelRoute('publicacoesForm', adminGroupLabel + '/publicacoes/:id'),
    new ModelRoute('modulosPublicacaoNovo', adminGroupLabel + '/publicacoes/:idrelacao/modulos'),
    new ModelRoute('modulosPublicacaoForm', adminGroupLabel + '/publicacoes/:idrelacao/modulos/:id'),

    new ModelRoute('aditivoSimuladorRentabilidadeLista', adminGroupLabel + '/aditivo-simuladorrentabilidade'),
    new ModelRoute('aditivoSimuladorRentabilidadeNovo', adminGroupLabel + '/aditivo-simuladorrentabilidade/novo'),
    new ModelRoute('aditivoSimuladorRentabilidadeForm', adminGroupLabel + '/aditivo-simuladorrentabilidade/:id'),

    new ModelRoute('telefonesLista', adminGroupLabel + '/telefones'),
    new ModelRoute('telefonesNovo', adminGroupLabel + '/telefones/novo'),
    new ModelRoute('telefonesForm', adminGroupLabel + '/telefones/:id'),

    new ModelRoute('gruposProdutos1Lista', adminGroupLabel + '/grupos-produtos1'),
    new ModelRoute('gruposProdutos1Novo', adminGroupLabel + '/grupos-produtos1/novo'),
    new ModelRoute('gruposProdutos1Form', adminGroupLabel + '/grupos-produtos1/:id'),

    new ModelRoute('marcasLista', adminGroupLabel + '/marcas'),
    new ModelRoute('marcasNovo', adminGroupLabel + '/marcas/novo'),
    new ModelRoute('marcasForm', adminGroupLabel + '/marcas/:id'),

    new ModelRoute('gruposTelefonesLista', adminGroupLabel + '/grupos-telefones'),
    new ModelRoute('gruposTelefonesNovo', adminGroupLabel + '/grupos-telefones/novo'),
    new ModelRoute('gruposTelefonesForm', adminGroupLabel + '/grupos-telefones/:id'),

    new ModelRoute('etapasPedidosLista', adminGroupLabel + '/etapaspedidos'),
    new ModelRoute('etapasPedidosNovo', adminGroupLabel + '/etapaspedidos/novo'),
    new ModelRoute('etapasPedidosForm', adminGroupLabel + '/etapaspedidos/:id'),

    new ModelRoute('paisesLista', adminGroupLabel + '/paises'),
    new ModelRoute('paisesNovo', adminGroupLabel + '/paises/novo'),
    new ModelRoute('paisesForm', adminGroupLabel + '/paises/:id'),

    new ModelRoute('fornecedoresLista', adminGroupLabel + '/fornecedores'),
    new ModelRoute('fornecedoresNovo', adminGroupLabel + '/fornecedores/novo'),
    new ModelRoute('fornecedoresForm', adminGroupLabel + '/fornecedores/:id'),
    new ModelRoute('condicoesFinanceirasFornecedorNovo', adminGroupLabel + '/fornecedores/:idrelacao/condicoes-financeiras'),
    new ModelRoute('condicoesFinanceirasFornecedorForm', adminGroupLabel + '/fornecedores/:idrelacao/condicoes-financeiras/:id'),

    new ModelRoute('regioesLista', adminGroupLabel + '/regioes'),
    new ModelRoute('regioesNovo', adminGroupLabel + '/regioes/novo'),
    new ModelRoute('regioesForm', adminGroupLabel + '/regioes/:id'),

    new ModelRoute('gruposColaboradoresLista', adminGroupLabel + '/gruposcolaboradores'),
    new ModelRoute('gruposColaboradoresNovo', adminGroupLabel + '/gruposcolaboradores/novo'),
    new ModelRoute('gruposColaboradoresForm', adminGroupLabel + '/gruposcolaboradores/:id'),

    new ModelRoute('categoriasProdutosLista', adminGroupLabel + '/categoriasprodutos'),
    new ModelRoute('categoriasProdutosNovo', adminGroupLabel + '/categoriasprodutos/novo'),
    new ModelRoute('categoriasProdutosForm', adminGroupLabel + '/categoriasprodutos/:id'),

    new ModelRoute('cidadesLista', adminGroupLabel + '/cidades'),
    new ModelRoute('cidadesNovo', adminGroupLabel + '/cidades/novo'),
    new ModelRoute('cidadesForm', adminGroupLabel + '/cidades/:id'),

    new ModelRoute('estadosLista', adminGroupLabel + '/estados'),
    new ModelRoute('estadosNovo', adminGroupLabel + '/estados/novo'),
    new ModelRoute('estadosForm', adminGroupLabel + '/estados/:id'),

    new ModelRoute('cargosLista', adminGroupLabel + '/cargos'),
    new ModelRoute('cargosNovo', adminGroupLabel + '/cargos/novo'),
    new ModelRoute('cargosForm', adminGroupLabel + '/cargos/:id'),

    new ModelRoute('tagsLista', adminGroupLabel + '/tags'),
    new ModelRoute('tagsNovo', adminGroupLabel + '/tags/novo'),
    new ModelRoute('tagsForm', adminGroupLabel + '/tags/:id'),

    new ModelRoute('bancosLista', adminGroupLabel + '/bancos'),
    new ModelRoute('bancosNovo', adminGroupLabel + '/bancos/novo'),
    new ModelRoute('bancosForm', adminGroupLabel + '/bancos/:id'),

    new ModelRoute('indicacoesLista', adminGroupLabel + '/indicacoes'),
    new ModelRoute('indicacoesNovo', adminGroupLabel + '/indicacoes/novo'),
    new ModelRoute('indicacoesForm', adminGroupLabel + '/indicacoes/:id'),

    new ModelRoute('profissoesLista', adminGroupLabel + '/profissoes'),
    new ModelRoute('profissoesNovo', adminGroupLabel + '/profissoes/novo'),
    new ModelRoute('profissoesForm', adminGroupLabel + '/profissoes/:id'),

    new ModelRoute('gruposContatosLista', adminGroupLabel + '/gruposcontatos'),
    new ModelRoute('gruposContatosNovo', adminGroupLabel + '/gruposcontatos/novo'),
    new ModelRoute('gruposContatosForm', adminGroupLabel + '/gruposcontatos/:id'),

    new ModelRoute('ncmLista', adminGroupLabel + '/ncm'),
    new ModelRoute('ncmNovo', adminGroupLabel + '/ncm/novo'),
    new ModelRoute('ncmForm', adminGroupLabel + '/ncm/:id'),

    new ModelRoute('grupoEspecificacoesLista', adminGroupLabel + '/gruposespecificacoes'),
    new ModelRoute('grupoEspecificacoesNovo', adminGroupLabel + '/gruposespecificacoes/novo'),
    new ModelRoute('grupoEspecificacoesForm', adminGroupLabel + '/gruposespecificacoes/:id'),

    new ModelRoute('pesLista', adminGroupLabel + '/pes'),
    new ModelRoute('pesNovo', adminGroupLabel + '/pes/novo'),
    new ModelRoute('pesForm', adminGroupLabel + '/pes/:id'),

    new ModelRoute('cfopLista', adminGroupLabel + '/cfop'),
    new ModelRoute('cfopNovo', adminGroupLabel + '/cfop/novo'),
    new ModelRoute('cfopForm', adminGroupLabel + '/cfop/:id'),

    new ModelRoute('depositosLista', adminGroupLabel + '/depositos'),
    new ModelRoute('depositosNovo', adminGroupLabel + '/depositos/novo'),
    new ModelRoute('depositosForm', adminGroupLabel + '/depositos/:id'),

    new ModelRoute('cadastrosLista', adminGroupLabel + '/cadastros'),
    new ModelRoute('cadastrosNovo', adminGroupLabel + '/cadastros/novo'),
    new ModelRoute('cadastrosForm', adminGroupLabel + '/cadastros/:id'),
    new ModelRoute('enderecosNovo', adminGroupLabel + '/cadastros/:idrelacao/enderecos'),
    new ModelRoute('enderecosForm', adminGroupLabel + '/cadastros/:idrelacao/enderecos/:id'),
    new ModelRoute('dadosBancariosCadastrosNovo', adminGroupLabel + '/cadastros/:idrelacao/dadosbancarios'),
    new ModelRoute('dadosBancariosCadastrosForm', adminGroupLabel + '/cadastros/:idrelacao/dadosbancarios/:id'),

    new ModelRoute('cadastrosSimplificadoLista', adminGroupLabel + '/cadastros-simplificado'),
    new ModelRoute('cadastrosSimplificadoNovo', adminGroupLabel + '/cadastros-simplificado/novo'),
    new ModelRoute('cadastrosSimplificadoForm', adminGroupLabel + '/cadastros-simplificado/:id'),
    new ModelRoute('enderecosSimplificadoNovo', adminGroupLabel + '/cadastros-simplificado/:idrelacao/enderecos'),
    new ModelRoute('enderecosSimplificadoForm', adminGroupLabel + '/cadastros-simplificado/:idrelacao/enderecos/:id'),
    new ModelRoute('dadosBancariosCadastrosNovo', adminGroupLabel + '/cadastros-simplificado/:idrelacao/dadosbancarios'),
    new ModelRoute('dadosBancariosCadastrosForm', adminGroupLabel + '/cadastros-simplificado/:idrelacao/dadosbancarios/:id'),

    new ModelRoute('atletasLista', adminGroupLabel + '/atletas'),
    new ModelRoute('atletasNovo', adminGroupLabel + '/atletas/novo'),
    new ModelRoute('atletasForm', adminGroupLabel + '/atletas/:id'),
    new ModelRoute('enderecosAtletasNovo', adminGroupLabel + '/atletas/:idrelacao/enderecos'),
    new ModelRoute('enderecosAtletasForm', adminGroupLabel + '/atletas/:idrelacao/enderecos/:id'),

    new ModelRoute('pacientesLista', adminGroupLabel + '/pacientes'),
    new ModelRoute('pacientesNovo', adminGroupLabel + '/pacientes/novo'),
    new ModelRoute('pacientesForm', adminGroupLabel + '/pacientes/:id'),
    new ModelRoute('enderecosPacientesNovo', adminGroupLabel + '/pacientes/:idrelacao/enderecos'),
    new ModelRoute('enderecosPacientesForm', adminGroupLabel + '/pacientes/:idrelacao/enderecos/:id'),

    new ModelRoute('deficienciaPacientesLista', adminGroupLabel + '/deficiencia-pacientes'),
    new ModelRoute('deficienciaPacientesNovo', adminGroupLabel + '/deficiencia-pacientes/novo'),
    new ModelRoute('deficienciaPacientesForm', adminGroupLabel + '/deficiencia-pacientes/:id'),

    new ModelRoute('origensLeadLista', adminGroupLabel + '/origens-leads'),
    new ModelRoute('origensLeadNovo', adminGroupLabel + '/origens-leads/novo'),
    new ModelRoute('origensLeadForm', adminGroupLabel + '/origens-leads/:id'),

    new ModelRoute('classificacoesLeadLista', adminGroupLabel + '/classificacoes-leads'),
    new ModelRoute('classificacoesLeadNovo', adminGroupLabel + '/classificacoes-leads/novo'),
    new ModelRoute('classificacoesLeadForm', adminGroupLabel + '/classificacoes-leads/:id'),

    new ModelRoute('statusLeadLista', adminGroupLabel + '/status-leads'),
    new ModelRoute('statusLeadNovo', adminGroupLabel + '/status-leads/novo'),
    new ModelRoute('statusLeadForm', adminGroupLabel + '/status-leads/:id'),

    new ModelRoute('leadsLista', adminGroupLabel + '/leads'),
    new ModelRoute('leadsNovo', adminGroupLabel + '/leads/novo'),
    new ModelRoute('leadsForm', adminGroupLabel + '/leads/:id'),

    new ModelRoute('fluxosEmpresariaisLista', adminGroupLabel + '/fluxos-empresariais'),
    new ModelRoute('fluxosEmpresariaisNovo', adminGroupLabel + '/fluxos-empresariais/novo'),
    new ModelRoute('fluxosEmpresariaisForm', adminGroupLabel + '/fluxos-empresariais/:id'),

    new ModelRoute('estagioFluxosEmpresariaisNovo', adminGroupLabel + '/fluxos-empresariais/:idrelacao/estagios'),
    new ModelRoute('estagioFluxosEmpresariaisForm', adminGroupLabel + '/fluxos-empresariais/:idrelacao/estagios/:id'),


    new ModelRoute('produtosLista', adminGroupLabel + '/produtos'),
    new ModelRoute('produtosNovo', adminGroupLabel + '/produtos/novo'),
    new ModelRoute('produtosForm', adminGroupLabel + '/produtos/:id'),
    new ModelRoute('especificacoesProdutoNovo', adminGroupLabel + '/produtos/:idrelacao/especificacoesproduto'),
    new ModelRoute('especificacoesProdutoForm', adminGroupLabel + '/produtos/:idrelacao/especificacoesproduto/:id'),
    new ModelRoute('produtosColaboradoresNovo', adminGroupLabel + '/produtos/:idrelacao/produtoscolaboradores'),
    new ModelRoute('produtosColaboradoresForm', adminGroupLabel + '/produtos/:idrelacao/produtoscolaboradores/:id'),
    new ModelRoute('produtosAgregadosNovo', adminGroupLabel + '/produtos/:idrelacao/produtosagregados'),
    new ModelRoute('produtosAgregadosForm', adminGroupLabel + '/produtos/:idrelacao/produtosagregados/:id'),

    new ModelRoute('caracteristicasProdutosNovo', adminGroupLabel + '/produtos/:idrelacao/caracteristicasprodutos'),
    new ModelRoute('caracteristicasProdutosForm', adminGroupLabel + '/produtos/:idrelacao/caracteristicasprodutos/:id'),

    new ModelRoute('produtosKitsNovo', adminGroupLabel + '/produtos/:idrelacao/produtoskits'),
    new ModelRoute('produtosKitsForm', adminGroupLabel + '/produtos/:idrelacao/produtoskits/:id'),

    new ModelRoute('pedidoVendasLista', adminGroupLabel + '/pedidovendas'),
    new ModelRoute('pedidoVendasNovo', adminGroupLabel + '/pedidovendas/novo'),
    new ModelRoute('geradorFaturamentos', adminGroupLabel + '/pedidovendas/faturamentos'),
    new ModelRoute('pedidoVendasForm', adminGroupLabel + '/pedidovendas/:id'),
    new ModelRoute('vendaItemNovo', adminGroupLabel + '/pedidovendas/:idrelacao/itens'),
    new ModelRoute('vendaItemForm', adminGroupLabel + '/pedidovendas/:idrelacao/itens/:id'),
    new ModelRoute('vendaEtapaNovo', adminGroupLabel + '/pedidovendas/:idrelacao/etapas'),
    new ModelRoute('vendaEtapaForm', adminGroupLabel + '/pedidovendas/:idrelacao/etapas/:id'),
    new ModelRoute('vendaParcelaNovo', adminGroupLabel + '/pedidovendas/:idrelacao/parcelas'),
    new ModelRoute('vendaParcelaForm', adminGroupLabel + '/pedidovendas/:idrelacao/parcelas/:id'),

    new ModelRoute('tabelaEspecificacoesProdutosLista', adminGroupLabel + '/tabelaespecificacoesprodutos'),
    new ModelRoute('tabelaEspecificacoesProdutosNovo', adminGroupLabel + '/tabelaespecificacoesprodutos/novo'),
    new ModelRoute('tabelaEspecificacoesProdutosForm', adminGroupLabel + '/tabelaespecificacoesprodutos/:id'),

    new ModelRoute('gradeProdutosLista', adminGroupLabel + '/gradeprodutos'),
    new ModelRoute('gradeProdutosNovo', adminGroupLabel + '/gradeprodutos/novo'),
    new ModelRoute('gradeProdutosForm', adminGroupLabel + '/gradeprodutos/:id'),

    new ModelRoute('ordemServicosLista', adminGroupLabel + '/ordem-servicos'),
    new ModelRoute('ordemServicosNovo', adminGroupLabel + '/ordem-servicos/novo'),
    new ModelRoute('ordemServicosForm', adminGroupLabel + '/ordem-servicos/:id'),
    new ModelRoute('produtoOrdemServicosNovo', adminGroupLabel + '/ordem-servicos/:idrelacao/produto'),
    new ModelRoute('produtoOrdemServicosForm', adminGroupLabel + '/ordem-servicos/:idrelacao/produto/:id'),

    new ModelRoute('comissoesordemservicosLista', adminGroupLabel + '/comissoes-ordem-servicos'),
    new ModelRoute('comissoesgeradasordemservicosLista', adminGroupLabel + '/comissoes-geradas-ordem-servicos'),
    new ModelRoute('comissoesgeradasordemservicosForm', adminGroupLabel + '/comissoes-geradas-ordem-servicos/:id'),

    new ModelRoute('diretivasConversaoLista', adminGroupLabel + '/diretivasconversoes'),
    new ModelRoute('diretivasConversaoNovo', adminGroupLabel + '/diretivasconversoes/novo'),
    new ModelRoute('diretivasConversaoForm', adminGroupLabel + '/diretivasconversoes/:id'),

    new ModelRoute('pushSubscriptionsLista', adminGroupLabel + '/pushsubscriptions'),
    new ModelRoute('pushSubscriptionsNovo', adminGroupLabel + '/pushsubscriptions/novo'),
    new ModelRoute('pushSubscriptionsForm', adminGroupLabel + '/pushsubscriptions/:id'),

    new ModelRoute('notificacoesLista', adminGroupLabel + '/notificacoes'),
    new ModelRoute('notificacoesNovo', adminGroupLabel + '/notificacoes/novo'),
    new ModelRoute('notificacoesForm', adminGroupLabel + '/notificacoes/:id'),

    new ModelRoute('newslettersLista', adminGroupLabel + '/newsletters'),
    new ModelRoute('newslettersNovo', adminGroupLabel + '/newsletters/novo'),
    new ModelRoute('newslettersForm', adminGroupLabel + '/newsletters/:id'),

    new ModelRoute('parametrosLista', adminGroupLabel + '/parametros'),
    new ModelRoute('parametrosNovo', adminGroupLabel + '/parametros/novo'),
    new ModelRoute('parametrosForm', adminGroupLabel + '/parametros/:id'),

    new ModelRoute('catalogoProdutos', adminGroupLabel + '/catalogo-produtos'),

    new ModelRoute('relatorioProdutos', adminGroupLabel + '/relatorios/produtos'),
    new ModelRoute('relatorioInventarioProdutos', adminGroupLabel + '/relatorios/inventario-produtos'),
    new ModelRoute('relatorioClientes', adminGroupLabel + '/relatorios/clientes'),
    new ModelRoute('relatorioCadastrosEnderecos', adminGroupLabel + '/relatorios/cadastros-enderecos'),
    new ModelRoute('relatorioArquivosGed', adminGroupLabel + '/relatorios/arquivosged'),
    new ModelRoute('relatorioAtendimentoClinico', adminGroupLabel + '/relatorios/atendimento-clinico'),
    new ModelRoute('classificacoesprojetosLista', adminGroupLabel + '/classificacoesprojetos'),
    new ModelRoute('classificacoesprojetosNovo', adminGroupLabel + '/classificacoesprojetos/novo'),
    new ModelRoute('classificacoesprojetosForm', adminGroupLabel + '/classificacoesprojetos/:id'),

    new ModelRoute('eventosLista', adminGroupLabel + '/eventos'),
    new ModelRoute('eventosNovo', adminGroupLabel + '/eventos/novo'),
    new ModelRoute('eventosForm', adminGroupLabel + '/eventos/:id'),

    new ModelRoute('portadoresLista', adminGroupLabel + '/portadores'),
    new ModelRoute('portadoresNovo', adminGroupLabel + '/portadores/novo'),
    new ModelRoute('portadoresForm', adminGroupLabel + '/portadores/:id'),

    new ModelRoute('tabelasFinanceirasLista', adminGroupLabel + '/tabelas-financeiras'),
    new ModelRoute('tabelasFinanceirasNovo', adminGroupLabel + '/tabelas-financeiras/novo'),
    new ModelRoute('tabelasFinanceirasForm', adminGroupLabel + '/tabelas-financeiras/:id'),

    new ModelRoute('condicoesFinanceirasLista', adminGroupLabel + '/condicoes-financeiras'),
    new ModelRoute('condicoesFinanceirasNovo', adminGroupLabel + '/condicoes-financeiras/novo'),
    new ModelRoute('condicoesFinanceirasForm', adminGroupLabel + '/condicoes-financeiras/:id'),

    new ModelRoute('portadoresLista', adminGroupLabel + '/portadores'),
    new ModelRoute('portadoresNovo', adminGroupLabel + '/portadores/novo'),
    new ModelRoute('portadoresForm', adminGroupLabel + '/portadores/:id'),

    new ModelRoute('contasReceberLista', adminGroupLabel + '/contas-receber'),
    new ModelRoute('contasReceberNovo', adminGroupLabel + '/contas-receber/novo'),
    new ModelRoute('contasReceberForm', adminGroupLabel + '/contas-receber/:id'),

    new ModelRoute('relatorioContasReceber', adminGroupLabel + '/relatorios/contas-receber'),
    new ModelRoute('relatorioContasPagar', adminGroupLabel + '/relatorios/contas-pagar'),

    new ModelRoute('contasReceberLancamentoNovo', adminGroupLabel + '/contas-receber/:idrelacao/lancamentos'),
    new ModelRoute('contasReceberLancamentoForm', adminGroupLabel + '/contas-receber/:idrelacao/lancamentos/:id'),

    new ModelRoute('contasPagarLista', adminGroupLabel + '/contas-pagar'),
    new ModelRoute('contasPagarNovo', adminGroupLabel + '/contas-pagar/novo'),
    new ModelRoute('contasPagarForm', adminGroupLabel + '/contas-pagar/:id'),

    new ModelRoute('contasPagarLancamentoNovo', adminGroupLabel + '/contas-pagar/:idrelacao/lancamentos'),
    new ModelRoute('contasPagarLancamentoForm', adminGroupLabel + '/contas-pagar/:idrelacao/lancamentos/:id'),

    new ModelRoute('lancamentosLista', adminGroupLabel + '/lancamentos'),
    new ModelRoute('lancamentosNovo', adminGroupLabel + '/lancamentos/novo'),
    new ModelRoute('lancamentosForm', adminGroupLabel + '/lancamentos/:id'),

    new ModelRoute('relatorioLivroCaixa', adminGroupLabel + '/relatorios/livro-caixa'),
    new ModelRoute('relatorioFluxoCaixa', adminGroupLabel + '/relatorios/fluxo-caixa'),
    new ModelRoute('relatorioDre', adminGroupLabel + '/relatorios/dre'),
    new ModelRoute('relatorioDreHorizontal', adminGroupLabel + '/relatorios/dre-horizontal'),

    new ModelRoute('tabelasGenericasLista', adminGroupLabel + '/tabelas-genericas'),
    new ModelRoute('tabelasGenericasNovo', adminGroupLabel + '/tabelas-genericas/novo'),
    new ModelRoute('tabelasGenericasForm', adminGroupLabel + '/tabelas-genericas/:id'),

    new ModelRoute('gruposEmailsLista', adminGroupLabel + '/grupos-emails'),
    new ModelRoute('gruposEmailsNovo', adminGroupLabel + '/grupos-emails/novo'),
    new ModelRoute('gruposEmailsForm', adminGroupLabel + '/grupos-emails/:id'),

    new ModelRoute('motivosDescadastroEmailLista', adminGroupLabel + '/motivos-descadastro-emails'),
    new ModelRoute('motivosDescadastroEmailNovo', adminGroupLabel + '/motivos-descadastro-emails/novo'),
    new ModelRoute('motivosDescadastroEmailForm', adminGroupLabel + '/motivos-descadastro-emails/:id'),

    new ModelRoute('gruposProdutos2Lista', adminGroupLabel + '/grupos-produtos2'),
    new ModelRoute('gruposProdutos2Novo', adminGroupLabel + '/grupos-produtos2/novo'),
    new ModelRoute('gruposProdutos2Form', adminGroupLabel + '/grupos-produtos2/:id'),

    new ModelRoute('gruposProdutos3Lista', adminGroupLabel + '/grupos-produtos3'),
    new ModelRoute('gruposProdutos3Novo', adminGroupLabel + '/grupos-produtos3/novo'),
    new ModelRoute('gruposProdutos3Form', adminGroupLabel + '/grupos-produtos3/:id'),

    new ModelRoute('gruposProdutos4Lista', adminGroupLabel + '/grupos-produtos4'),
    new ModelRoute('gruposProdutos4Novo', adminGroupLabel + '/grupos-produtos4/novo'),
    new ModelRoute('gruposProdutos4Form', adminGroupLabel + '/grupos-produtos4/:id'),

    new ModelRoute('cuponsLista', adminGroupLabel + '/cupons'),
    new ModelRoute('cuponsNovo', adminGroupLabel + '/cupons/novo'),
    new ModelRoute('cuponsForm', adminGroupLabel + '/cupons/:id'),

    new ModelRoute('edificiosLista', adminGroupLabel + '/edificios'),
    new ModelRoute('edificiosNovo', adminGroupLabel + '/edificios/novo'),
    new ModelRoute('edificiosForm', adminGroupLabel + '/edificios/:id'),

    new ModelRoute('avaliacoesProdutosLista', adminGroupLabel + '/avaliacoes-produtos'),
    new ModelRoute('avaliacoesProdutosNovo', adminGroupLabel + '/avaliacoes-produtos/novo'),
    new ModelRoute('avaliacoesProdutosForm', adminGroupLabel + '/avaliacoes-produtos/:id'),

    new ModelRoute('opcoesGeraisLista', adminGroupLabel + '/opcoes-gerais'),
    new ModelRoute('opcoesGeraisNovo', adminGroupLabel + '/opcoes-gerais/novo'),
    new ModelRoute('opcoesGeraisForm', adminGroupLabel + '/opcoes-gerais/:id'),

    new ModelRoute('estoquesLista', adminGroupLabel + '/estoques'),
    new ModelRoute('estoquesNovo', adminGroupLabel + '/estoques/novo'),
    new ModelRoute('estoquesForm', adminGroupLabel + '/estoques/:id'),

    new ModelRoute('bairrosLista', adminGroupLabel + '/bairros'),
    new ModelRoute('bairrosNovo', adminGroupLabel + '/bairros/novo'),
    new ModelRoute('bairrosForm', adminGroupLabel + '/bairros/:id'),

    new ModelRoute('dadosBancariosLista', adminGroupLabel + '/dados-bancarios'),
    new ModelRoute('dadosBancariosNovo', adminGroupLabel + '/dados-bancarios/novo'),
    new ModelRoute('dadosBancariosForm', adminGroupLabel + '/dados-bancarios/:id'),

    new ModelRoute('setoresLista', adminGroupLabel + '/setores'),
    new ModelRoute('setoresNovo', adminGroupLabel + '/setores/novo'),
    new ModelRoute('setoresForm', adminGroupLabel + '/setores/:id'),

    new ModelRoute('funcoesLista', adminGroupLabel + '/funcoes'),
    new ModelRoute('funcoesNovo', adminGroupLabel + '/funcoes/novo'),
    new ModelRoute('funcoesForm', adminGroupLabel + '/funcoes/:id'),

    new ModelRoute('funcionariosLista', adminGroupLabel + '/funcionarios'),
    new ModelRoute('funcionariosNovo', adminGroupLabel + '/funcionarios/novo'),
    new ModelRoute('funcionariosForm', adminGroupLabel + '/funcionarios/:id'),

    new ModelRoute('motivosMovimentacaoLista', adminGroupLabel + '/motivos-movimentacao'),
    new ModelRoute('motivosMovimentacaoNovo', adminGroupLabel + '/motivos-movimentacao/novo'),
    new ModelRoute('motivosMovimentacaoForm', adminGroupLabel + '/motivos-movimentacao/:id'),

    new ModelRoute('produtosClienteLista', adminGroupLabel + '/produtos-cliente'),
    new ModelRoute('produtosClienteNovo', adminGroupLabel + '/produtos-cliente/novo'),
    new ModelRoute('produtosClienteForm', adminGroupLabel + '/produtos-cliente/:id'),

    new ModelRoute('produtosMarketPlacesLista', adminGroupLabel + '/produtos-marketplaces'),
    new ModelRoute('produtosMarketPlacesReplicador', adminGroupLabel + '/produtos-marketplaces/replicar'),
    new ModelRoute('produtosMarketPlacesNovo', adminGroupLabel + '/produtos-marketplaces/novo'),
    new ModelRoute('produtosMarketPlacesForm', adminGroupLabel + '/produtos-marketplaces/:id'),

    new ModelRoute('movimentosEpiLista', adminGroupLabel + '/movimentos-epi'),
    new ModelRoute('movimentosEpiNovo', adminGroupLabel + '/movimentos-epi/novo'),
    new ModelRoute('movimentosEpiForm', adminGroupLabel + '/movimentos-epi/:id'),
    new ModelRoute('movimentosEpiItensNovo', adminGroupLabel + '/movimentos-epi/:idrelacao/itens'),
    new ModelRoute('movimentosEpiItensForm', adminGroupLabel + '/movimentos-epi/:idrelacao/itens/:id'),

    new ModelRoute('estoquesEpiLista', adminGroupLabel + '/estoques-epi'),
    new ModelRoute('estoquesEpiNovo', adminGroupLabel + '/estoques-epi/novo'),
    new ModelRoute('estoquesEpiForm', adminGroupLabel + '/estoques-epi/:id'),

    new ModelRoute('permissoesLista', adminGroupLabel + '/permissoes'),
    new ModelRoute('permissoesNovo', adminGroupLabel + '/permissoes/novo'),
    new ModelRoute('permissoesForm', adminGroupLabel + '/permissoes/:id'),

    new ModelRoute('relatorioEpisEmpresa', adminGroupLabel + '/relatorios/produtos-empresa'),
    new ModelRoute('relatorioEpisFuncionario', adminGroupLabel + '/relatorios/epis-funcionario'),

    new ModelRoute('pacotesLista', adminGroupLabel + '/pacotes'),
    new ModelRoute('pacotesNovo', adminGroupLabel + '/pacotes/novo'),
    new ModelRoute('pacotesForm', adminGroupLabel + '/pacotes/:id'),

    new ModelRoute('periodicidadesLista', adminGroupLabel + '/periodicidades'),
    new ModelRoute('periodicidadesNovo', adminGroupLabel + '/periodicidades/novo'),
    new ModelRoute('periodicidadesForm', adminGroupLabel + '/periodicidades/:id'),

    new ModelRoute('planosLista', adminGroupLabel + '/planos'),
    new ModelRoute('planosNovo', adminGroupLabel + '/planos/novo'),
    new ModelRoute('planosForm', adminGroupLabel + '/planos/:id'),

    new ModelRoute('dashboardsLista', adminGroupLabel + '/dashboards'),
    new ModelRoute('dashboardsNovo', adminGroupLabel + '/dashboards/novo'),
    new ModelRoute('dashboardsForm', adminGroupLabel + '/dashboards/:id'),

    new ModelRoute('dadosDashboardLista', adminGroupLabel + '/dados-dashboard'),
    new ModelRoute('dadosDashboardNovo', adminGroupLabel + '/dados-dashboard/novo'),
    new ModelRoute('dadosDashboardForm', adminGroupLabel + '/dados-dashboard/:id'),

    new ModelRoute('relatorioEpis', adminGroupLabel + '/relatorios/epis'),
    new ModelRoute('relatorioEpisCliente', adminGroupLabel + '/relatorios/epis-cliente'),
    new ModelRoute('relatorioPedidoVendas', adminGroupLabel + '/relatorios/pedido-vendas'),

    new ModelRoute('orcamentoVendasLista', adminGroupLabel + '/orcamentos-venda'),
    new ModelRoute('orcamentoVendasNovo', adminGroupLabel + '/orcamentos-venda/novo'),
    new ModelRoute('orcamentoVendasForm', adminGroupLabel + '/orcamentos-venda/:id'),
    new ModelRoute('orcamentoVendasItensNovo', adminGroupLabel + '/orcamentos-venda/:idrelacao/itens'),
    new ModelRoute('orcamentoVendasItensForm', adminGroupLabel + '/orcamentos-venda/:idrelacao/itens/:id'),

    new ModelRoute('orcamentoVendasProjetosNovo', adminGroupLabel + '/orcamentos-venda/:idrelacao/projetos'),
    new ModelRoute('orcamentoVendasProjetosForm', adminGroupLabel + '/orcamentos-venda/:idrelacao/projetos/:id'),

    new ModelRoute('orcamentoVendasProdutosProjetosNovo', adminGroupLabel + '/orcamentos-venda/:idrelacao/projetos/:idprojeto/produtos'),
    new ModelRoute('orcamentoVendasProdutosProjetosForm', adminGroupLabel + '/orcamentos-venda/:idrelacao/projetos/:idprojeto/produtos/:id'),

    new ModelRoute('relatorioEntregasEpi', adminGroupLabel + '/relatorios/entrega-epis'),

    new ModelRoute('posicoesAtletasLista', adminGroupLabel + '/posicoes-atletas'),
    new ModelRoute('posicoesAtletasNovo', adminGroupLabel + '/posicoes-atletas/novo'),
    new ModelRoute('posicoesAtletasForm', adminGroupLabel + '/posicoes-atletas/:id'),

    new ModelRoute('turmasAtletasLista', adminGroupLabel + '/turmas-atletas'),
    new ModelRoute('turmasAtletasNovo', adminGroupLabel + '/turmas-atletas/novo'),
    new ModelRoute('turmasAtletasForm', adminGroupLabel + '/turmas-atletas/:id'),

    new ModelRoute('categoriasAtletasLista', adminGroupLabel + '/categorias-atletas'),
    new ModelRoute('categoriasAtletasNovo', adminGroupLabel + '/categorias-atletas/novo'),
    new ModelRoute('categoriasAtletasForm', adminGroupLabel + '/categorias-atletas/:id'),

    new ModelRoute('locaisAvaliacaoLista', adminGroupLabel + '/locais-avaliacao'),
    new ModelRoute('locaisAvaliacaoNovo', adminGroupLabel + '/locais-avaliacao/novo'),
    new ModelRoute('locaisAvaliacaoForm', adminGroupLabel + '/locais-avaliacao/:id'),

    new ModelRoute('finalidadesAvaliacaoColetivaLista', adminGroupLabel + '/finalidades-avaliacao-coletiva'),
    new ModelRoute('finalidadesAvaliacaoColetivaNovo', adminGroupLabel + '/finalidades-avaliacao-coletiva/novo'),
    new ModelRoute('finalidadesAvaliacaoColetivaForm', adminGroupLabel + '/finalidades-avaliacao-coletiva/:id'),

    new ModelRoute('objetivosAvaliacaoIndividualLista', adminGroupLabel + '/objetivos-avaliacao-individual'),
    new ModelRoute('objetivosAvaliacaoIndividualNovo', adminGroupLabel + '/objetivos-avaliacao-individual/novo'),
    new ModelRoute('objetivosAvaliacaoIndividualForm', adminGroupLabel + '/objetivos-avaliacao-individual/:id'),

    new ModelRoute('avaliacoesIndividuaisLista', adminGroupLabel + '/avaliacoes-individuais'),
    new ModelRoute('avaliacoesIndividuaisNovo', adminGroupLabel + '/avaliacoes-individuais/novo'),
    new ModelRoute('avaliacoesIndividuaisForm', adminGroupLabel + '/avaliacoes-individuais/:id'),

    new ModelRoute('avaliacoesColetivasLista', adminGroupLabel + '/avaliacoes-coletivas'),
    new ModelRoute('avaliacoesColetivasNovo', adminGroupLabel + '/avaliacoes-coletivas/novo'),
    new ModelRoute('avaliacoesColetivasForm', adminGroupLabel + '/avaliacoes-coletivas/:id'),

    new ModelRoute('atendimentoClinicoLista', adminGroupLabel + '/atendimento-clinico'),
    new ModelRoute('atendimentoClinicoNovo', adminGroupLabel + '/atendimento-clinico/novo'),
    new ModelRoute('atendimentoClinicoForm', adminGroupLabel + '/atendimento-clinico/:id'),

    new ModelRoute('pesquisasLista', adminGroupLabel + '/pesquisas'),
    new ModelRoute('pesquisasNovo', adminGroupLabel + '/pesquisas/novo'),
    new ModelRoute('pesquisasForm', adminGroupLabel + '/pesquisas/:id'),
    new ModelRoute('perguntasPesquisasNovo', adminGroupLabel + '/pesquisas/:idrelacao/perguntas'),
    new ModelRoute('perguntasPesquisasForm', adminGroupLabel + '/pesquisas/:idrelacao/perguntas/:id'),
    new ModelRoute('opcoesRespostasPesquisasNovo', adminGroupLabel + '/pesquisas/:idrelacao/perguntas/:idpergunta/opcoes-respota'),
    new ModelRoute('opcoesRespostasPesquisasForm', adminGroupLabel + '/pesquisas/:idrelacao/perguntas/:idpergunta/opcoes-respota/:id'),

    new ModelRoute('relatorioComprovantesEntrega', adminGroupLabel + '/relatorios/comprovantes-entrega'),
    new ModelRoute('relatorioAvaliacoesColetivas', adminGroupLabel + '/relatorios/avaliacoes-coletivas'),
    new ModelRoute('relatorioAvaliacoesIndividuais', adminGroupLabel + '/relatorios/avaliacoes-individuais'),
    new ModelRoute('relatorioAtletas', adminGroupLabel + '/relatorios/atletas'),
    new ModelRoute('relatorioEpisProximosExpiarcaoUso', adminGroupLabel + '/relatorios/epis-proximos-expiracao-uso'),

    new ModelRoute('etapasPedidoCompraLista', adminGroupLabel + '/etapas-pedidos-compra'),
    new ModelRoute('etapasPedidoCompraNovo', adminGroupLabel + '/etapas-pedidos-compra/novo'),
    new ModelRoute('etapasPedidoCompraForm', adminGroupLabel + '/etapas-pedidos-compra/:id'),

    new ModelRoute('pedidoComprasLista', adminGroupLabel + '/pedidos-compra'),
    new ModelRoute('pedidoComprasNovo', adminGroupLabel + '/pedidos-compra/novo'),
    new ModelRoute('pedidoComprasForm', adminGroupLabel + '/pedidos-compra/:id'),
    new ModelRoute('pedidoComprasItensNovo', adminGroupLabel + '/pedidos-compra/:idrelacao/itens'),
    new ModelRoute('pedidoComprasItensForm', adminGroupLabel + '/pedidos-compra/:idrelacao/itens/:id'),
    new ModelRoute('pedidoComprasEtapasNovo', adminGroupLabel + '/pedidos-compra/:idrelacao/etapas'),
    new ModelRoute('pedidoComprasEtapasForm', adminGroupLabel + '/pedidos-compra/:idrelacao/etapas/:id'),

    new ModelRoute('funcoesUsuarioLista', adminGroupLabel + '/funcoes-usuario'),
    new ModelRoute('funcoesUsuarioNovo', adminGroupLabel + '/funcoes-usuario/novo'),
    new ModelRoute('funcoesUsuarioForm', adminGroupLabel + '/funcoes-usuario/:id'),

    new ModelRoute('idiomasLista', adminGroupLabel + '/idiomas'),
    new ModelRoute('idiomasNovo', adminGroupLabel + '/idiomas/novo'),
    new ModelRoute('idiomasForm', adminGroupLabel + '/idiomas/:id'),

    new ModelRoute('itensOpenCartLista', adminGroupLabel + '/carrinhos-loja'),
    new ModelRoute('aniversariantesLista', adminGroupLabel + '/aniversariantes'),
    new ModelRoute('relatorioComissoesGeradas', adminGroupLabel + '/relatorios/comissoes-geradas'),

    new ModelRoute('permissoesAPILista', adminGroupLabel + '/permissoes-api'),
    new ModelRoute('permissoesAPINovo', adminGroupLabel + '/permissoes-api/novo'),
    new ModelRoute('permissoesAPIForm', adminGroupLabel + '/permissoes-api/:id'),

    new ModelRoute('faturamentosLista', adminGroupLabel + '/faturamentos'),
    new ModelRoute('faturamentosNovo', adminGroupLabel + '/faturamentos/novo'),
    new ModelRoute('faturamentosForm', adminGroupLabel + '/faturamentos/:id'),
    new ModelRoute('faturamentosItensNovo', adminGroupLabel + '/faturamentos/:idrelacao/itens'),
    new ModelRoute('faturamentosItensForm', adminGroupLabel + '/faturamentos/:idrelacao/itens/:id'),

    new ModelRoute('departamentosUsuarioLista', adminGroupLabel + '/departamentos-usuario'),
    new ModelRoute('departamentosUsuarioNovo', adminGroupLabel + '/departamentos-usuario/novo'),
    new ModelRoute('departamentosUsuarioForm', adminGroupLabel + '/departamentos-usuario/:id'),

    new ModelRoute('gruposUsuarioLista', adminGroupLabel + '/grupos-usuario'),
    new ModelRoute('gruposUsuarioNovo', adminGroupLabel + '/grupos-usuario/novo'),
    new ModelRoute('gruposUsuarioForm', adminGroupLabel + '/grupos-usuario/:id'),

    new ModelRoute('departamentosTicketAtendimentoLista', adminGroupLabel + '/departamentos-ticket'),
    new ModelRoute('departamentosTicketAtendimentoNovo', adminGroupLabel + '/departamentos-ticket/novo'),
    new ModelRoute('departamentosTicketAtendimentoForm', adminGroupLabel + '/departamentos-ticket/:id'),

    new ModelRoute('statusTicketAtendimentoLista', adminGroupLabel + '/status-ticket'),
    new ModelRoute('statusTicketAtendimentoNovo', adminGroupLabel + '/status-ticket/novo'),
    new ModelRoute('statusTicketAtendimentoForm', adminGroupLabel + '/status-ticket/:id'),

    new ModelRoute('ticketsAtendimentoLista', adminGroupLabel + '/tickets'),
    new ModelRoute('ticketsAtendimentoNovo', adminGroupLabel + '/tickets/novo'),
    new ModelRoute('ticketsAtendimentoForm', adminGroupLabel + '/tickets/:id'),
    new ModelRoute('interacoesTicketsAtendimentoNovo', adminGroupLabel + '/tickets/:idrelacao/interacoes'),
    new ModelRoute('interacoesTicketsAtendimentoForm', adminGroupLabel + '/tickets/:idrelacao/interacoes/:id'),

    new ModelRoute('skuManager', adminGroupLabel + '/produtos/sku-manager'),

    new ModelRoute('biFiltroAvaliacaoColetiva', adminGroupLabel + '/bi/avaliacoes-coletivas'),
    new ModelRoute('biPainelAvaliacaoColetiva', adminGroupLabel + '/bi/avaliacoes-coletivas/resultado'),
    new ModelRoute('biFiltroAvaliacaoIndividual', adminGroupLabel + '/bi/avaliacoes-individuais'),
    new ModelRoute('biPainelAvaliacaoIndividual', adminGroupLabel + '/bi/avaliacoes-individuais/resultado'),

    new ModelRoute('permissoesMarketplaceLista', adminGroupLabel + '/permissoes-marketplace'),
    new ModelRoute('permissoesMarketplaceNovo', adminGroupLabel + '/permissoes-marketplace/novo'),
    new ModelRoute('permissoesMarketplaceForm', adminGroupLabel + '/permissoes-marketplace/:id'),

    new ModelRoute('seriesNotasLista', adminGroupLabel + '/series-notas'),
    new ModelRoute('seriesNotasNovo', adminGroupLabel + '/series-notas/novo'),
    new ModelRoute('seriesNotasForm', adminGroupLabel + '/series-notas/:id'),

    new ModelRoute('spedNfeLista', adminGroupLabel + '/sped-nfe'),
    new ModelRoute('spedNfeForm', adminGroupLabel + '/sped-nfe/:id'),
    new ModelRoute('mensagensSpedNfeForm', adminGroupLabel + '/sped-nfe/:idrelacao/mensagens/:id'),

    new ModelRoute('certificadosLista', adminGroupLabel + '/certificados'),
    new ModelRoute('certificadosNovo', adminGroupLabel + '/certificados/novo'),
    new ModelRoute('certificadosForm', adminGroupLabel + '/certificados/:id'),

    new ModelRoute('orcamentosPaineisEnergiaLista', adminGroupLabel + '/orcamentos-paineis-energia'),
    new ModelRoute('orcamentosPaineisEnergiaNovo', adminGroupLabel + '/orcamentos-paineis-energia/novo'),
    new ModelRoute('orcamentosPaineisEnergiaForm', adminGroupLabel + '/orcamentos-paineis-energia/:id'),

    new ModelRoute('previewPedidosVenda', adminGroupLabel + '/pedidovendas/preview/:id'),
    new ModelRoute('disponibilidadeProdutos', adminGroupLabel + '/disponibilidade-produtos'),
    new ModelRoute('acompanhamentoPedidos', adminGroupLabel + '/acompanhamento-pedidos'),

    new ModelRoute('monitorGatewaysMarketplaceLista', adminGroupLabel + '/monitorgatewaysmarketplaces'),
    new ModelRoute('atualizadorPrecoProdutoLista', adminGroupLabel + '/atualizadorprecoproduto'),

    new ModelRoute('tiposPacoteLista', adminGroupLabel + '/tipos-pacote'),
    new ModelRoute('tiposPacoteNovo', adminGroupLabel + '/tipos-pacote/novo'),
    new ModelRoute('tiposPacoteForm', adminGroupLabel + '/tipos-pacote/:id'),

    new ModelRoute('etapasPedidosMarketplaceLista', adminGroupLabel + '/etapas-pedidos-marketplace'),
    new ModelRoute('etapasPedidosMarketplaceNovo', adminGroupLabel + '/etapas-pedidos-marketplace/novo'),
    new ModelRoute('etapasPedidosMarketplaceForm', adminGroupLabel + '/etapas-pedidos-marketplace/:id'),

    new ModelRoute('nucleosEsportivosLista', adminGroupLabel + '/nucleos-esportivos'),
    new ModelRoute('nucleosEsportivosNovo', adminGroupLabel + '/nucleos-esportivos/novo'),
    new ModelRoute('nucleosEsportivosForm', adminGroupLabel + '/nucleos-esportivos/:id'),

    new ModelRoute('escalasEsportivas', adminGroupLabel + '/escalas-esportivas'),

    new ModelRoute('previewFaturamentos', adminGroupLabel + '/faturamentos/preview/:id'),

    new ModelRoute('queryExecute', adminGroupLabel + '/query-execute'),

    new ModelRoute('instanciasWorkersLista', adminGroupLabel + '/instancias-workers'),
    new ModelRoute('instanciasWorkersNovo', adminGroupLabel + '/instancias-workers/novo'),
    new ModelRoute('instanciasWorkersForm', adminGroupLabel + '/instancias-workers/:id'),
    new ModelRoute('workersProdutosMarketplacesLista', adminGroupLabel + '/worker-produtos-marketplaces'),

    new ModelRoute('relatorioTotaisProdutosMarketplace', adminGroupLabel + '/relatorios/total-produtos-marketplace'),

    new ModelRoute('diretoriosGedLista', adminGroupLabel + '/diretorios-ged'),
    new ModelRoute('diretoriosGedNovo', adminGroupLabel + '/diretorios-ged/novo'),
    new ModelRoute('diretoriosGedForm', adminGroupLabel + '/diretorios-ged/:id'),

    new ModelRoute('arquivosGedLista', adminGroupLabel + '/arquivos-ged'),
    new ModelRoute('arquivosGedNovo', adminGroupLabel + '/arquivos-ged/novo'),
    new ModelRoute('arquivosGedForm', adminGroupLabel + '/arquivos-ged/:id'),

    new ModelRoute('agendamentosLista', adminGroupLabel + '/agendamentos'),
    new ModelRoute('agendamentosNovo', adminGroupLabel + '/agendamentos/novo'),
    new ModelRoute('agendamentosForm', adminGroupLabel + '/agendamentos/:id'),

    new ModelRoute('checkinMercadoriasLista', adminGroupLabel + '/checkin-mercadorias'),
    new ModelRoute('checkinMercadoriasNovo', adminGroupLabel + '/checkin-mercadorias/novo'),
    new ModelRoute('checkinMercadoriasForm', adminGroupLabel + '/checkin-mercadorias/:id'),

    new ModelRoute('movimentacoesCreditoClienteLista', adminGroupLabel + '/movimentacoes-credito-cliente'),
    new ModelRoute('movimentacoesCreditoClienteNovo', adminGroupLabel + '/movimentacoes-credito-cliente/novo'),
    new ModelRoute('movimentacoesCreditoClienteForm', adminGroupLabel + '/movimentacoes-credito-cliente/:id'),

    new ModelRoute('tiposEmbalagemLista', adminGroupLabel + '/tipos-embalagem'),
    new ModelRoute('tiposEmbalagemNovo', adminGroupLabel + '/tipos-embalagem/novo'),
    new ModelRoute('tiposEmbalagemForm', adminGroupLabel + '/tipos-embalagem/:id'),

    new ModelRoute('solicitacoesCompraLista', adminGroupLabel + '/solicitacoes-compra'),
    new ModelRoute('solicitacoesCompraNovo', adminGroupLabel + '/solicitacoes-compra/novo'),
    new ModelRoute('solicitacoesCompraForm', adminGroupLabel + '/solicitacoes-compra/:id'),

    new ModelRoute('relatorioPedidoCompras', adminGroupLabel + '/relatorios/pedido-compras'),

    new ModelRoute('boletosLista', adminGroupLabel + '/boletos'),
    new ModelRoute('boletosNovo', adminGroupLabel + '/boletos/novo'),
    new ModelRoute('boletosForm', adminGroupLabel + '/boletos/:id'),

    new ModelRoute('gatewaysPagamentoLista', adminGroupLabel + '/gateways-pagamento'),
    new ModelRoute('gatewaysPagamentoNovo', adminGroupLabel + '/gateways-pagamento/novo'),
    new ModelRoute('gatewaysPagamentoForm', adminGroupLabel + '/gateways-pagamento/:id'),

    new ModelRoute('agendamentosDiaLista', adminGroupLabel + '/agendamentos-dia'),
    new ModelRoute('agendamentosDiaNovo', adminGroupLabel + '/agendamentos-dia/novo'),
    new ModelRoute('agendamentosDiaForm', adminGroupLabel + '/agendamentos-dia/:id'),

    new ModelRoute('relatorioBoletos', adminGroupLabel + '/relatorios/boletos'),

    new ModelRoute('finalidadeAtendimentoClinicoLista', adminGroupLabel + '/finalidades-atendimento-clinico'),
    new ModelRoute('finalidadeAtendimentoClinicoNovo', adminGroupLabel + '/finalidades-atendimento-clinico/novo'),
    new ModelRoute('finalidadeAtendimentoClinicoForm', adminGroupLabel + '/finalidades-atendimento-clinico/:id'),
]

export default routes;
