<button #openButton type="button" data-toggle="modal" data-target="#modalConfirm" tabindex="-1"></button>
<div class="modal fade" id="modalConfirm" data-backdrop="static"
  tabindex="-1" role="dialog" aria-hidden="true" data-keyboard="false">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div *ngIf="options && options.title" class="modal-header">
        <h4 class="modal-title">{{ options.title }}</h4>
      </div>
      <div class="modal-body">
        <div class="panel panel-default">
          <div class="panel-body">
            <p *ngIf="options && options.text">
              {{ options.text }}
            </p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-success" type="button" data-dismiss="modal" (click)="confirm(true)">
          {{ options && options.labelConfirm ? options.labelConfirm : 'Sim' }}
        </button>
        <button class="btn btn-danger" type="button" data-dismiss="modal" (click)="confirm(false)">
          {{ options && options.labelCancel ? options.labelCancel : 'Não' }}
        </button>
      </div>
    </div>
  </div>
</div>