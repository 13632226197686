import { EnumOption } from './enumOption';

export class EnumOptionUsuario extends EnumOption {

    icon: any;

    constructor(description: any, value?: any, icon?: any) {
        super(description, value);

        if(icon === undefined) {
            this.icon = "";
        } else {
            this.icon = icon;
        }
    }

}
