import { EnvService } from "../services/env.service";
import { AuthHelper } from "./auth-helper";

export class Tools {

    fetchBlob(url: string, env: EnvService, callback: Function, callbackError?: Function) {
        let options = {
            method: 'GET',
            headers: {
                'sequence-version': env.sequenceVersion,
                'access-control-allow-origin': '*',
                'access-control-allow-headers': 'x-requested-with, content-type, accept, origin, authorization',
                'access-control-allow-methods': 'get, post, put, patch, delete, options',
                'lg': AuthHelper.getAuthDadoEmpresa("lg")
            }
        };

        fetch(url, options)
            .then(response => {
                if (response.ok) {
                    return response.blob();
                } else {
                    throw new Error(`${response.status}(${response.statusText}): ${response.url}`);
                }
            })
            .then(blob => callback(blob))
            .catch(error => callbackError ? callbackError() : console.error(error));
    }

    lowerCaseAndRemoveSpecialChars = function (str: string) {
        return str.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }

    checkBoxVerify = function (value: string) {
        return value == 't';
    }

    getBoolean = function (value: string): boolean {
        return ((value == 'true') || (value == 't'));
    }

    getBooleanText = function (value: string) {
        return this.getBoolean(value) ? "Sim" : "Não";
    }

    timeClear = function (value: string): string {
        return (value == '00:00:00' ? '' : value);
    }

    getCurrentDate = function (days: number = 0) {
        let date: Date = new Date();

        let dataNew = new Date(date.getTime() + (days * 24 * 60 * 60 * 1000));

        return dataNew.getFullYear() + "-" + (1 + dataNew.getMonth()).toString().padStart(2, '0') + "-" + dataNew.getDate().toString().padStart(2, '0');
    }


    getCurrentMonth = function () {
        let date: Date = new Date();
        let dataNew = new Date(date.getTime() + (0 * 24 * 60 * 60 * 1000));
        return (1 + dataNew.getMonth()).toString().padStart(2, '0');
    }

    getCurrentYear = function () {
        let date: Date = new Date();
        let dataNew = new Date(date.getTime() + (0 * 24 * 60 * 60 * 1000));
        return dataNew.getFullYear() + '';
    }

    getCurrentTime() {
        let today = new Date();

        let hora: number = today.getHours();
        let formato_hora: string = "";

        let minuto: number = today.getMinutes();
        let formato_minuto: string = "";

        formato_hora = hora.toString();
        formato_minuto = minuto.toString();

        if (hora < 10) {
            formato_hora = "0" + hora;
        }

        if (minuto < 10) {
            formato_minuto = "0" + minuto;
        }

        return formato_hora + ":" + formato_minuto;
    }


    empty = function (value: string) {
        return ((value == undefined) || (value == ''));
    }

    getUrlImage = function (env: EnvService, codfotocadastro: number) {
        return env.apiUrl + "get-image/" + codfotocadastro;
    }

    cleanTexts = function (value: string): string {
        return value.replace(/\D/g, '');
    }

    whatsappLink = function (phone: string, text: string = '') {
        let link: string = '';

        if (phone != '' || phone != undefined || phone != null) {
            link = "https://api.whatsapp.com/send?phone=55" + this.cleanTexts(phone) + "&text=" + encodeURI(text);
        }

        return link;
    }

    telLink = function (phone: string) {
        let link: string = '';

        if (phone != '' || phone != undefined || phone != null) {
            link = "tel:0" + this.cleanTexts(phone);
        }

        return link;
    }

    elementObserver(element: Node, callback: MutationCallback, observeChild: boolean, observeAttr: boolean) {
        const observer = new MutationObserver(callback);

        observer.observe(element, { childList: observeChild, attributes: observeAttr });
    }


    humanizeSize(bytes) {
        if (bytes == 0) { return "0.00 B"; }
        var e = Math.floor(Math.log(bytes) / Math.log(1024));
        return (bytes / Math.pow(1024, e)).toFixed(2) + ' ' + ' KMGTP'.charAt(e) + 'B';
    }
}
