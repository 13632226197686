import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfirmOptions } from '../components/includes/confirm/confirm-options.interface';

@Injectable({
  providedIn: 'root'
})
export class ConfirmService {

  private options = new BehaviorSubject<ConfirmOptions|null>(null);

  public get(): Observable<ConfirmOptions|null> {
    return this.options.asObservable();
  }

  public set(options: ConfirmOptions): void {
    this.options.next(options);
  }
}
