import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './helpers/auth.guard';

const routes: Routes = [
  { 
    path: '', 
    redirectTo: 'admin/dashboard', 
    pathMatch: 'full' 
  },{ 
    path: 'login', 
    loadComponent: () => import ('./components/login/login.component') 
  },{ 
    path: '404', 
    loadComponent: () => import ('./components/login/login.component') 
  },{ 
    path: '403', 
    loadComponent: () => import ('./components/error403/error403.component') 
  },{ 
    path: 'recuperar-senha', 
    loadComponent: () => import ('./components/recuperacao-senha/recuperacao-senha.component') 
  },{ 
    path: 'emails/descadastrar/:lg/:email', 
    loadComponent: () => import ('./components/publics/email-unsubscribe/email-unsubscribe.component'),
    data: { menuRoute: '', title: 'Descadastramento de e-mail' }
  },{ 
    path: 'emails/cadastrar/:lg/:email', 
    loadComponent: () => import ('./components/publics/email-subscribe/email-subscribe.component'),
    data: { menuRoute: '', title: 'Inscrição de e-mail' }
  },{ 
    path: 'eventos/cadastrar/:lg/:evento', 
    loadComponent: () => import ('./components/publics/evento-cadastro/evento-cadastro.component') 
  },{ 
    path: 'admin/dashboard', 
    loadComponent: () => import ('./components/dashboard/dashboard.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: '', title: '' }
  },{ 
    path: 'admin/emails', 
    loadComponent: () => import ('./components/emails/email-list/email-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'emailsLista' }
  },{ 
    path: 'admin/emails/importar', 
    loadComponent: () => import ('./components/emails/importador-lista-emails/importador-lista-emails.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'emailsLista', title: 'Importar de Lista de E-mails' }
  },{ 
    path: 'admin/emails/novo', 
    loadComponent: () => import ('./components/emails/email-form/email-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'emailsLista' }
  },{ 
    path: 'admin/emails/:id', 
    loadComponent: () => import ('./components/emails/email-form/email-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'emailsLista' }
  },{ 
    path: 'admin/planocontas', 
    loadComponent: () => import ('./components/planocontas/planoconta-list/planoconta-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'planoContasLista' }
  },{ 
    path: 'admin/planocontas/novo', 
    loadComponent: () => import ('./components/planocontas/planoconta-form/planoconta-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'planoContasLista' }
  },{ 
    path: 'admin/planocontas/:id', 
    loadComponent: () => import ('./components/planocontas/planoconta-form/planoconta-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'planoContasLista' }
  },{ 
    path: 'admin/paginas', 
    loadComponent: () => import ('./components/paginas/pagina-list/pagina-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'paginasLista' }
  },{ 
    path: 'admin/paginas/novo', 
    loadComponent: () => import ('./components/paginas/pagina-form/pagina-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'paginasLista' }
  },{ 
    path: 'admin/paginas/:id', 
    loadComponent: () => import ('./components/paginas/pagina-form/pagina-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'paginasLista' }
  },{ 
    path: 'admin/lojas', 
    loadComponent: () => import ('./components/lojas/loja-list/loja-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'lojasLista' }
  },{ 
    path: 'admin/lojas/novo', 
    loadComponent: () => import ('./components/lojas/loja-form/loja-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'lojasLista' }
  },{ 
    path: 'admin/lojas/:id', 
    loadComponent: () => import ('./components/lojas/loja-form/loja-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'lojasLista' }
  },{ 
    path: 'admin/lojas/:idrelacao/regrasfrete', 
    loadComponent: () => import ('./components/lojas/regra-frete-form/regra-frete-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'lojasLista', title: 'Regras de Frete' }
  },{ 
    path: 'admin/lojas/:idrelacao/regrasfrete/:id', 
    loadComponent: () => import ('./components/lojas/regra-frete-form/regra-frete-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'lojasLista', title: 'Regras de Frete' }
  },{ 
    path: 'admin/usuarios', 
    loadComponent: () => import ('./components/usuarios/usuario-list/usuario-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'usuariosLista' }
  },{ 
    path: 'admin/usuarios/novo', 
    loadComponent: () => import ('./components/usuarios/usuario-form/usuario-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'usuariosLista' }
  },{ 
    path: 'admin/usuarios/:id', 
    loadComponent: () => import ('./components/usuarios/usuario-form/usuario-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'usuariosLista' }
  },{ 
    path: 'admin/categorias-publicacoes', 
    loadComponent: () => import ('./components/categoriaspublicacoes/categoriapublicacao-list/categoriapublicacao-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'categoriasPublicacoesLista' }
  },{ 
    path: 'admin/categorias-publicacoes/novo', 
    loadComponent: () => import ('./components/categoriaspublicacoes/categoriapublicacao-form/categoriapublicacao-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'categoriasPublicacoesLista' }
  },{ 
    path: 'admin/categorias-publicacoes/:id', 
    loadComponent: () => import ('./components/categoriaspublicacoes/categoriapublicacao-form/categoriapublicacao-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'categoriasPublicacoesLista' }
  },{ 
    path: 'admin/publicacoes', 
    loadComponent: () => import ('./components/publicacoes/publicacao-list/publicacao-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'publicacoesLista' }
  },{ 
    path: 'admin/publicacoes/converter', 
    loadComponent: () => import ('./components/publicacoes/conversor-publicacao/conversor-publicacao.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'publicacoesLista', title: 'Conversor de Publicações' }
  },{ 
    path: 'admin/publicacoes/novo', 
    loadComponent: () => import ('./components/publicacoes/publicacao-form/publicacao-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'publicacoesLista' }
  },{ 
    path: 'admin/publicacoes/:id', 
    loadComponent: () => import ('./components/publicacoes/publicacao-form/publicacao-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'publicacoesLista' }
  },{ 
    path: 'admin/publicacoes/:idrelacao/modulos', 
    loadComponent: () => import ('./components/publicacoes/publicacao-modulo-form/publicacao-modulo-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'publicacoesLista', title: 'Módulos' }
  },{ 
    path: 'admin/publicacoes/:idrelacao/modulos/:id', 
    loadComponent: () => import ('./components/publicacoes/publicacao-modulo-form/publicacao-modulo-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'publicacoesLista', title: 'Módulos' }
  },{ 
    path: 'admin/bairros', 
    loadComponent: () => import ('./components/bairros/bairro-list/bairro-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'bairrosLista' }
  },{ 
    path: 'admin/bairros/novo', 
    loadComponent: () => import ('./components/bairros/bairro-form/bairro-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'bairrosLista' }
  },{ 
    path: 'admin/bairros/:id', 
    loadComponent: () => import ('./components/bairros/bairro-form/bairro-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'bairrosLista' }
  },{ 
    path: 'admin/telefones', 
    loadComponent: () => import ('./components/telefones/telefone-list/telefone-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'telefonesLista' }
  },{ 
    path: 'admin/telefones/novo', 
    loadComponent: () => import ('./components/telefones/telefone-form/telefone-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'telefonesLista' }
  },{ 
    path: 'admin/telefones/:id', 
    loadComponent: () => import ('./components/telefones/telefone-form/telefone-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'telefonesLista' }
  },{ 
    path: 'admin/grupos-produtos1', 
    loadComponent: () => import ('./components/gruposprodutos1/grupoproduto1-list/grupoproduto1-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'gruposProdutos1Lista' }
  },{ 
    path: 'admin/grupos-produtos1/novo', 
    loadComponent: () => import ('./components/gruposprodutos1/grupoproduto1-form/grupoproduto1-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'gruposProdutos1Lista' }
  },{ 
    path: 'admin/grupos-produtos1/:id', 
    loadComponent: () => import ('./components/gruposprodutos1/grupoproduto1-form/grupoproduto1-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'gruposProdutos1Lista' }
  },{ 
    path: 'admin/grupos-telefones', 
    loadComponent: () => import ('./components/grupostelefones/grupotelefone-list/grupotelefone-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'gruposTelefonesLista' }
  },{ 
    path: 'admin/grupos-telefones/novo', 
    loadComponent: () => import ('./components/grupostelefones/grupotelefone-form/grupotelefone-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'gruposTelefonesLista' }
  },{ 
    path: 'admin/grupos-telefones/:id', 
    loadComponent: () => import ('./components/grupostelefones/grupotelefone-form/grupotelefone-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'gruposTelefonesLista' }
  },{ 
    path: 'admin/marcas', 
    loadComponent: () => import ('./components/marcas/marca-list/marca-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'marcasLista' }
  },{ 
    path: 'admin/marcas/novo', 
    loadComponent: () => import ('./components/marcas/marca-form/marca-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'marcasLista' }
  },{ 
    path: 'admin/marcas/:id', 
    loadComponent: () => import ('./components/marcas/marca-form/marca-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'marcasLista' }
  },{ 
    path: 'admin/etapaspedidos', 
    loadComponent: () => import ('./components/etapaspedidos/etapapedido-list/etapapedido-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'etapasPedidosLista' }
  },{ 
    path: 'admin/etapaspedidos/novo', 
    loadComponent: () => import ('./components/etapaspedidos/etapapedido-form/etapapedido-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'etapasPedidosLista' }
  },{ 
    path: 'admin/etapaspedidos/:id', 
    loadComponent: () => import ('./components/etapaspedidos/etapapedido-form/etapapedido-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'etapasPedidosLista' }
  },{ 
    path: 'admin/paises', 
    loadComponent: () => import ('./components/paises/pais-list/pais-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'paisesLista' }
  },{ 
    path: 'admin/paises/novo', 
    loadComponent: () => import ('./components/paises/pais-form/pais-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'paisesLista' }
  },{ 
    path: 'admin/paises/:id', 
    loadComponent: () => import ('./components/paises/pais-form/pais-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'paisesLista' }
  },{ 
    path: 'admin/fornecedores', 
    loadComponent: () => import ('./components/fornecedores/fornecedor-list/fornecedor-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'fornecedoresLista' }
  },{ 
    path: 'admin/fornecedores/novo', 
    loadComponent: () => import ('./components/fornecedores/fornecedor-form/fornecedor-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'fornecedoresLista' }
  },{ 
    path: 'admin/fornecedores/:id', 
    loadComponent: () => import ('./components/fornecedores/fornecedor-form/fornecedor-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'fornecedoresLista' }
  },{ 
    path: 'admin/fornecedores/:idrelacao/condicoes-financeiras', 
    loadComponent: () => import ('./components/fornecedores/condicaofinanceirafornecedor-form/condicaofinanceirafornecedor-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'fornecedoresLista', title: 'Condições Financeiras' }
  },{ 
    path: 'admin/fornecedores/:idrelacao/condicoes-financeiras/:id', 
    loadComponent: () => import ('./components/fornecedores/condicaofinanceirafornecedor-form/condicaofinanceirafornecedor-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'fornecedoresLista', title: 'Condições Financeiras' }
  },{ 
    path: 'admin/regioes', 
    loadComponent: () => import ('./components/regioes/regiao-list/regiao-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'regioesLista' }
  },{ 
    path: 'admin/regioes/novo', 
    loadComponent: () => import ('./components/regioes/regiao-form/regiao-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'regioesLista' }
  },{ 
    path: 'admin/regioes/:id', 
    loadComponent: () => import ('./components/regioes/regiao-form/regiao-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'regioesLista' }
  },{ 
    path: 'admin/gruposcolaboradores', 
    loadComponent: () => import ('./components/gruposcolaboradores/grupocolaborador-list/grupocolaborador-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'gruposColaboradoresLista' }
  },{ 
    path: 'admin/gruposcolaboradores/novo', 
    loadComponent: () => import ('./components/gruposcolaboradores/grupocolaborador-form/grupocolaborador-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'gruposColaboradoresLista' }
  },{ 
    path: 'admin/gruposcolaboradores/:id', 
    loadComponent: () => import ('./components/gruposcolaboradores/grupocolaborador-form/grupocolaborador-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'gruposColaboradoresLista' }
  },{ 
    path: 'admin/categoriasprodutos', 
    loadComponent: () => import ('./components/categoriasprodutos/categoriaproduto-list/categoriaproduto-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'categoriasProdutosLista' }
  },{ 
    path: 'admin/categoriasprodutos/novo', 
    loadComponent: () => import ('./components/categoriasprodutos/categoriaproduto-form/categoriaproduto-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'categoriasProdutosLista' }
  },{ 
    path: 'admin/categoriasprodutos/:id', 
    loadComponent: () => import ('./components/categoriasprodutos/categoriaproduto-form/categoriaproduto-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'categoriasProdutosLista' }
  },{ 
    path: 'admin/cidades', 
    loadComponent: () => import ('./components/cidades/cidade-list/cidade-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'cidadesLista' }
  },{ 
    path: 'admin/cidades/novo', 
    loadComponent: () => import ('./components/cidades/cidade-form/cidade-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'cidadesLista' }
  },{ 
    path: 'admin/cidades/:id', 
    loadComponent: () => import ('./components/cidades/cidade-form/cidade-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'cidadesLista' }
  },{ 
    path: 'admin/estados', 
    loadComponent: () => import ('./components/estados/estado-list/estado-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'estadosLista' }
  },{ 
    path: 'admin/estados/novo', 
    loadComponent: () => import ('./components/estados/estado-form/estado-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'estadosLista' }
  },{ 
    path: 'admin/estados/:id', 
    loadComponent: () => import ('./components/estados/estado-form/estado-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'estadosLista' }
  },{ 
    path: 'admin/cargos', 
    loadComponent: () => import ('./components/cargos/cargo-list/cargo-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'cargosLista' }
  },{ 
    path: 'admin/cargos/novo', 
    loadComponent: () => import ('./components/cargos/cargo-form/cargo-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'cargosLista' }
  },{ 
    path: 'admin/cargos/:id', 
    loadComponent: () => import ('./components/cargos/cargo-form/cargo-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'cargosLista' }
  },{ 
    path: 'admin/tags', 
    loadComponent: () => import ('./components/tags/tag-list/tag-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'tagsLista' }
  },{ 
    path: 'admin/tags/novo', 
    loadComponent: () => import ('./components/tags/tag-form/tag-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'tagsLista' }
  },{ 
    path: 'admin/tags/:id', 
    loadComponent: () => import ('./components/tags/tag-form/tag-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'tagsLista' }
  },{ 
    path: 'admin/ordem-servicos', 
    loadComponent: () => import ('./components/ordemservicos/ordemservico-list/ordemservico-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'ordemServicosLista', title: 'Ordens de Serviço' }
  },{ 
    path: 'admin/ordem-servicos/novo', 
    loadComponent: () => import ('./components/ordemservicos/ordemservico-form/ordemservico-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'ordemServicosLista', title: 'Ordens de Serviço' }
  },{ 
    path: 'admin/ordem-servicos/:id', 
    loadComponent: () => import ('./components/ordemservicos/ordemservico-form/ordemservico-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'ordemServicosLista', title: 'Ordens de Serviço' }
  },{ 
    path: 'admin/ordem-servicos/:idrelacao/produto', 
    loadComponent: () => import ('./components/ordemservicos/produto-ordemservico-form/produtoordemservico-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'ordens-sevicosLista', title: 'Produtos da Ordem de Serviço' }
  },{ 
    path: 'admin/ordem-servicos/:idrelacao/produto/:id', 
    loadComponent: () => import ('./components/ordemservicos/produto-ordemservico-form/produtoordemservico-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'ordens-sevicosLista', title: 'Produtos da Ordem de Serviço' }
  },{ 
    path: 'admin/comissoes-ordem-servicos', 
    loadComponent: () => import ('./components/comissoesordemservicos/comissaoordemservico-list/comissaoordemservico-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'comissoesordemservicosLista' }
  },{ 
    path: 'admin/comissoes-geradas-ordem-servicos', 
    loadComponent: () => import ('./components/comissoesgeradasordemservicos/comissaogeradaordemservico-list/comissaogeradaordemservico-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'comissoesgeradasordemservicosLista' }
  },{ 
    path: 'admin/comissoes-geradas-ordem-servicos/:id', 
    loadComponent: () => import ('./components/comissoesgeradasordemservicos/comissaogeradaordemservico-form/comissaogeradaordemservico-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'comissoesgeradasordemservicosLista' }
  },{ 
    path: 'admin/bancos', 
    loadComponent: () => import ('./components/bancos/banco-list/banco-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'bancosLista' }
  },{ 
    path: 'admin/bancos/novo', 
    loadComponent: () => import ('./components/bancos/banco-form/banco-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'bancosLista' }
  },{ 
    path: 'admin/bancos/:id', 
    loadComponent: () => import ('./components/bancos/banco-form/banco-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'bancosLista' }
  },{ 
    path: 'admin/indicacoes', 
    loadComponent: () => import ('./components/indicacoes/indicacao-list/indicacao-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'indicacoesLista' }
  },{ 
    path: 'admin/indicacoes/novo', 
    loadComponent: () => import ('./components/indicacoes/indicacao-form/indicacao-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'indicacoesLista' }
  },{ 
    path: 'admin/indicacoes/:id', 
    loadComponent: () => import ('./components/indicacoes/indicacao-form/indicacao-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'indicacoesLista' }
  },{ 
    path: 'admin/profissoes', 
    loadComponent: () => import ('./components/profissoes/profissao-list/profissao-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'profissoesLista' }
  },{ 
    path: 'admin/profissoes/novo', 
    loadComponent: () => import ('./components/profissoes/profissao-form/profissao-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'profissoesLista' }
  },{ 
    path: 'admin/profissoes/:id', 
    loadComponent: () => import ('./components/profissoes/profissao-form/profissao-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'profissoesLista' }
  },{ 
    path: 'admin/gruposcontatos', 
    loadComponent: () => import ('./components/gruposcontatos/grupo-contato-list/grupo-contato-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'gruposContatosLista' }
  },{ 
    path: 'admin/gruposcontatos/novo', 
    loadComponent: () => import ('./components/gruposcontatos/grupo-contato-form/grupo-contato-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'gruposContatosLista' }
  },{ 
    path: 'admin/gruposcontatos/:id', 
    loadComponent: () => import ('./components/gruposcontatos/grupo-contato-form/grupo-contato-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'gruposContatosLista' }
  },{ 
    path: 'admin/ncm', 
    loadComponent: () => import ('./components/ncms/ncm-list/ncm-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'ncmLista' }
  },{ 
    path: 'admin/ncm/novo', 
    loadComponent: () => import ('./components/ncms/ncm-form/ncm-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'ncmLista' }
  },{ 
    path: 'admin/ncm/:id', 
    loadComponent: () => import ('./components/ncms/ncm-form/ncm-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'ncmLista' }
  },{ 
    path: 'admin/gruposespecificacoes', 
    loadComponent: () => import ('./components/gruposespecificacoes/grupo-especificacao-list/grupo-especificacao-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'grupoEspecificacoesLista' }
  },{ 
    path: 'admin/gruposespecificacoes/novo', 
    loadComponent: () => import ('./components/gruposespecificacoes/grupo-especificacao-form/grupo-especificacao-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'grupoEspecificacoesLista' }
  },{ 
    path: 'admin/gruposespecificacoes/:id', 
    loadComponent: () => import ('./components/gruposespecificacoes/grupo-especificacao-form/grupo-especificacao-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'grupoEspecificacoesLista' }
  },{ 
    path: 'admin/pes', 
    loadComponent: () => import ('./components/pes/pes-list/pes-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'pesLista' }
  },{ 
    path: 'admin/pes/novo', 
    loadComponent: () => import ('./components/pes/pes-form/pes-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'pesLista' }
  },{ 
    path: 'admin/pes/:id', 
    loadComponent: () => import ('./components/pes/pes-form/pes-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'pesLista' }
  },{ 
    path: 'admin/cfop', 
    loadComponent: () => import ('./components/cfops/cfop-list/cfop-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'cfopLista' }
  },{ 
    path: 'admin/cfop/novo', 
    loadComponent: () => import ('./components/cfops/cfop-form/cfop-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'cfopLista' }
  },{ 
    path: 'admin/cfop/:id', 
    loadComponent: () => import ('./components/cfops/cfop-form/cfop-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'cfopLista' }
  },{ 
    path: 'admin/depositos', 
    loadComponent: () => import ('./components/depositos/deposito-list/deposito-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'depositosLista' }
  },{ 
    path: 'admin/depositos/novo', 
    loadComponent: () => import ('./components/depositos/deposito-form/deposito-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'depositosLista' }
  },{ 
    path: 'admin/depositos/:id', 
    loadComponent: () => import ('./components/depositos/deposito-form/deposito-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'depositosLista' }
  },{ 
    path: 'admin/cadastros', 
    loadComponent: () => import ('./components/cadastros/cadastro-list/cadastro-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'cadastrosLista' }
  },{ 
    path: 'admin/cadastros/novo', 
    loadComponent: () => import ('./components/cadastros/cadastro-form/cadastro-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'cadastrosLista' }
  },{ 
    path: 'admin/cadastros/:id', 
    loadComponent: () => import ('./components/cadastros/cadastro-form/cadastro-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'cadastrosLista' }
  },{ 
    path: 'admin/cadastros/:idrelacao/enderecos', 
    loadComponent: () => import ('./components/cadastros/endereco-form/endereco-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'cadastrosLista', title: 'Endereços' }
  },{ 
    path: 'admin/cadastros/:idrelacao/enderecos/:id', 
    loadComponent: () => import ('./components/cadastros/endereco-form/endereco-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'cadastrosLista', title: 'Endereços' }
  },{ 
    path: 'admin/cadastros/:idrelacao/dadosbancarios', 
    loadComponent: () => import ('./components/cadastros/dado-bancario-cadastro-form/dado-bancario-cadastro-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'cadastrosLista', title: 'Dados Bancários' }
  },{ 
    path: 'admin/cadastros/:idrelacao/dadosbancarios/:id', 
    loadComponent: () => import ('./components/cadastros/dado-bancario-cadastro-form/dado-bancario-cadastro-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'cadastrosLista', title: 'Dados Bancários' }
  },{ 
    path: 'admin/cadastros-simplificado', 
    loadComponent: () => import ('./components/cadastrossimplificado/cadastrosimplificado-list/cadastrosimplificado-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'cadastrosSimplificadoLista' }
  },{ 
    path: 'admin/cadastros-simplificado/novo', 
    loadComponent: () => import ('./components/cadastrossimplificado/cadastrosimplificado-form/cadastrosimplificado-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'cadastrosSimplificadoLista' }
  },{ 
    path: 'admin/cadastros-simplificado/:id', 
    loadComponent: () => import ('./components/cadastrossimplificado/cadastrosimplificado-form/cadastrosimplificado-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'cadastrosSimplificadoLista' }
  },{ 
    path: 'admin/cadastros-simplificado/:idrelacao/enderecos', 
    loadComponent: () => import ('./components/cadastros/endereco-form/endereco-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'cadastrosLista', title: 'Endereços' }
  },{ 
    path: 'admin/cadastros-simplificado/:idrelacao/enderecos/:id', 
    loadComponent: () => import ('./components/cadastros/endereco-form/endereco-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'cadastrosLista', title: 'Endereços' }
  },{ 
    path: 'admin/cadastros-simplificado/:idrelacao/dadosbancarios', 
    loadComponent: () => import ('./components/cadastros/dado-bancario-cadastro-form/dado-bancario-cadastro-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'cadastrosLista', title: 'Dados Bancários' }
  },{ 
    path: 'admin/cadastros-simplificado/:idrelacao/dadosbancarios/:id', 
    loadComponent: () => import ('./components/cadastros/dado-bancario-cadastro-form/dado-bancario-cadastro-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'cadastrosLista', title: 'Dados Bancários' }
  },{ 
    path: 'admin/tabelaespecificacoesprodutos', 
    loadComponent: () => import ('./components/tabelaespecificacoesprodutos/tabelaespecificacaoproduto-list/tabelaespecificacaoproduto-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'tabelaEspecificacoesProdutosLista' }
  },{ 
    path: 'admin/tabelaespecificacoesprodutos/novo', 
    loadComponent: () => import ('./components/tabelaespecificacoesprodutos/tabelaespecificacaoproduto-form/tabelaespecificacaoproduto-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'tabelaEspecificacoesProdutosLista' }
  },{ 
    path: 'admin/tabelaespecificacoesprodutos/:id', 
    loadComponent: () => import ('./components/tabelaespecificacoesprodutos/tabelaespecificacaoproduto-form/tabelaespecificacaoproduto-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'tabelaEspecificacoesProdutosLista' }
  },{ 
    path: 'admin/gradeprodutos', 
    loadComponent: () => import ('./components/gradeprodutos/gradeproduto-list/gradeproduto-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'gradeProdutosLista' }
  },{ 
    path: 'admin/gradeprodutos/novo', 
    loadComponent: () => import ('./components/gradeprodutos/gradeproduto-form/gradeproduto-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'gradeProdutosLista' }
  },{ 
    path: 'admin/gradeprodutos/:id', 
    loadComponent: () => import ('./components/gradeprodutos/gradeproduto-form/gradeproduto-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'gradeProdutosLista' }
  },{ 
    path: 'admin/atletas', 
    loadComponent: () => import ('./components/atletas/atleta-list/atleta-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'atletasLista' }
  },{ 
    path: 'admin/atletas/novo', 
    loadComponent: () => import ('./components/atletas/atleta-form/atleta-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'atletasLista' }
  },{ 
    path: 'admin/atletas/:id', 
    loadComponent: () => import ('./components/atletas/atleta-form/atleta-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'atletasLista' }
  },{ 
    path: 'admin/atletas/:idrelacao/enderecos', 
    loadComponent: () => import ('./components/atletas/endereco-atleta-form/endereco-atleta-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'atletasLista', title: 'Endereços' }
  },{ 
    path: 'admin/atletas/:idrelacao/enderecos/:id', 
    loadComponent: () => import ('./components/atletas/endereco-atleta-form/endereco-atleta-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'atletasLista', title: 'Endereços' }
  },{ 
    path: 'admin/pacientes', 
    loadComponent: () => import ('./components/pacientes/paciente-list/paciente-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'pacientesLista' }
  },{ 
    path: 'admin/pacientes/novo', 
    loadComponent: () => import ('./components/pacientes/paciente-form/paciente-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'pacientesLista' }
  },{ 
    path: 'admin/pacientes/:id', 
    loadComponent: () => import ('./components/pacientes/paciente-form/paciente-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'pacientesLista' }
  },{ 
    path: 'admin/pacientes/:idrelacao/enderecos', 
    loadComponent: () => import ('./components/pacientes/endereco-paciente-form/endereco-paciente-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'pacientesLista', title: 'Endereços' }
  },{ 
    path: 'admin/pacientes/:idrelacao/enderecos/:id', 
    loadComponent: () => import ('./components/pacientes/endereco-paciente-form/endereco-paciente-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'pacientesLista', title: 'Endereços' }
  },{ 
    path: 'admin/deficiencia-pacientes', 
    loadComponent: () => import ('./components/deficienciapaciente/deficienciapaciente-list/deficienciapaciente-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'deficienciaPacientesLista' }
  },{ 
    path: 'admin/deficiencia-pacientes/novo', 
    loadComponent: () => import ('./components/deficienciapaciente/deficienciapaciente-form/deficienciapaciente-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'deficienciaPacientesLista' }
  },{ 
    path: 'admin/deficiencia-pacientes/:id', 
    loadComponent: () => import ('./components/deficienciapaciente/deficienciapaciente-form/deficienciapaciente-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'deficienciaPacientesLista' }
  },{ 
    path: 'admin/fluxos-empresariais', 
    loadComponent: () => import ('./components/fluxosempresariais/fluxoempresarial-list/fluxoempresarial-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'fluxosEmpresariaisLista' }
  },{ 
    path: 'admin/fluxos-empresariais/novo', 
    loadComponent: () => import ('./components/fluxosempresariais/fluxoempresarial-form/fluxoempresarial-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'fluxosEmpresariaisLista' }
  },{ 
    path: 'admin/fluxos-empresariais/:id', 
    loadComponent: () => import ('./components/fluxosempresariais/fluxoempresarial-form/fluxoempresarial-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'fluxosEmpresariaisLista' }
  },{ 
    path: 'admin/fluxos-empresariais/:idrelacao/estagios', 
    loadComponent: () => import ('./components/fluxosempresariais/estagiofluxoempresarial-form/estagiofluxoempresarial-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'produtosLista', title: 'Estágios do Fluxo Empresarial' }
  },{ 
    path: 'admin/fluxos-empresariais/:idrelacao/estagios/:id', 
    loadComponent: () => import ('./components/fluxosempresariais/estagiofluxoempresarial-form/estagiofluxoempresarial-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'produtosLista', title: 'Estágios do Fluxo Empresarial' }
  },{ 
    path: 'admin/origens-leads', 
    loadComponent: () => import ('./components/origensleads/origemlead-list/origemlead-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'origensLeadLista' }
  },{ 
    path: 'admin/origens-leads/novo', 
    loadComponent: () => import ('./components/origensleads/origemlead-form/origemlead-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'origensLeadLista' }
  },{ 
    path: 'admin/origens-leads/:id', 
    loadComponent: () => import ('./components/origensleads/origemlead-form/origemlead-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'origensLeadLista' }
  },{ 
    path: 'admin/classificacoes-leads', 
    loadComponent: () => import ('./components/classificacoesleads/classificacaolead-list/classificacaolead-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'classificacoesLeadLista' }
  },{ 
    path: 'admin/classificacoes-leads/novo', 
    loadComponent: () => import ('./components/classificacoesleads/classificacaolead-form/classificacaolead-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'classificacoesLeadLista' }
  },{ 
    path: 'admin/classificacoes-leads/:id', 
    loadComponent: () => import ('./components/classificacoesleads/classificacaolead-form/classificacaolead-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'classificacoesLeadLista' }
  },{ 
    path: 'admin/status-leads', 
    loadComponent: () => import ('./components/statusleads/statuslead-list/statuslead-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'statusLeadLista' }
  },{ 
    path: 'admin/status-leads/novo', 
    loadComponent: () => import ('./components/statusleads/statuslead-form/statuslead-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'statusLeadLista' }
  },{ 
    path: 'admin/status-leads/:id', 
    loadComponent: () => import ('./components/statusleads/statuslead-form/statuslead-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'statusLeadLista' }
  },{ 
    path: 'admin/leads', 
    loadComponent: () => import ('./components/leads/lead-list/lead-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'leadsLista' }
  },{ 
    path: 'admin/leads/novo', 
    loadComponent: () => import ('./components/leads/lead-form/lead-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'leadsLista' }
  },{ 
    path: 'admin/leads/:id', 
    loadComponent: () => import ('./components/leads/lead-form/lead-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'leadsLista' }
  },{ 
    path: 'admin/produtos-marketplaces', 
    loadComponent: () => import ('./components/produtosmarketplaces/produtomarketplace-list/produtomarketplace-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'produtosMarketPlacesLista' }
  },{ 
    path: 'admin/produtos-marketplaces/replicar', 
    loadComponent: () => import ('./components/produtos/replicador-produtos-marketplaces/replicador-produtos-marketplaces.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'produtosLista', title: 'Replicador de Produtos para Marketplaces' }
  },{ 
    path: 'admin/produtos-marketplaces/novo', 
    loadComponent: () => import ('./components/produtosmarketplaces/produtomarketplace-form/produtomarketplace-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'produtosMarketPlacesLista' }
  },{ 
    path: 'admin/produtos-marketplaces/:id', 
    loadComponent: () => import ('./components/produtosmarketplaces/produtomarketplace-form/produtomarketplace-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'produtosMarketPlacesLista' }
  },{ 
    path: 'admin/aditivo-simuladorrentabilidade', 
    loadComponent: () => import ('./components/aditivosimuladorrentabilidade/aditivosimuladorrentabilidade-list/aditivosimuladorrentabilidade-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'aditivoSimuladorRentabilidadeLista' }
  },{ 
    path: 'admin/aditivo-simuladorrentabilidade/novo', 
    loadComponent: () => import ('./components/aditivosimuladorrentabilidade/aditivosimuladorrentabilidade-form/aditivosimuladorrentabilidade-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'finalidadeAtendimentoClinicoLista' }
  },{ 
    path: 'admin/aditivo-simuladorrentabilidade/:id', 
    loadComponent: () => import ('./components/aditivosimuladorrentabilidade/aditivosimuladorrentabilidade-form/aditivosimuladorrentabilidade-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'finalidadeAtendimentoClinicoLista' }
  },{ 
    path: 'admin/produtos', 
    loadComponent: () => import ('./components/produtos/produto-list/produto-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'produtosLista' }
  },{ 
    path: 'admin/produtos/sku-manager', 
    loadComponent: () => import ('./components/produtos/sku-manager/sku-manager.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'produtosLista', title: 'SKU Manager' }
  },{ 
    path: 'admin/produtos/novo', 
    loadComponent: () => import ('./components/produtos/produto-form/produto-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'produtosLista', title: 'Especificações de Produto' }
  },{ 
    path: 'admin/produtos/:id', 
    loadComponent: () => import ('./components/produtos/produto-form/produto-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'produtosLista', title: 'Especificações de Produto' }
  },{ 
    path: 'admin/produtos/:idrelacao/especificacoesproduto', 
    loadComponent: () => import ('./components/produtos/especificacao-produto-form/especificacao-produto-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'produtosLista' }
  },{ 
    path: 'admin/produtos/:idrelacao/especificacoesproduto/:id', 
    loadComponent: () => import ('./components/produtos/especificacao-produto-form/especificacao-produto-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'produtosLista' }
  },{ 
    path: 'admin/produtos/:idrelacao/produtoscolaboradores', 
    loadComponent: () => import ('./components/produtos/produto-colaborador-form/produto-colaborador-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'produtosLista', title: 'Relação Produto-Colaborador' }
  },{ 
    path: 'admin/produtos/:idrelacao/produtoscolaboradores/:id', 
    loadComponent: () => import ('./components/produtos/produto-colaborador-form/produto-colaborador-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'produtosLista', title: 'Relação Produto-Colaborador' }
  },{ 
    path: 'admin/produtos/:idrelacao/produtosagregados', 
    loadComponent: () => import ('./components/produtos/produto-agregado-form/produto-agregado-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'produtosLista', title: 'Produtos Agregados' }
  },{ 
    path: 'admin/produtos/:idrelacao/produtosagregados/:id', 
    loadComponent: () => import ('./components/produtos/produto-agregado-form/produto-agregado-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'produtosLista', title: 'Produtos Agregados' }
  },{ 
    path: 'admin/produtos/:idrelacao/produtoskits', 
    loadComponent: () => import ('./components/produtos/produto-kit-form/produto-kit-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'produtosLista', title: 'Produtos do Kit' }
  },{ 
    path: 'admin/produtos/:idrelacao/produtoskits/:id', 
    loadComponent: () => import ('./components/produtos/produto-kit-form/produto-kit-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'produtosLista', title: 'Produtos do Kit' }
  },{ 
    path: 'admin/produtos/:idrelacao/caracteristicasprodutos', 
    loadComponent: () => import ('./components/produtos/caracteristica-produto-form/caracteristica-produto-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'produtosLista', title: 'Características do Produto' }
  },{ 
    path: 'admin/produtos/:idrelacao/caracteristicasprodutos/:id', 
    loadComponent: () => import ('./components/produtos/caracteristica-produto-form/caracteristica-produto-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'produtosLista', title: 'Características do Produto' }
  },{ 
    path: 'admin/disponibilidade-produtos', 
    loadComponent: () => import ('./components/disponibilidadeprodutos/disponibilidadeprodutos.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'disponibilidadeProdutos', title: 'Disponibilidade de Produtos' }
  },{ 
    path: 'admin/acompanhamento-pedidos', 
    loadComponent: () => import ('./components/acompanhamentopedidos/acompanhamentopedidos.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'acompanhamentoPedidos', title: 'Acompanhamento de Pedidos' }
  },{ 
    path: 'admin/monitorgatewaysmarketplaces', 
    loadComponent: () => import ('./components/monitorgatewaysmarketplace/monitorgatewaysmarketplace-list/monitorgatewaysmarketplace-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'monitorGatewaysMarketplaces' }
  },{ 
    path: 'admin/atualizadorprecoproduto', 
    loadComponent: () => import ('./components/atualizadorprecoproduto/atualizadorprecoproduto-list/atualizadorprecoproduto-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'atualizadorPrecoProdutoLista' }
  },{ 
    path: 'admin/pedidovendas', 
    loadComponent: () => import ('./components/pedidovendas/pedidovenda-list/pedidovenda-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'pedidoVendasLista' }
  },{ 
    path: 'admin/pedidovendas/novo', 
    loadComponent: () => import ('./components/pedidovendas/pedidovenda-form/pedidovenda-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'pedidoVendasLista' }
  },{ 
    path: 'admin/pedidovendas/faturamentos', 
    loadComponent: () => import ('./components/pedidovendas/gerador-faturamentos/gerador-faturamentos.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'pedidoVendasLista', title: 'Gerador de Faturamentos' }
  },{ 
    path: 'admin/pedidovendas/:id', 
    loadComponent: () => import ('./components/pedidovendas/pedidovenda-form/pedidovenda-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'pedidoVendasLista' }
  },{ 
    path: 'admin/pedidovendas/:idrelacao/itens', 
    loadComponent: () => import ('./components/pedidovendas/pedidovenda-item-form/pedidovenda-item-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'pedidoVendasLista', title: 'Itens da Venda' }
  },{ 
    path: 'admin/pedidovendas/:idrelacao/itens/:id', 
    loadComponent: () => import ('./components/pedidovendas/pedidovenda-item-form/pedidovenda-item-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'pedidoVendasLista', title: 'Itens da Venda' }
  },{ 
    path: 'admin/pedidovendas/:idrelacao/etapas', 
    loadComponent: () => import ('./components/pedidovendas/pedidovenda-etapa-form/pedidovenda-etapa-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'pedidoVendasLista', title: 'Etapas da Venda' }
  },{ 
    path: 'admin/pedidovendas/:idrelacao/etapas/:id', 
    loadComponent: () => import ('./components/pedidovendas/pedidovenda-etapa-form/pedidovenda-etapa-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'pedidoVendasLista', title: 'Etapas da Venda' }
  },{ 
    path: 'admin/pedidovendas/:idrelacao/parcelas', 
    loadComponent: () => import ('./components/pedidovendas/pedidovenda-parcela-form/pedidovenda-parcela-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'pedidoVendasLista', title: 'Parcelas da Venda' }
  },{ 
    path: 'admin/pedidovendas/:idrelacao/parcelas/:id', 
    loadComponent: () => import ('./components/pedidovendas/pedidovenda-parcela-form/pedidovenda-parcela-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'pedidoVendasLista', title: 'Parcelas da Venda' }
  },{ 
    path: 'admin/diretivasconversoes', 
    loadComponent: () => import ('./components/pedidovendas/pedidovenda-parcela-form/pedidovenda-parcela-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'pedidoVendasLista', title: 'Parcelas da Venda' }
  },{ 
    path: 'admin/diretivasconversoes/novo', 
    loadComponent: () => import ('./components/diretivasconversao/diretiva-conversao-form/diretiva-conversao-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'diretivasConversaoLista' }
  },{ 
    path: 'admin/diretivasconversoes/:id', 
    loadComponent: () => import ('./components/diretivasconversao/diretiva-conversao-form/diretiva-conversao-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'diretivasConversaoLista' }
  },{ 
    path: 'admin/pushsubscriptions', 
    loadComponent: () => import ('./components/pushsubscriptions/push-subscription-list/push-subscription-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'pushSubscriptionsLista' }
  },{ 
    path: 'admin/pushsubscriptions/novo', 
    loadComponent: () => import ('./components/pushsubscriptions/push-subscription-form/push-subscription-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'pushSubscriptionsLista' }
  },{ 
    path: 'admin/pushsubscriptions/:id', 
    loadComponent: () => import ('./components/pushsubscriptions/push-subscription-form/push-subscription-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'pushSubscriptionsLista' }
  },{ 
    path: 'admin/notificacoes', 
    loadComponent: () => import ('./components/notificacoes/notificacao-list/notificacao-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'notificacoesLista' }
  },{ 
    path: 'admin/notificacoes/novo', 
    loadComponent: () => import ('./components/notificacoes/notificacao-form/notificacao-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'notificacoesLista' }
  },{ 
    path: 'admin/notificacoes/:id', 
    loadComponent: () => import ('./components/notificacoes/notificacao-form/notificacao-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'notificacoesLista' }
  },{ 
    path: 'admin/newsletters', 
    loadComponent: () => import ('./components/newsletters/newsletter-list/newsletter-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'newslettersLista' }
  },{ 
    path: 'admin/newsletters/novo', 
    loadComponent: () => import ('./components/newsletters/newsletter-form/newsletter-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'newslettersLista' }
  },{ 
    path: 'admin/newsletters/:id', 
    loadComponent: () => import ('./components/newsletters/newsletter-form/newsletter-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'newslettersLista' }
  },{ 
    path: 'admin/parametros', 
    loadComponent: () => import ('./components/parametros/parametro-list/parametro-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'parametrosLista' }
  },{ 
    path: 'admin/parametros/novo', 
    loadComponent: () => import ('./components/parametros/parametro-form/parametro-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'parametrosLista' }
  },{ 
    path: 'admin/parametros/:id', 
    loadComponent: () => import ('./components/parametros/parametro-form/parametro-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'parametrosLista' }
  },{ 
    path: 'admin/relatorios/atendimento-clinico', 
    loadComponent: () => import ('./components/relatorios/relatorio-atendimento-clinico/relatorio-atendimento-clinico.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'relatorioPedidoVendas', title: 'Relatório de Atendimentos clínico' }
  },{ 
    path: 'admin/relatorios/produtos', 
    loadComponent: () => import ('./components/relatorios/relatorio-produtos/relatorio-produtos.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'relatorioProdutos', title: 'Relatório de Produtos' }
  },{ 
    path: 'admin/relatorios/arquivosged', 
    loadComponent: () => import ('./components/relatorios/relatorio-arquivosged/relatorio-arquivosged.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'relatorioArquivosGed', title: 'Relatório de Arquivos do GED' }
  },{ 
    path: 'admin/relatorios/cadastros-enderecos', 
    loadComponent: () => import ('./components/relatorios/relatorio-cadastros-enderecos/relatorio-cadastros-enderecos.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'relatorioCadastrosEnderecos', title: 'Relatório de Cadastros e Endereços' }
  },{ 
    path: 'admin/relatorios/inventario-produtos', 
    loadComponent: () => import ('./components/relatorios/relatorio-inventario-produtos/relatorio-inventario-produtos.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'relatorioInventarioProdutos', title: 'Relatório Inventário de Produtos' }
  },{ 
    path: 'admin/catalogo-produtos', 
    loadComponent: () => import ('./components/catalogoprodutos/catalogoprodutos.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'catalogoProdutos', title: 'Catálogo de Produtos' }
  },{ 
    path: 'admin/classificacoesprojetos', 
    loadComponent: () => import ('./components/classificacoesprojetos/classificacaoprojeto-list/classificacaoprojeto-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'classificacoesprojetosLista' }
  },{ 
    path: 'admin/classificacoesprojetos/novo', 
    loadComponent: () => import ('./components/classificacoesprojetos/classificacaoprojeto-form/classificacaoprojeto-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'classificacoesprojetosLista' }
  },{ 
    path: 'admin/classificacoesprojetos/:id', 
    loadComponent: () => import ('./components/classificacoesprojetos/classificacaoprojeto-form/classificacaoprojeto-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'classificacoesprojetosLista' }
  },{ 
    path: 'admin/eventos', 
    loadComponent: () => import ('./components/eventos/evento-list/evento-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'eventosLista' }
  },{ 
    path: 'admin/eventos/novo', 
    loadComponent: () => import ('./components/eventos/evento-form/evento-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'eventosLista' }
  },{ 
    path: 'admin/eventos/:id', 
    loadComponent: () => import ('./components/eventos/evento-form/evento-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'eventosLista' }
  },{ 
    path: 'admin/portadores', 
    loadComponent: () => import ('./components/portadores/portador-list/portador-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'portadoresLista' }
  },{ 
    path: 'admin/portadores/novo', 
    loadComponent: () => import ('./components/portadores/portador-form/portador-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'portadoresLista' }
  },{ 
    path: 'admin/portadores/:id', 
    loadComponent: () => import ('./components/portadores/portador-form/portador-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'portadoresLista' }
  },{ 
    path: 'admin/tabelas-financeiras', 
    loadComponent: () => import ('./components/tabelasfinanceiras/tabelafinanceira-list/tabelafinanceira-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'tabelasFinanceirasLista' }
  },{ 
    path: 'admin/tabelas-financeiras/novo', 
    loadComponent: () => import ('./components/tabelasfinanceiras/tabelafinanceira-form/tabelafinanceira-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'tabelasFinanceirasLista' }
  },{ 
    path: 'admin/tabelas-financeiras/:id', 
    loadComponent: () => import ('./components/tabelasfinanceiras/tabelafinanceira-form/tabelafinanceira-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'tabelasFinanceirasLista' }
  },{ 
    path: 'admin/condicoes-financeiras', 
    loadComponent: () => import ('./components/condicoesfinanceiras/condicaofinanceira-list/condicaofinanceira-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'condicoesFinanceirasLista' }
  },{ 
    path: 'admin/condicoes-financeiras/novo', 
    loadComponent: () => import ('./components/condicoesfinanceiras/condicaofinanceira-form/condicaofinanceira-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'condicoesFinanceirasLista' }
  },{ 
    path: 'admin/condicoes-financeiras/:id', 
    loadComponent: () => import ('./components/condicoesfinanceiras/condicaofinanceira-form/condicaofinanceira-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'condicoesFinanceirasLista' }
  },{ 
    path: 'admin/contas-receber', 
    loadComponent: () => import ('./components/contasreceber/contareceber-list/contareceber-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'contasReceberLista' }
  },{ 
    path: 'admin/contas-receber/novo', 
    loadComponent: () => import ('./components/contasreceber/contareceber-form/contareceber-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'contasReceberLista' }
  },{ 
    path: 'admin/contas-receber/:id', 
    loadComponent: () => import ('./components/contasreceber/contareceber-form/contareceber-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'contasReceberLista' }
  },{ 
    path: 'admin/contas-receber/:idrelacao/lancamentos', 
    loadComponent: () => import ('./components/contasreceber/contareceber-lancamento-form/contareceber-lancamento-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'contasReceberLista', title: 'Lançamentos Contas à Receber' }
  },{ 
    path: 'admin/relatorios/contas-receber', 
    loadComponent: () => import ('./components/relatorios/relatorio-contas-receber/relatorio-contas-receber.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'relatorioContasReceber', title: 'Relatório de Contas à Receber' }
  },{ 
    path: 'admin/relatorios/contas-pagar', 
    loadComponent: () => import ('./components/relatorios/relatorio-contas-pagar/relatorio-contas-pagar.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'relatorioContasPagar', title: 'Relatório de Contas à Pagar' }
  },{ 
    path: 'admin/contas-pagar', 
    loadComponent: () => import ('./components/contaspagar/contapagar-list/contapagar-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'contasPagarLista' }
  },{ 
    path: 'admin/contas-pagar/novo', 
    loadComponent: () => import ('./components/contaspagar/contapagar-form/contapagar-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'contasPagarLista' }
  },{ 
    path: 'admin/contas-pagar/:id', 
    loadComponent: () => import ('./components/contaspagar/contapagar-form/contapagar-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'contasPagarLista' }
  },{ 
    path: 'admin/contas-pagar/:idrelacao/lancamentos', 
    loadComponent: () => import ('./components/contaspagar/contapagar-lancamento-form/contapagar-lancamento-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'relatorioContasPagar', title: 'Lançamentos Contas à Pagar' }
  },{ 
    path: 'admin/tabelas-genericas', 
    loadComponent: () => import ('./components/tabelasgenericas/tabelagenerica-list/tabelagenerica-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'tabelasGenericasLista' }
  },{ 
    path: 'admin/tabelas-genericas/novo', 
    loadComponent: () => import ('./components/tabelasgenericas/tabelagenerica-form/tabelagenerica-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'tabelasGenericasLista' }
  },{ 
    path: 'admin/tabelas-genericas/:id', 
    loadComponent: () => import ('./components/tabelasgenericas/tabelagenerica-form/tabelagenerica-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'tabelasGenericasLista' }
  },{ 
    path: 'admin/grupos-emails', 
    loadComponent: () => import ('./components/gruposemails/grupoemail-list/grupoemail-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'gruposEmailsLista' }
  },{ 
    path: 'admin/grupos-emails/novo', 
    loadComponent: () => import ('./components/gruposemails/grupoemail-form/grupoemail-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'gruposEmailsLista' }
  },{ 
    path: 'admin/grupos-emails/:id', 
    loadComponent: () => import ('./components/gruposemails/grupoemail-form/grupoemail-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'gruposEmailsLista' }
  },{ 
    path: 'admin/motivos-descadastro-emails', 
    loadComponent: () => import ('./components/motivosdescadastroemail/motivodescadastroemail-list/motivodescadastroemail-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'motivosDescadastroEmailLista' }
  },{ 
    path: 'admin/motivos-descadastro-emails/novo', 
    loadComponent: () => import ('./components/motivosdescadastroemail/motivodescadastroemail-form/motivodescadastroemail-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'motivosDescadastroEmailLista' }
  },{ 
    path: 'admin/motivos-descadastro-emails/:id', 
    loadComponent: () => import ('./components/motivosdescadastroemail/motivodescadastroemail-form/motivodescadastroemail-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'motivosDescadastroEmailLista' }
  },{ 
    path: 'admin/grupos-produtos2', 
    loadComponent: () => import ('./components/gruposprodutos2/grupoproduto2-list/grupoproduto2-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'gruposProdutos2Lista' }
  },{ 
    path: 'admin/grupos-produtos2/novo', 
    loadComponent: () => import ('./components/gruposprodutos2/grupoproduto2-form/grupoproduto2-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'gruposProdutos2Lista' }
  },{ 
    path: 'admin/grupos-produtos2/:id', 
    loadComponent: () => import ('./components/gruposprodutos2/grupoproduto2-form/grupoproduto2-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'gruposProdutos2Lista' }
  },{ 
    path: 'admin/grupos-produtos3', 
    loadComponent: () => import ('./components/gruposprodutos3/grupoproduto3-list/grupoproduto3-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'gruposProdutos3Lista' }
  },{ 
    path: 'admin/grupos-produtos3/novo', 
    loadComponent: () => import ('./components/gruposprodutos3/grupoproduto3-form/grupoproduto3-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'gruposProdutos3Lista' }
  },{ 
    path: 'admin/grupos-produtos3/:id', 
    loadComponent: () => import ('./components/gruposprodutos3/grupoproduto3-form/grupoproduto3-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'gruposProdutos3Lista' }
  },{ 
    path: 'admin/grupos-produtos4', 
    loadComponent: () => import ('./components/gruposprodutos4/grupoproduto4-list/grupoproduto4-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'gruposProdutos4Lista' }
  },{ 
    path: 'admin/grupos-produtos4/novo', 
    loadComponent: () => import ('./components/gruposprodutos4/grupoproduto4-form/grupoproduto4-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'gruposProdutos4Lista' }
  },{ 
    path: 'admin/grupos-produtos4/:id', 
    loadComponent: () => import ('./components/gruposprodutos4/grupoproduto4-form/grupoproduto4-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'gruposProdutos4Lista' }
  },{ 
    path: 'admin/cupons', 
    loadComponent: () => import ('./components/cupons/cupom-list/cupom-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'cuponsLista' }
  },{ 
    path: 'admin/cupons/novo', 
    loadComponent: () => import ('./components/cupons/cupom-form/cupom-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'cuponsLista' }
  },{ 
    path: 'admin/cupons/:id', 
    loadComponent: () => import ('./components/cupons/cupom-form/cupom-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'cuponsLista' }
  },{ 
    path: 'admin/edificios', 
    loadComponent: () => import ('./components/edificios/edificio-list/edificio-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'edificiosLista' }
  },{ 
    path: 'admin/edificios/novo', 
    loadComponent: () => import ('./components/edificios/edificio-form/edificio-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'edificiosLista' }
  },{ 
    path: 'admin/edificios/:id', 
    loadComponent: () => import ('./components/edificios/edificio-form/edificio-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'edificiosLista' }
  },{ 
    path: 'admin/avaliacoes-produtos', 
    loadComponent: () => import ('./components/avaliacoesprodutos/avaliacaoproduto-list/avaliacaoproduto-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'edificiosLista' }
  },{ 
    path: 'admin/avaliacoes-produtos/novo', 
    loadComponent: () => import ('./components/avaliacoesprodutos/avaliacaoproduto-form/avaliacaoproduto-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'avaliacoesProdutosLista' }
  },{ 
    path: 'admin/avaliacoes-produtos/:id', 
    loadComponent: () => import ('./components/avaliacoesprodutos/avaliacaoproduto-form/avaliacaoproduto-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'avaliacoesProdutosLista' }
  },{ 
    path: 'admin/opcoes-gerais', 
    loadComponent: () => import ('./components/opcoesgerais/opcaogeral-list/opcaogeral-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'opcoesGeraisLista' }
  },{ 
    path: 'admin/opcoes-gerais/novo', 
    loadComponent: () => import ('./components/opcoesgerais/opcaogeral-form/opcaogeral-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'opcoesGeraisLista' }
  },{ 
    path: 'admin/opcoes-gerais/:id', 
    loadComponent: () => import ('./components/opcoesgerais/opcaogeral-form/opcaogeral-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'opcoesGeraisLista' }
  },{ 
    path: 'admin/estoques', 
    loadComponent: () => import ('./components/estoques/estoque-list/estoque-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'estoquesLista' }
  },{ 
    path: 'admin/estoques/novo', 
    loadComponent: () => import ('./components/estoques/estoque-form/estoque-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'estoquesLista' }
  },{ 
    path: 'admin/estoques/:id', 
    loadComponent: () => import ('./components/estoques/estoque-form/estoque-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'estoquesLista' }
  },{ 
    path: 'admin/dados-bancarios', 
    loadComponent: () => import ('./components/dadosbancarios/dadobancario-list/dadobancario-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'dadosBancariosLista' }
  },{ 
    path: 'admin/dados-bancarios/novo', 
    loadComponent: () => import ('./components/dadosbancarios/dadobancario-form/dadobancario-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'dadosBancariosLista' }
  },{ 
    path: 'admin/dados-bancarios/:id', 
    loadComponent: () => import ('./components/dadosbancarios/dadobancario-form/dadobancario-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'dadosBancariosLista' }
  },{ 
    path: 'admin/lancamentos', 
    loadComponent: () => import ('./components/lancamentos/lancamento-list/lancamento-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'lancamentosLista' }
  },{ 
    path: 'admin/lancamentos/novo', 
    loadComponent: () => import ('./components/lancamentos/lancamento-form/lancamento-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'lancamentosLista' }
  },{ 
    path: 'admin/lancamentos/:id', 
    loadComponent: () => import ('./components/lancamentos/lancamento-form/lancamento-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'lancamentosLista' }
  },{ 
    path: 'admin/relatorios/livro-caixa', 
    loadComponent: () => import ('./components/relatorios/relatorio-livro-caixa/relatorio-livro-caixa.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'relatorioLivroCaixa', title: 'Relatório de Livro Caixa' }
  },{ 
    path: 'admin/relatorios/fluxo-caixa', 
    loadComponent: () => import ('./components/relatorios/relatorio-fluxo-caixa/relatorio-fluxo-caixa.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'relatorioFluxoCaixa', title: 'Relatório de Fluxo de Caixa' }
  },{ 
    path: 'admin/relatorios/dre', 
    loadComponent: () => import ('./components/relatorios/relatorio-dre/relatorio-dre.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'relatorioDre', title: 'Relatório DRE' }
  },{ 
    path: 'admin/relatorios/dre-horizontal', 
    loadComponent: () => import ('./components/relatorios/relatorio-dre-horizontal/relatorio-dre-horizontal.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'relatorioDreHorizontal', title: 'Relatório DRE' }
  },{ 
    path: 'admin/setores', 
    loadComponent: () => import ('./components/setores/setor-list/setor-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'setoresLista' }
  },{ 
    path: 'admin/setores/novo', 
    loadComponent: () => import ('./components/setores/setor-form/setor-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'setoresLista' }
  },{ 
    path: 'admin/setores/:id', 
    loadComponent: () => import ('./components/setores/setor-form/setor-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'setoresLista' }
  },{ 
    path: 'admin/funcoes', 
    loadComponent: () => import ('./components/funcoes/funcao-list/funcao-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'funcoesLista' }
  },{ 
    path: 'admin/funcoes/novo', 
    loadComponent: () => import ('./components/funcoes/funcao-form/funcao-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'funcoesLista' }
  },{ 
    path: 'admin/funcoes/:id', 
    loadComponent: () => import ('./components/funcoes/funcao-form/funcao-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'funcoesLista' }
  },{ 
    path: 'admin/funcionarios', 
    loadComponent: () => import ('./components/funcionarios/funcionario-list/funcionario-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'funcionariosLista' }
  },{ 
    path: 'admin/funcionarios/novo', 
    loadComponent: () => import ('./components/funcionarios/funcionario-form/funcionario-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'funcionariosLista' }
  },{ 
    path: 'admin/funcionarios/:id', 
    loadComponent: () => import ('./components/funcionarios/funcionario-form/funcionario-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'funcionariosLista' }
  },{ 
    path: 'admin/motivos-movimentacao', 
    loadComponent: () => import ('./components/motivosmovimentacao/motivomovimentacao-list/motivomovimentacao-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'motivosMovimentacaoLista' }
  },{ 
    path: 'admin/motivos-movimentacao/novo', 
    loadComponent: () => import ('./components/motivosmovimentacao/motivomovimentacao-form/motivomovimentacao-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'motivosMovimentacaoLista' }
  },{ 
    path: 'admin/motivos-movimentacao/:id', 
    loadComponent: () => import ('./components/motivosmovimentacao/motivomovimentacao-form/motivomovimentacao-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'motivosMovimentacaoLista' }
  },{ 
    path: 'admin/produtos-cliente', 
    loadComponent: () => import ('./components/produtoscliente/produtocliente-list/produtocliente-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'produtosClienteLista' }
  },{ 
    path: 'admin/produtos-cliente/novo', 
    loadComponent: () => import ('./components/produtoscliente/produtocliente-form/produtocliente-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'produtosClienteLista' }
  },{ 
    path: 'admin/produtos-cliente/:id', 
    loadComponent: () => import ('./components/produtoscliente/produtocliente-form/produtocliente-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'produtosClienteLista' }
  },{ 
    path: 'admin/movimentos-epi', 
    loadComponent: () => import ('./components/movimentosepi/movimentoepi-list/movimentoepi-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'movimentosEpiLista' }
  },{ 
    path: 'admin/movimentos-epi/novo', 
    loadComponent: () => import ('./components/movimentosepi/movimentoepi-form/movimentoepi-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'movimentosEpiLista' }
  },{ 
    path: 'admin/movimentos-epi/:id', 
    loadComponent: () => import ('./components/movimentosepi/movimentoepi-form/movimentoepi-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'movimentosEpiLista' }
  },{ 
    path: 'admin/movimentos-epi/:idrelacao/itens', 
    loadComponent: () => import ('./components/movimentosepi/produtomovimentoepi-form/produtomovimentoepi-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'movimentosEpiLista' }
  },{ 
    path: 'admin/movimentos-epi/:idrelacao/itens/:id', 
    loadComponent: () => import ('./components/movimentosepi/produtomovimentoepi-form/produtomovimentoepi-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'movimentosEpiLista' }
  },{ 
    path: 'admin/estoques-epi', 
    loadComponent: () => import ('./components/estoquesepi/estoqueepi-list/estoqueepi-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'estoquesEpiLista' }
  },{ 
    path: 'admin/estoques-epi/novo', 
    loadComponent: () => import ('./components/estoquesepi/estoqueepi-form/estoqueepi-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'estoquesEpiLista' }
  },{ 
    path: 'admin/estoques-epi/:id', 
    loadComponent: () => import ('./components/estoquesepi/estoqueepi-form/estoqueepi-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'estoquesEpiLista' }
  },{ 
    path: 'admin/permissoes', 
    loadComponent: () => import ('./components/permissoes/permissao-list/permissao-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'permissoesLista' }
  },{ 
    path: 'admin/permissoes/novo', 
    loadComponent: () => import ('./components/permissoes/permissao-form/permissao-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'permissoesLista' }
  },{ 
    path: 'admin/permissoes/:id', 
    loadComponent: () => import ('./components/permissoes/permissao-form/permissao-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'permissoesLista' }
  },{ 
    path: 'admin/relatorios/produtos-empresa', 
    loadComponent: () => import ('./components/relatorios/relatorio-epi-empresa/relatorio-epi-empresa.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'relatorioEpisEmpresa', title: 'Relatório de EPIs da Empresa' }
  },{ 
    path: 'admin/relatorios/epis-funcionario', 
    loadComponent: () => import ('./components/relatorios/relatorio-epi-funcionario/relatorio-epi-funcionario.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'relatorioEpisFuncionario', title: 'Relatório de EPIs do Funcionário' }
  },{ 
    path: 'admin/pacotes', 
    loadComponent: () => import ('./components/pacotes/pacote-list/pacote-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'pacotesLista' }
  },{ 
    path: 'admin/pacotes/novo', 
    loadComponent: () => import ('./components/pacotes/pacote-form/pacote-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'pacotesLista' }
  },{ 
    path: 'admin/pacotes/:id', 
    loadComponent: () => import ('./components/pacotes/pacote-form/pacote-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'pacotesLista' }
  },{ 
    path: 'admin/periodicidades', 
    loadComponent: () => import ('./components/periodicidades/periodicidade-list/periodicidade-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'periodicidadesLista' }
  },{ 
    path: 'admin/periodicidades/novo', 
    loadComponent: () => import ('./components/periodicidades/periodicidade-form/periodicidade-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'periodicidadesLista' }
  },{ 
    path: 'admin/periodicidades/:id', 
    loadComponent: () => import ('./components/periodicidades/periodicidade-form/periodicidade-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'periodicidadesLista' }
  },{ 
    path: 'admin/planos', 
    loadComponent: () => import ('./components/planos/plano-list/plano-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'planosLista' }
  },{ 
    path: 'admin/planos/novo', 
    loadComponent: () => import ('./components/planos/plano-form/plano-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'planosLista' }
  },{ 
    path: 'admin/planos/:id', 
    loadComponent: () => import ('./components/planos/plano-form/plano-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'planosLista' }
  },{ 
    path: 'admin/dashboards', 
    loadComponent: () => import ('./components/dashboards/dashboard-list/dashboard-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'dashboardsLista' }
  },{ 
    path: 'admin/dashboards/novo', 
    loadComponent: () => import ('./components/dashboards/dashboard-form/dashboard-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'dashboardsLista' }
  },{ 
    path: 'admin/dashboards/:id', 
    loadComponent: () => import ('./components/dashboards/dashboard-form/dashboard-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'dashboardsLista' }
  },{ 
    path: 'admin/dados-dashboard', 
    loadComponent: () => import ('./components/dadosdashboard/dadodashboard-list/dadodashboard-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'dadosDashboardLista' }
  },{ 
    path: 'admin/dados-dashboard/novo', 
    loadComponent: () => import ('./components/dadosdashboard/dadodashboard-form/dadodashboard-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'dadosDashboardLista' }
  },{ 
    path: 'admin/dados-dashboard/:id', 
    loadComponent: () => import ('./components/dadosdashboard/dadodashboard-form/dadodashboard-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'dadosDashboardLista' }
  },{ 
    path: 'admin/relatorios/clientes', 
    loadComponent: () => import ('./components/relatorios/relatorio-clientes/relatorio-clientes.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'relatorioClientes', title: 'Relatório de Clientes' }
  },{ 
    path: 'admin/relatorios/epis', 
    loadComponent: () => import ('./components/relatorios/relatorio-epi/relatorio-epi.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'relatorioEpis', title: 'Relatório de EPIs' }
  },{ 
    path: 'admin/relatorios/epis-cliente', 
    loadComponent: () => import ('./components/relatorios/relatorio-epi-cliente/relatorio-epi-cliente.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'relatorioEpisCliente', title: 'Relatório de EPIs do Cliente' }
  },{ 
    path: 'admin/relatorios/pedido-vendas', 
    loadComponent: () => import ('./components/relatorios/relatorio-pedido-vendas/relatorio-pedido-vendas.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'relatorioPedidoVendas', title: 'Relatório de Pedidos de Venda' }
  },{ 
    path: 'admin/orcamentos-venda', 
    loadComponent: () => import ('./components/orcamentovendas/orcamentovenda-list/orcamentovenda-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'orcamentoVendasLista' }
  },{ 
    path: 'admin/orcamentos-venda/novo', 
    loadComponent: () => import ('./components/orcamentovendas/orcamentovenda-form/orcamentovenda-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'orcamentoVendasLista' }
  },{ 
    path: 'admin/orcamentos-venda/:id', 
    loadComponent: () => import ('./components/orcamentovendas/orcamentovenda-form/orcamentovenda-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'orcamentoVendasLista' }
  },{ 
    path: 'admin/orcamentos-venda/:idrelacao/itens', 
    loadComponent: () => import ('./components/orcamentovendas/produtoorcamentovenda-form/produtoorcamentovenda-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'orcamentoVendasLista' }
  },{ 
    path: 'admin/orcamentos-venda/:idrelacao/itens/:id', 
    loadComponent: () => import ('./components/orcamentovendas/produtoorcamentovenda-form/produtoorcamentovenda-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'orcamentoVendasLista' }
  },{
    path: 'admin/orcamentos-venda/:idrelacao/projetos',
    loadComponent: () => import ('./components/orcamentovendas/projetoorcamentovenda-form/projetoorcamentovenda-form.component'),
    canActivate: [AuthGuard],
    data: { menuRoute: 'orcamentoVendasProjetosLista' }
  },{
    path: 'admin/orcamentos-venda/:idrelacao/projetos/:id',
    loadComponent: () => import ('./components/orcamentovendas/projetoorcamentovenda-form/projetoorcamentovenda-form.component'),
    canActivate: [AuthGuard],
    data: { menuRoute: 'orcamentoVendasProjetosLista' }
  },{
    path: 'admin/orcamentos-venda/:idrelacao/projetos/:idprojeto/produtos',
    loadComponent: () => import ('./components/orcamentovendas/produtoprojetoorcamentovenda-form/produtoprojetoorcamentovenda-form.component'),
    canActivate: [AuthGuard],
    data: { menuRoute: 'orcamentoVendasLista' }
  },{
    path: 'admin/orcamentos-venda/:idrelacao/projetos/:idprojeto/produtos/:id',
    loadComponent: () => import ('./components/orcamentovendas/produtoprojetoorcamentovenda-form/produtoprojetoorcamentovenda-form.component'),
    canActivate: [AuthGuard],
    data: { menuRoute: 'orcamentoVendasLista' }
  },{ 
    path: 'admin/relatorios/entrega-epis', 
    loadComponent: () => import ('./components/relatorios/relatorio-entregas-epi/relatorio-entregas-epi.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'relatorioEntregasEpi', title: 'Relatório de Entregas de EPI' }
  },{ 
    path: 'admin/posicoes-atletas', 
    loadComponent: () => import ('./components/posicoesatleta/posicaoatleta-list/posicaoatleta-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'posicoesAtletasLista' }
  },{ 
    path: 'admin/posicoes-atletas/novo', 
    loadComponent: () => import ('./components/posicoesatleta/posicaoatleta-form/posicaoatleta-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'posicoesAtletasLista' }
  },{ 
    path: 'admin/posicoes-atletas/:id', 
    loadComponent: () => import ('./components/posicoesatleta/posicaoatleta-form/posicaoatleta-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'posicoesAtletasLista' }
  },{ 
    path: 'admin/turmas-atletas', 
    loadComponent: () => import ('./components/turmasatleta/turmaatleta-list/turmaatleta-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'turmasAtletasLista' }
  },{ 
    path: 'admin/turmas-atletas/novo', 
    loadComponent: () => import ('./components/turmasatleta/turmaatleta-form/turmaatleta-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'turmasAtletasLista' }
  },{ 
    path: 'admin/turmas-atletas/:id', 
    loadComponent: () => import ('./components/turmasatleta/turmaatleta-form/turmaatleta-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'turmasAtletasLista' }
  },{ 
    path: 'admin/categorias-atletas', 
    loadComponent: () => import ('./components/categoriasatleta/categoriaatleta-list/categoriaatleta-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'categoriasAtletasLista' }
  },{ 
    path: 'admin/categorias-atletas/novo', 
    loadComponent: () => import ('./components/categoriasatleta/categoriaatleta-form/categoriaatleta-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'categoriasAtletasLista' }
  },{ 
    path: 'admin/categorias-atletas/:id', 
    loadComponent: () => import ('./components/categoriasatleta/categoriaatleta-form/categoriaatleta-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'categoriasAtletasLista' }
  },{ 
    path: 'admin/locais-avaliacao', 
    loadComponent: () => import ('./components/locaisavaliacao/localavaliacao-list/localavaliacao-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'locaisAvaliacaoLista' }
  },{ 
    path: 'admin/locais-avaliacao/novo', 
    loadComponent: () => import ('./components/locaisavaliacao/localavaliacao-form/localavaliacao-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'locaisAvaliacaoLista' }
  },{ 
    path: 'admin/locais-avaliacao/:id', 
    loadComponent: () => import ('./components/locaisavaliacao/localavaliacao-form/localavaliacao-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'locaisAvaliacaoLista' }
  },{ 
    path: 'admin/finalidades-avaliacao-coletiva', 
    loadComponent: () => import ('./components/finalidadesavaliacaocoletiva/finalidadeavaliacaocoletiva-list/finalidadeavaliacaocoletiva-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'finalidadesAvaliacaoColetivaLista' }
  },{ 
    path: 'admin/finalidades-avaliacao-coletiva/novo', 
    loadComponent: () => import ('./components/finalidadesavaliacaocoletiva/finalidadeavaliacaocoletiva-form/finalidadeavaliacaocoletiva-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'finalidadesAvaliacaoColetivaLista' }
  },{ 
    path: 'admin/finalidades-avaliacao-coletiva/:id', 
    loadComponent: () => import ('./components/finalidadesavaliacaocoletiva/finalidadeavaliacaocoletiva-form/finalidadeavaliacaocoletiva-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'finalidadesAvaliacaoColetivaLista' }
  },{ 
    path: 'admin/objetivos-avaliacao-individual', 
    loadComponent: () => import ('./components/objetivosavaliacaoindividual/objetivoavaliacaoindividual-list/objetivoavaliacaoindividual-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'objetivosAvaliacaoIndividualLista' }
  },{ 
    path: 'admin/objetivos-avaliacao-individual/novo', 
    loadComponent: () => import ('./components/objetivosavaliacaoindividual/objetivoavaliacaoindividual-form/objetivoavaliacaoindividual-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'objetivosAvaliacaoIndividualLista' }
  },{ 
    path: 'admin/objetivos-avaliacao-individual/:id', 
    loadComponent: () => import ('./components/objetivosavaliacaoindividual/objetivoavaliacaoindividual-form/objetivoavaliacaoindividual-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'objetivosAvaliacaoIndividualLista' }
  },{ 
    path: 'admin/avaliacoes-individuais', 
    loadComponent: () => import ('./components/avaliacoesindividuais/avaliacaoindividual-list/avaliacaoindividual-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'avaliacoesIndividuaisLista' }
  },{ 
    path: 'admin/avaliacoes-individuais/novo', 
    loadComponent: () => import ('./components/avaliacoesindividuais/avaliacaoindividual-form/avaliacaoindividual-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'avaliacoesIndividuaisLista' }
  },{ 
    path: 'admin/avaliacoes-individuais/:id', 
    loadComponent: () => import ('./components/avaliacoesindividuais/avaliacaoindividual-form/avaliacaoindividual-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'avaliacoesIndividuaisLista' }
  },{ 
    path: 'admin/avaliacoes-coletivas', 
    loadComponent: () => import ('./components/avaliacoescoletivas/avaliacaocoletiva-list/avaliacaocoletiva-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'avaliacoesColetivasLista' }
  },{ 
    path: 'admin/avaliacoes-coletivas/novo', 
    loadComponent: () => import ('./components/avaliacoescoletivas/avaliacaocoletiva-form/avaliacaocoletiva-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'avaliacoesColetivasLista' }
  },{ 
    path: 'admin/avaliacoes-coletivas/:id', 
    loadComponent: () => import ('./components/avaliacoescoletivas/avaliacaocoletiva-form/avaliacaocoletiva-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'avaliacoesColetivasLista' }
  },{ 
    path: 'admin/pesquisas', 
    loadComponent: () => import ('./components/pesquisas/pesquisa-list/pesquisa-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'pesquisasLista' }
  },{ 
    path: 'admin/pesquisas/novo', 
    loadComponent: () => import ('./components/pesquisas/pesquisa-form/pesquisa-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'pesquisasLista' }
  },{ 
    path: 'admin/pesquisas/:id', 
    loadComponent: () => import ('./components/pesquisas/pesquisa-form/pesquisa-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'pesquisasLista' }
  },{ 
    path: 'admin/pesquisas/:idrelacao/perguntas', 
    loadComponent: () => import ('./components/pesquisas/perguntapesquisa-form/perguntapesquisa-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'pesquisasLista', title: 'Perguntas da Pesquisa' }
  },{ 
    path: 'admin/pesquisas/:idrelacao/perguntas/:id', 
    loadComponent: () => import ('./components/pesquisas/perguntapesquisa-form/perguntapesquisa-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'pesquisasLista', title: 'Perguntas da Pesquisa' }
  },{ 
    path: 'admin/pesquisas/:idrelacao/perguntas/:idpergunta/opcoes-respota', 
    loadComponent: () => import ('./components/pesquisas/perguntapesquisa-form/perguntapesquisa-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'pesquisasLista', title: 'Perguntas da Pesquisa' }
  },{ 
    path: 'admin/pesquisas/:idrelacao/perguntas/:idpergunta/opcoes-respota/:id', 
    loadComponent: () => import ('./components/pesquisas/perguntapesquisa-form/perguntapesquisa-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'pesquisasLista', title: 'Perguntas da Pesquisa' }
  },{ 
    path: 'admin/relatorios/comprovantes-entrega', 
    loadComponent: () => import ('./components/relatorios/relatorio-comprovantes-entrega/relatorio-comprovantes-entrega.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'relatorioComprovantesEntrega', title: 'Relatório de Comprovantes de Entrega' }
  },{ 
    path: 'admin/relatorios/avaliacoes-coletivas', 
    loadComponent: () => import ('./components/relatorios/relatorio-avaliacoes-coletivas/relatorio-avaliacoes-coletivas.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'relatorioAvaliacoesColetivas', title: 'Relatório de Avaliações Coletivas' }
  },{ 
    path: 'admin/relatorios/avaliacoes-individuais', 
    loadComponent: () => import ('./components/relatorios/relatorio-avaliacoes-individuais/relatorio-avaliacoes-individuais.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'relatorioAvaliacoesIndividuais', title: 'Relatório de Avaliações Individuais' }
  },{ 
    path: 'admin/relatorios/atletas', 
    loadComponent: () => import ('./components/relatorios/relatorio-atletas/relatorio-atletas.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'relatorioAtletas', title: 'Relatório de Atletas' }
  },{ 
    path: 'admin/relatorios/epis-proximos-expiracao-uso', 
    loadComponent: () => import ('./components/relatorios/relatorio-epis-proximo-expiracao-uso/relatorio-epis-proximo-expiracao-uso.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'relatorioEpisProximosExpiarcaoUso', title: 'Relatório de EPIs próximos da Expiração por Uso' }
  },{ 
    path: 'admin/etapas-pedidos-compra', 
    loadComponent: () => import ('./components/etapaspedidoscompra/etapapedidocompra-list/etapapedidocompra-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'etapasPedidoCompraLista' }
  },{ 
    path: 'admin/etapas-pedidos-compra/novo', 
    loadComponent: () => import ('./components/etapaspedidoscompra/etapapedidocompra-form/etapapedidocompra-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'etapasPedidoCompraLista' }
  },{ 
    path: 'admin/etapas-pedidos-compra/:id', 
    loadComponent: () => import ('./components/etapaspedidoscompra/etapapedidocompra-form/etapapedidocompra-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'etapasPedidoCompraLista' }
  },{ 
    path: 'admin/pedidos-compra', 
    loadComponent: () => import ('./components/pedidocompras/pedidocompra-list/pedidocompra-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'pedidoComprasLista' }
  },{ 
    path: 'admin/pedidos-compra/novo', 
    loadComponent: () => import ('./components/pedidocompras/pedidocompra-form/pedidocompra-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'pedidoComprasLista' }
  },{ 
    path: 'admin/pedidos-compra/:id', 
    loadComponent: () => import ('./components/pedidocompras/pedidocompra-form/pedidocompra-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'pedidoComprasLista' }
  },{ 
    path: 'admin/pedidos-compra/:idrelacao/itens', 
    loadComponent: () => import ('./components/pedidocompras/produtopedidocompra-form/produtopedidocompra-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'pedidoComprasLista', title: 'Itens do Pedido de Compra' }
  },{ 
    path: 'admin/pedidos-compra/:idrelacao/itens/:id', 
    loadComponent: () => import ('./components/pedidocompras/produtopedidocompra-form/produtopedidocompra-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'pedidoComprasLista', title: 'Itens do Pedido de Compra' }
  },{ 
    path: 'admin/pedidos-compra/:idrelacao/etapas', 
    loadComponent: () => import ('./components/pedidocompras/relacaoetapapedidocompra-form/relacaoetapapedidocompra-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'pedidoComprasLista', title: 'Etapa do Pedido de Compra' }
  },{ 
    path: 'admin/pedidos-compra/:idrelacao/etapas/:id', 
    loadComponent: () => import ('./components/pedidocompras/relacaoetapapedidocompra-form/relacaoetapapedidocompra-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'pedidoComprasLista', title: 'Etapa do Pedido de Compra' }
  },{ 
    path: 'admin/funcoes-usuario', 
    loadComponent: () => import ('./components/funcoesusuarios/funcaousuario-list/funcaousuario-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'funcoesUsuarioLista' }
  },{ 
    path: 'admin/funcoes-usuario/novo', 
    loadComponent: () => import ('./components/funcoesusuarios/funcaousuario-form/funcaousuario-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'funcoesUsuarioLista' }
  },{ 
    path: 'admin/funcoes-usuario/:id', 
    loadComponent: () => import ('./components/funcoesusuarios/funcaousuario-form/funcaousuario-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'funcoesUsuarioLista' }
  },{ 
    path: 'admin/idiomas', 
    loadComponent: () => import ('./components/idiomas/idioma-list/idioma-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'idiomasLista' }
  },{ 
    path: 'admin/idiomas/novo', 
    loadComponent: () => import ('./components/idiomas/idioma-form/idioma-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'idiomasLista' }
  },{ 
    path: 'admin/idiomas/:id', 
    loadComponent: () => import ('./components/idiomas/idioma-form/idioma-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'idiomasLista' }
  },{ 
    path: 'admin/carrinhos-loja', 
    loadComponent: () => import ('./components/itenspencart/itemopencart-list/itemopencart-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'itensOpenCartLista' }
  },{ 
    path: 'admin/aniversariantes', 
    loadComponent: () => import ('./components/aniversariantes/aniversariante-list/aniversariante-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'aniversariantesLista' }
  },{ 
    path: 'admin/relatorios/comissoes-geradas', 
    loadComponent: () => import ('./components/relatorios/relatorio-comissoes-geradas/relatorio-comissoes-geradas.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'relatorioComissoesGeradas', title: 'Relatório de Comissões Geradas' }
  },{ 
    path: 'admin/permissoes-api', 
    loadComponent: () => import ('./components/permissoesapi/permissaoapi-list/permissaoapi-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'permissoesAPILista' }
  },{ 
    path: 'admin/permissoes-api/novo', 
    loadComponent: () => import ('./components/permissoesapi/permissaoapi-form/permissaoapi-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'permissoesAPILista' }
  },{ 
    path: 'admin/permissoes-api/:id', 
    loadComponent: () => import ('./components/permissoesapi/permissaoapi-form/permissaoapi-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'permissoesAPILista' }
  },{ 
    path: 'admin/faturamentos', 
    loadComponent: () => import ('./components/faturamentos/faturamento-list/faturamento-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'faturamentosLista' }
  },{ 
    path: 'admin/faturamentos/novo', 
    loadComponent: () => import ('./components/faturamentos/faturamento-form/faturamento-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'faturamentosLista' }
  },{ 
    path: 'admin/faturamentos/:id', 
    loadComponent: () => import ('./components/faturamentos/faturamento-form/faturamento-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'faturamentosLista' }
  },{ 
    path: 'admin/faturamentos/:idrelacao/itens', 
    loadComponent: () => import ('./components/faturamentos/produtofaturamento-form/produtofaturamento-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'faturamentosLista', title: 'Itens do Faturamento' }
  },{ 
    path: 'admin/faturamentos/:idrelacao/itens/:id', 
    loadComponent: () => import ('./components/faturamentos/produtofaturamento-form/produtofaturamento-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'faturamentosLista', title: 'Itens do Faturamento' }
  },{ 
    path: 'admin/departamentos-usuario', 
    loadComponent: () => import ('./components/departamentosusuario/departamentousuario-list/departamentousuario-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'departamentosUsuarioLista' }
  },{ 
    path: 'admin/departamentos-usuario/novo', 
    loadComponent: () => import ('./components/departamentosusuario/departamentousuario-form/departamentousuario-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'departamentosUsuarioLista' }
  },{ 
    path: 'admin/departamentos-usuario/:id', 
    loadComponent: () => import ('./components/departamentosusuario/departamentousuario-form/departamentousuario-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'departamentosUsuarioLista' }
  },{ 
    path: 'admin/grupos-usuario', 
    loadComponent: () => import ('./components/gruposusuarios/grupousuario-list/grupousuario-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'gruposUsuarioLista' }
  },{ 
    path: 'admin/grupos-usuario/novo', 
    loadComponent: () => import ('./components/gruposusuarios/grupousuario-form/grupousuario-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'gruposUsuarioLista' }
  },{ 
    path: 'admin/grupos-usuario/:id', 
    loadComponent: () => import ('./components/gruposusuarios/grupousuario-form/grupousuario-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'gruposUsuarioLista' }
  },{ 
    path: 'admin/departamentos-ticket', 
    loadComponent: () => import ('./components/departamentosticketatendimento/departamentoticketatendimento-list/departamentoticketatendimento-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'departamentosTicketAtendimentoLista' }
  },{ 
    path: 'admin/departamentos-ticket/novo', 
    loadComponent: () => import ('./components/departamentosticketatendimento/departamentoticketatendimento-form/departamentoticketatendimento-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'departamentosTicketAtendimentoLista' }
  },{ 
    path: 'admin/departamentos-ticket/:id', 
    loadComponent: () => import ('./components/departamentosticketatendimento/departamentoticketatendimento-form/departamentoticketatendimento-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'departamentosTicketAtendimentoLista' }
  },{ 
    path: 'admin/status-ticket', 
    loadComponent: () => import ('./components/statusticketatendimento/statusticketatendimento-list/statusticketatendimento-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'statusTicketAtendimentoLista' }
  },{ 
    path: 'admin/status-ticket/novo', 
    loadComponent: () => import ('./components/statusticketatendimento/statusticketatendimento-form/statusticketatendimento-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'statusTicketAtendimentoLista' }
  },{ 
    path: 'admin/status-ticket/:id', 
    loadComponent: () => import ('./components/statusticketatendimento/statusticketatendimento-form/statusticketatendimento-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'statusTicketAtendimentoLista' }
  },{ 
    path: 'admin/tickets', 
    loadComponent: () => import ('./components/ticketsatendimento/ticketatendimento-list/ticketatendimento-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'ticketsAtendimentoLista' }
  },{ 
    path: 'admin/tickets/novo', 
    loadComponent: () => import ('./components/ticketsatendimento/ticketatendimento-form/ticketatendimento-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'ticketsAtendimentoLista' }
  },{ 
    path: 'admin/tickets/:id', 
    loadComponent: () => import ('./components/ticketsatendimento/ticketatendimento-form/ticketatendimento-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'ticketsAtendimentoLista' }
  },{ 
    path: 'admin/tickets/:idrelacao/interacoes', 
    loadComponent: () => import ('./components/ticketsatendimento/interacaoticketatendimento-form/interacaoticketatendimento-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'ticketsAtendimentoLista', title: 'Interações do Ticket' }
  },{ 
    path: 'admin/tickets/:idrelacao/interacoes/:id', 
    loadComponent: () => import ('./components/ticketsatendimento/interacaoticketatendimento-form/interacaoticketatendimento-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'ticketsAtendimentoLista', title: 'Interações do Ticket' }
  },{ 
    path: 'admin/bi/avaliacoes-coletivas', 
    loadComponent: () => import ('./components/bi/filtro-avaliacao-coletiva/filtro-avaliacao-coletiva.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'biFiltroAvaliacaoColetiva', title: 'BI de Avaliações Coletivas - Filtro' }
  },{ 
    path: 'admin/bi/avaliacoes-coletivas/resultado', 
    loadComponent: () => import ('./components/bi/painel-avaliacao-coletiva/painel-avaliacao-coletiva.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'biFiltroAvaliacaoColetiva', title: 'BI de Avaliações Coletivas - Resultado' }
  },{ 
    path: 'admin/bi/avaliacoes-individuais', 
    loadComponent: () => import ('./components/bi/filtro-avaliacao-individual/filtro-avaliacao-individual.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'biFiltroAvaliacaoIndividual', title: 'BI de Avaliações Individuais - Filtro' }
  },{ 
    path: 'admin/bi/avaliacoes-individuais/resultado', 
    loadComponent: () => import ('./components/bi/painel-avaliacao-individual/painel-avaliacao-individual.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'biFiltroAvaliacaoIndividual', title: 'BI de Avaliações Individuais - Resultado' }
  },{ 
    path: 'admin/permissoes-marketplace', 
    loadComponent: () => import ('./components/permissoesmarketplace/permissaomarketplace-list/permissaomarketplace-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'permissoesMarketplaceLista' }
  },{ 
    path: 'admin/permissoes-marketplace/novo', 
    loadComponent: () => import ('./components/permissoesmarketplace/permissaomarketplace-form/permissaomarketplace-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'permissoesMarketplaceLista' }
  },{ 
    path: 'admin/permissoes-marketplace/:id', 
    loadComponent: () => import ('./components/permissoesmarketplace/permissaomarketplace-form/permissaomarketplace-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'permissoesMarketplaceLista' }
  },{ 
    path: 'admin/series-notas', 
    loadComponent: () => import ('./components/seriesnotas/serienota-list/serienota-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'seriesNotasLista' }
  },{ 
    path: 'admin/series-notas/novo', 
    loadComponent: () => import ('./components/seriesnotas/serienota-form/serienota-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'seriesNotasLista' }
  },{ 
    path: 'admin/series-notas/:id', 
    loadComponent: () => import ('./components/seriesnotas/serienota-form/serienota-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'seriesNotasLista' }
  },{ 
    path: 'admin/sped-nfe', 
    loadComponent: () => import ('./components/spednfe/spednfe-list/spednfe-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'spedNfeLista' }
  },{ 
    path: 'admin/sped-nfe/:id', 
    loadComponent: () => import ('./components/spednfe/spednfe-form/spednfe-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'spedNfeLista' }
  },{ 
    path: 'admin/sped-nfe/:idrelacao/mensagens/:id', 
    loadComponent: () => import ('./components/spednfe/mensagemspednfe-form/mensagemspednfe-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'spedNfeLista', title: 'Mensagens do SPED NFe' }
  },{ 
    path: 'admin/certificados', 
    loadComponent: () => import ('./components/certificados/certificado-list/certificado-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'certificadosLista' }
  },{ 
    path: 'admin/certificados/novo', 
    loadComponent: () => import ('./components/certificados/certificado-form/certificado-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'certificadosLista' }
  },{ 
    path: 'admin/certificados/:id', 
    loadComponent: () => import ('./components/certificados/certificado-form/certificado-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'certificadosLista' }
  },{ 
    path: 'admin/orcamentos-paineis-energia', 
    loadComponent: () => import ('./components/orcamentospaineisenergia/orcamentopainelenergia-list/orcamentopainelenergia-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'orcamentosPaineisEnergiaLista' }
  },{ 
    path: 'admin/orcamentos-paineis-energia/novo', 
    loadComponent: () => import ('./components/orcamentospaineisenergia/orcamentopainelenergia-form/orcamentopainelenergia-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'orcamentosPaineisEnergiaLista' }
  },{ 
    path: 'admin/orcamentos-paineis-energia/:id', 
    loadComponent: () => import ('./components/orcamentospaineisenergia/orcamentopainelenergia-form/orcamentopainelenergia-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'orcamentosPaineisEnergiaLista' }
  },{ 
    path: 'admin/pedidovendas/preview/:id', 
    loadComponent: () => import ('./components/pedidovendas/preview-pedidos-venda/preview-pedidos-venda.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'pedidoVendasLista', title: 'Preview de Pedidos de Venda' }
  },{ 
    path: 'admin/tipos-pacote', 
    loadComponent: () => import ('./components/tipospacote/tipopacote-list/tipopacote-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'tiposPacoteLista' }
  },{ 
    path: 'admin/tipos-pacote/novo', 
    loadComponent: () => import ('./components/tipospacote/tipopacote-form/tipopacote-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'tiposPacoteLista' }
  },{ 
    path: 'admin/tipos-pacote/:id', 
    loadComponent: () => import ('./components/tipospacote/tipopacote-form/tipopacote-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'tiposPacoteLista' }
  },{ 
    path: 'admin/etapas-pedidos-marketplace', 
    loadComponent: () => import ('./components/etapaspedidosgatewaymarketplace/etapapedidogatewaymarketplace-list/etapapedidogatewaymarketplace-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'etapasPedidosMarketplaceLista' }
  },{ 
    path: 'admin/etapas-pedidos-marketplace/novo', 
    loadComponent: () => import ('./components/etapaspedidosgatewaymarketplace/etapapedidogatewaymarketplace-form/etapapedidogatewaymarketplace-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'etapasPedidosMarketplaceLista' }
  },{ 
    path: 'admin/etapas-pedidos-marketplace/:id', 
    loadComponent: () => import ('./components/etapaspedidosgatewaymarketplace/etapapedidogatewaymarketplace-form/etapapedidogatewaymarketplace-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'etapasPedidosMarketplaceLista' }
  },{ 
    path: 'admin/nucleos-esportivos', 
    loadComponent: () => import ('./components/nucleosesportivos/nucleoesportivo-list/nucleoesportivo-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'nucleosEsportivosLista' }
  },{ 
    path: 'admin/nucleos-esportivos/novo', 
    loadComponent: () => import ('./components/nucleosesportivos/nucleoesportivo-form/nucleoesportivo-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'nucleosEsportivosLista' }
  },{ 
    path: 'admin/nucleos-esportivos/:id', 
    loadComponent: () => import ('./components/nucleosesportivos/nucleoesportivo-form/nucleoesportivo-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'nucleosEsportivosLista' }
  },{ 
    path: 'admin/escalas-esportivas', 
    loadComponent: () => import ('./components/escalasesportivas/escalaesportiva/escalaesportiva.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'escalasEsportivas', title: 'Escalas Esportivas' }
  },{ 
    path: 'admin/faturamentos/preview/:id', 
    loadComponent: () => import ('./components/faturamentos/preview-faturamentos/preview-faturamentos.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'faturamentosLista', title: 'Preview de Faturamentos' }
  },{ 
    path: 'admin/query-execute', 
    loadComponent: () => import ('./components/queryexecute/query-execute/query-execute.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'queryExecute', title: 'Query Execute' }
  },{ 
    path: 'admin/relatorios/total-produtos-marketplace', 
    loadComponent: () => import ('./components/relatorios/relatorio-total-produtos-marketplace/relatorio-total-produtos-marketplace.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'relatorioTotaisProdutosMarketplace', title: 'Relatório de Total de Produtos por Marketplace' }
  },{ 
    path: 'admin/instancias-workers', 
    loadComponent: () => import ('./components/instanciasworkers/instanciaworker-list/instanciaworker-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'instanciasWorkersLista' }
  },{ 
    path: 'admin/instancias-workers/novo', 
    loadComponent: () => import ('./components/instanciasworkers/instanciaworker-form/instanciaworker-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'instanciasWorkersLista' }
  },{ 
    path: 'admin/instancias-workers/:id', 
    loadComponent: () => import ('./components/instanciasworkers/instanciaworker-form/instanciaworker-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'instanciasWorkersLista' }
  },{ 
    path: 'admin/worker-produtos-marketplaces', 
    loadComponent: () => import ('./components/workerprodutosmarketplaces/workerprodutomarketplace-list/workerprodutomarketplace-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'workersProdutosMarketplacesLista' }
  },{ 
    path: 'admin/diretorios-ged', 
    loadComponent: () => import ('./components/diretoriosged/diretorioged-list/diretorioged-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'diretoriosGedLista' }
  },{ 
    path: 'admin/diretorios-ged/novo', 
    loadComponent: () => import ('./components/diretoriosged/diretorioged-form/diretorioged-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'diretoriosGedLista' }
  },{ 
    path: 'admin/diretorios-ged/:id', 
    loadComponent: () => import ('./components/diretoriosged/diretorioged-form/diretorioged-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'diretoriosGedLista' }
  },{ 
    path: 'admin/arquivos-ged', 
    loadComponent: () => import ('./components/arquivosged/arquivoged-list/arquivoged-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'arquivosGedLista' }
  },{ 
    path: 'admin/arquivos-ged/novo', 
    loadComponent: () => import ('./components/arquivosged/arquivoged-form/arquivoged-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'arquivosGedLista' }
  },{ 
    path: 'admin/arquivos-ged/:id', 
    loadComponent: () => import ('./components/arquivosged/arquivoged-form/arquivoged-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'arquivosGedLista' }
  },{ 
    path: 'admin/agendamentos', 
    loadComponent: () => import ('./components/agendamentos/agendamento-list/agendamento-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'agendamentosLista' }
  },{ 
    path: 'admin/agendamentos/novo', 
    loadComponent: () => import ('./components/agendamentos/agendamento-form/agendamento-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'agendamentosLista' }
  },{ 
    path: 'admin/agendamentos/:id', 
    loadComponent: () => import ('./components/agendamentos/agendamento-form/agendamento-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'agendamentosLista' }
  },{ 
    path: 'admin/checkin-mercadorias', 
    loadComponent: () => import ('./components/checkinmercadorias/checkinmercadoria-list/checkinmercadoria-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'checkinMercadoriasLista' }
  },{ 
    path: 'admin/checkin-mercadorias/novo', 
    loadComponent: () => import ('./components/checkinmercadorias/checkinmercadoria-form/checkinmercadoria-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'checkinMercadoriasLista' }
  },{ 
    path: 'admin/checkin-mercadorias/:id', 
    loadComponent: () => import ('./components/checkinmercadorias/checkinmercadoria-form/checkinmercadoria-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'checkinMercadoriasLista' }
  },{ 
    path: 'admin/movimentacoes-credito-cliente', 
    loadComponent: () => import ('./components/movimentacoescreditocliente/movimentacaocreditocliente-list/movimentacaocreditocliente-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'movimentacoesCreditoClienteLista' }
  },{ 
    path: 'admin/movimentacoes-credito-cliente/novo', 
    loadComponent: () => import ('./components/movimentacoescreditocliente/movimentacaocreditocliente-form/movimentacaocreditocliente-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'movimentacoesCreditoClienteLista' }
  },{ 
    path: 'admin/movimentacoes-credito-cliente/:id', 
    loadComponent: () => import ('./components/movimentacoescreditocliente/movimentacaocreditocliente-form/movimentacaocreditocliente-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'movimentacoesCreditoClienteLista' }
  },{ 
    path: 'admin/tipos-embalagem', 
    loadComponent: () => import ('./components/tiposembalagem/tipoembalagem-list/tipoembalagem-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'tiposEmbalagemLista' }
  },{ 
    path: 'admin/tipos-embalagem/novo', 
    loadComponent: () => import ('./components/tiposembalagem/tipoembalagem-form/tipoembalagem-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'tiposEmbalagemLista' }
  },{ 
    path: 'admin/tipos-embalagem/:id', 
    loadComponent: () => import ('./components/tiposembalagem/tipoembalagem-form/tipoembalagem-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'tiposEmbalagemLista' }
  },{ 
    path: 'admin/solicitacoes-compra', 
    loadComponent: () => import ('./components/solicitacoescompra/solicitacaocompra-list/solicitacaocompra-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'solicitacoesCompraLista' }
  },{ 
    path: 'admin/solicitacoes-compra/novo', 
    loadComponent: () => import ('./components/solicitacoescompra/solicitacaocompra-form/solicitacaocompra-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'solicitacoesCompraLista' }
  },{ 
    path: 'admin/solicitacoes-compra/:id', 
    loadComponent: () => import ('./components/solicitacoescompra/solicitacaocompra-form/solicitacaocompra-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'solicitacoesCompraLista' }
  },{ 
    path: 'admin/relatorios/pedido-compras', 
    loadComponent: () => import ('./components/relatorios/relatorio-pedido-compras/relatorio-pedido-compras.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'relatorioPedidoCompras', title: 'Relatório de Pedidos de Compra' }
  },{ 
    path: 'admin/boletos', 
    loadComponent: () => import ('./components/boletos/boleto-list/boleto-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'boletosLista' }
  },{ 
    path: 'admin/boletos/novo', 
    loadComponent: () => import ('./components/boletos/boleto-form/boleto-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'boletosLista' }
  },{ 
    path: 'admin/boletos/:id', 
    loadComponent: () => import ('./components/boletos/boleto-form/boleto-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'boletosLista' }
  },{ 
    path: 'admin/gateways-pagamento', 
    loadComponent: () => import ('./components/gatewaysdepagamento/gatewaydepagamento-list/gatewaydepagamento-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'gatewaysPagamentoLista' }
  },{ 
    path: 'admin/gateways-pagamento/novo', 
    loadComponent: () => import ('./components/gatewaysdepagamento/gatewaydepagamento-form/gatewaydepagamento-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'gatewaysPagamentoLista' }
  },{ 
    path: 'admin/gateways-pagamento/:id', 
    loadComponent: () => import ('./components/gatewaysdepagamento/gatewaydepagamento-form/gatewaydepagamento-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'gatewaysPagamentoLista' }
  },{ 
    path: 'admin/agendamentos-dia', 
    loadComponent: () => import ('./components/agendamentosdia/agendamentodia-list/agendamentodia-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'agendamentosDiaLista' }
  },{ 
    path: 'admin/agendamentos-dia/novo', 
    loadComponent: () => import ('./components/agendamentosdia/agendamentodia-form/agendamentodia-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'agendamentosDiaLista' }
  },{ 
    path: 'admin/agendamentos-dia/:id', 
    loadComponent: () => import ('./components/agendamentosdia/agendamentodia-form/agendamentodia-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'agendamentosDiaLista' }
  },{ 
    path: 'admin/relatorios/boletos', 
    loadComponent: () => import ('./components/relatorios/relatorio-boletos/relatorio-boletos.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'relatorioBoletos', title: 'Relatório de Boletos' }
  },{ 
    path: 'admin/atendimento-clinico', 
    loadComponent: () => import ('./components/atendimentoclinico/atendimentoclinico-list/atendimentoclinico-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'atendimentoClinicoLista' }
  },{ 
    path: 'admin/atendimento-clinico/novo', 
    loadComponent: () => import ('./components/atendimentoclinico/atendimentoclinico-form/atendimentoclinico-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'atendimentoClinicoLista' }
  },{ 
    path: 'admin/atendimento-clinico/:id', 
    loadComponent: () => import ('./components/atendimentoclinico/atendimentoclinico-form/atendimentoclinico-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'atendimentoClinicoLista' }
  },{ 
    path: 'admin/finalidades-atendimento-clinico', 
    loadComponent: () => import ('./components/finalidadesatendimentoclinico/finalidadeatendimentoclinico-list/finalidadeatendimentoclinico-list.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'finalidadeAtendimentoClinicoLista' }
  },{ 
    path: 'admin/finalidades-atendimento-clinico/novo', 
    loadComponent: () => import ('./components/finalidadesatendimentoclinico/finalidadeatendimentoclinico-form/finalidadeatendimentoclinico-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'finalidadeAtendimentoClinicoLista' }
  },{ 
    path: 'admin/finalidades-atendimento-clinico/:id', 
    loadComponent: () => import ('./components/finalidadesatendimentoclinico/finalidadeatendimentoclinico-form/finalidadeatendimentoclinico-form.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'finalidadeAtendimentoClinicoLista' }
  },{ 
    path: 'admin/gadgets', 
    loadComponent: () => import ('./components/gadgets/gadgets.component'), 
    canActivate: [AuthGuard],
    data: { menuRoute: 'gadgetsLista', title: 'Gadgets' }
  },{ 
    path: '**', 
    redirectTo: '/404' 
  }
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })]
})
export class AppRoutingModule { }