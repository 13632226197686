import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Service } from './service';
import { EnvService } from './env.service';
import { FilterServiceInterface } from '../helpers/filter-service-interface';
import { PermissaoSistema } from '../models/permissaosistema.model';
import { QueryStringBuilder } from '../helpers/query-string-builder';
import { ModuleServiceInterface } from '../helpers/module-service-interface';

@Injectable({
  providedIn: 'root'
})
export class PermissoesSistemaService extends Service implements ModuleServiceInterface<PermissaoSistema>, FilterServiceInterface<PermissaoSistema> {

  constructor(protected http: HttpClient, protected env: EnvService, protected router: Router, public toastr: ToastrService) {
    super("permissoessistema", http, env, router, toastr);
  }

  getAll(page?: number, ordenation?: string, typeorder?: string, object?: any, limit?: number): Observable<any> {
    let url: string = this.getQueryString(this.baseUrl, page, ordenation, typeorder, object, limit);
    return this.http.get<PermissaoSistema[]>(url, this.getHttpOptions());
  }

  getOne(cod: string): Observable<any> {
    let url: string = this.baseUrl + "/" + cod;
    return this.http.get<PermissaoSistema>(url, this.getHttpOptions());
  }

  insert(item: PermissaoSistema): Observable<any> {
    return this.http.post<PermissaoSistema>(this.baseUrl, item, this.getHttpOptions());
  }

  update(item: PermissaoSistema): Observable<any> {
    let url: string = this.baseUrl + "/" + item.id;
    return this.http.put<PermissaoSistema>(url, item, this.getHttpOptions());
  }

  remove(item: PermissaoSistema): Observable<any> {
    let url: string = this.baseUrl + "/" + item.id;
    return this.http.delete<PermissaoSistema>(url, this.getHttpOptions());
  }

  removeSelected(object: any): Observable<any> {
    let url: string = this.baseUrl + "/delete";
    return this.http.post<any>(url, object, this.getHttpOptions());
  }

  activateSelected(object: any): Observable<any> {
    let url: string = this.baseUrl + "/ativar";
    return this.http.patch<any>(url, object, this.getHttpOptions());
  }

  deactivateSelected(object: any): Observable<any> {
    let url: string = this.baseUrl + "/desativar";
    return this.http.patch<any>(url, object, this.getHttpOptions());
  }

  async getOneByLabel(cod: string) {
    let url: string = this.baseUrl + "/" + cod;
    return this.http.get<PermissaoSistema>(url, this.getHttpOptions()).toPromise();
  }


  getQueryString(baseUrl: string, page?: number, ordenation?: string, typeorder?: string, object?: any, limit?: number): string {
    let qsBuilder: QueryStringBuilder = this.initializeQueryStringBuilder(baseUrl, page, ordenation, typeorder, limit);

    if (object !== undefined) {
      qsBuilder.checkAndAddParam("descricao", object.descricao);
      qsBuilder.checkAndAddParam("labelmenu", object.labelmenu);
      qsBuilder.checkAndAddParamNumeric("modulo", object.modulo);
      qsBuilder.checkAndAddParamNumeric("id", object.id);
    }

    return qsBuilder.build();
  }

}
