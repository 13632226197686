import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[copyToClipboard]',
    standalone: true
})
export class CopyToClipboardDirective {

  @Input() copyToClipboard: string;

  constructor(private elementRef: ElementRef) {}

  @HostListener('click') onclick() {
    this.copy();
  }

  private copy(): void {
    const host = this.elementRef.nativeElement;
    const value = this.copyToClipboard ? this.copyToClipboard : '';

    window.navigator['clipboard'].writeText(value).then(
      () => {
        if(host.tagName == 'BUTTON') {
          host.classList.add('btn-action-success');
          setTimeout(()=> host.classList.remove('btn-action-success'), 750);
        }
      },
      () => {
        console.error('Ocorreu um erro ao copiar os dados para a área de transferência');
      }
    );
  }
}
