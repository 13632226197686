import { ModelGrupoMenu } from '../models/modelgrupomenu.model';
import { UsuarioEnum } from '../enums/usuarioEnum';
import { EnumItem } from '../enums/enumItem';
import { UsuariosService } from '../services/usuarios.service';
import { PermissaoSistema } from '../models/permissaosistema.model';
import { PermissaoHelper } from './permissao-helper';

export class MenuHelper {

    static getMenu(service: UsuariosService): ModelGrupoMenu[] {
        let gruposMenu: ModelGrupoMenu[] = [];
        let usuarioEnum: UsuarioEnum = new UsuarioEnum();
        let enumItem: EnumItem =  usuarioEnum.getItem("modulo");

        // Consulta as permissões no backend
        service.getPermissions().subscribe(data => {
            let permissoesSistema: PermissaoSistema[] = data.result;

            // Percorre a Enum e monta a listagem com os grupos
            enumItem.getValues().map(option => {
                let modulo = option.value;

                // Cria um array apenas com as permissões do grupo
                let permissoesGrupo: PermissaoSistema[] = permissoesSistema.filter(
                    permissao => {
                        return permissao.modulo == modulo 
                                && permissao.routemenu !== "" 
                                && permissao.routemenu !== null 
                                && PermissaoHelper.hasPermissao(permissao.codpermissaosistema);
                    }
                );

                // Cria o objeto do Grupo de Menu
                let grupoMenu = new ModelGrupoMenu(modulo, option.description, option.icon);
                grupoMenu.setPermissoes(permissoesGrupo);

                // Adiciona o grupo à lista de grupos
                gruposMenu.push(grupoMenu);
            });
        });

        return gruposMenu;
    }

}
