import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EnvService } from '../services/env.service';
import { AuthHelper } from './auth-helper';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(private router: Router, public env: EnvService, private toastr: ToastrService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (AuthHelper.isAuthenticated(this.env)) {
            return true;
        }

        localStorage.setItem("redirectAfterLoginUrl", state.url);

        if(localStorage.getItem("isExpirated") === "S") {
            this.toastr.info("Sua sessão expirou! <br>Por favor, faça login novamente.", "", {
                positionClass: 'toast-top-center'
            });

            localStorage.removeItem('isExpirated');
        }

        this.router.navigate(['/login']);
        return false;
    }
}
