import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgIf, NgClass } from '@angular/common';

@Component({
  selector: 'app-button-load',
  templateUrl: './button-load.component.html',
  standalone: true,
  imports: [NgIf, NgClass]
})
export class ButtonLoadComponent implements OnInit {

  @Input() isLoading: boolean;
  @Input() classNames: string;
  @Input() text: string;
  @Input() icon: string;
  @Input() modal: string;
  @Input() iconOnRight: boolean;
  @Input() isDisabled: boolean;

  @Output() clickEvent: EventEmitter<string>;

  constructor() {
    this.clickEvent = new EventEmitter<string>();
    this.isLoading = false;
    this.iconOnRight = false;
    this.icon = "";
    this.modal = "";
    this.isDisabled = false;
  }

  ngOnInit() {
  }

  clickAction() {
    this.clickEvent.next('evento');
  }

}
