<router-outlet *ngIf="!adminRoute; else adminTemplate"></router-outlet>
<ng-template #adminTemplate>
  <div [class.close-side-menu]="!openSideMenu">
    <app-menu [routemenu]="menuRoute" [open]="openSideMenu" (openMenu)="toggleSideMenu(true)"></app-menu>
    <div id="content-wrapper" class="d-flex flex-column">
      <div id="content">
        <app-header [openSideMenu]="openSideMenu" (toggleMenu)="toggleSideMenu()"></app-header>
        <router-outlet></router-outlet>
      </div>
      <app-footer></app-footer>
    </div>
    <app-confirm></app-confirm>
  </div>
</ng-template>